/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Language as PostgresModelLanguage } from '../models/Postgres/Model/language';
import { Region as PostgresModelRegion } from '../models/Postgres/Model/region';

@Injectable({
  providedIn: 'root',
})
export class RoamingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRoamingListRegionGet
   */
  static readonly ApiRoamingListRegionGetPath = '/api/Roaming/ListRegion';

  /**
   * Getting a list of available regions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoamingListRegionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListRegionGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * brand code
     */
    product?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelRegion>>> {

    const rb = new RequestBuilder(this.rootUrl, RoamingService.ApiRoamingListRegionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('product', params.product, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelRegion>>;
      })
    );
  }

  /**
   * Getting a list of available regions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoamingListRegionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListRegionGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * brand code
     */
    product?: string;
  },
  context?: HttpContext

): Observable<Array<PostgresModelRegion>> {

    return this.apiRoamingListRegionGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelRegion>>) => r.body as Array<PostgresModelRegion>)
    );
  }

  /**
   * Getting a list of available regions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoamingListRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListRegionGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * brand code
     */
    product?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelRegion>>> {

    const rb = new RequestBuilder(this.rootUrl, RoamingService.ApiRoamingListRegionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('product', params.product, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelRegion>>;
      })
    );
  }

  /**
   * Getting a list of available regions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoamingListRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListRegionGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * brand code
     */
    product?: string;
  },
  context?: HttpContext

): Observable<Array<PostgresModelRegion>> {

    return this.apiRoamingListRegionGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelRegion>>) => r.body as Array<PostgresModelRegion>)
    );
  }

  /**
   * Path part for operation apiRoamingListLanguagesGet
   */
  static readonly ApiRoamingListLanguagesGetPath = '/api/Roaming/ListLanguages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoamingListLanguagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListLanguagesGet$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelLanguage>>> {

    const rb = new RequestBuilder(this.rootUrl, RoamingService.ApiRoamingListLanguagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelLanguage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoamingListLanguagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListLanguagesGet$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<PostgresModelLanguage>> {

    return this.apiRoamingListLanguagesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelLanguage>>) => r.body as Array<PostgresModelLanguage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoamingListLanguagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListLanguagesGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PostgresModelLanguage>>> {

    const rb = new RequestBuilder(this.rootUrl, RoamingService.ApiRoamingListLanguagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostgresModelLanguage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoamingListLanguagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoamingListLanguagesGet(params?: {
  },
  context?: HttpContext

): Observable<Array<PostgresModelLanguage>> {

    return this.apiRoamingListLanguagesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PostgresModelLanguage>>) => r.body as Array<PostgresModelLanguage>)
    );
  }

}
