import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { SettingsDesignLogoModel, SettingsService } from '@libs/settings';
import { Observable } from 'rxjs';
import { IconModule } from '@ui/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { RouteEntity } from '@core/router/route.entity';
import { LogoComponent } from '@ui/logo/logo.component';
import { LangCheckerComponent } from '@ui/lang-checker/lang-checker.component';
import { UserState } from '@core/state/user.state';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';
import { LanguageState } from '@core/localization/language.state';
import { Language } from '@api/models/Postgres/Model/language';
import { map } from 'rxjs/operators';
import { AvailableLanguage } from '@core/localization/available-language';


@Component({
    selector: 'mon-tech-header-mon-tech',
    standalone: true,
    templateUrl: './header-mon-tech.component.html',
    styleUrls: ['./header-mon-tech.component.scss'],
    imports: [
        AsyncPipe,
        NgIf,
        IconModule,
        TranslateModule,
        RouterLink,
        LogoComponent,
        LangCheckerComponent,
    ],
})
export class HeaderMonTechComponent {
    readonly #settingsService: SettingsService = inject(SettingsService);
    readonly #languageState: LanguageState = inject(LanguageState);
    readonly #userState: UserState = inject(UserState);

    public readonly routeEntity: typeof RouteEntity = RouteEntity;

    /**
     * Logo header
     */
    public readonly logo: SettingsDesignLogoModel = this.#settingsService.settings?.logo?.srcHeader;
    /**
     * User data
     */
    public readonly user$: Observable<LoginResponse> = this.#userState.user$;
    /**
     * List languages
     */
    public readonly languages$: Observable<Language[]> = this.#languageState.languages$;
    /**
     * Selected lang code
     */
    public readonly selectedLang$: Observable<string> = this.#languageState.language$.pipe(
        map((lang: AvailableLanguage) => lang.toUpperCase()),
    );

    public handlerChangeLang(lang: Language): void {
        this.#languageState.setCurrentLanguage(lang);
        window.location.reload();
    }
}
