/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Module as UserServiceContractModule } from '../models/UserService/Contract/module';

@Injectable({
  providedIn: 'root',
})
export class ModulesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUsersListModulesGet
   */
  static readonly ApiUsersListModulesGetPath = '/api/Users/ListModules';

  /**
   * Getting a list of sections (modules) of the application.
   *
   * ### Claim
   *  boservice.users.listmodules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListModulesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListModulesGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * app name
     */
    appName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractModule>>> {

    const rb = new RequestBuilder(this.rootUrl, ModulesService.ApiUsersListModulesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('appName', params.appName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractModule>>;
      })
    );
  }

  /**
   * Getting a list of sections (modules) of the application.
   *
   * ### Claim
   *  boservice.users.listmodules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListModulesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListModulesGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * app name
     */
    appName?: string;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractModule>> {

    return this.apiUsersListModulesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractModule>>) => r.body as Array<UserServiceContractModule>)
    );
  }

  /**
   * Getting a list of sections (modules) of the application.
   *
   * ### Claim
   *  boservice.users.listmodules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListModulesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListModulesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * app name
     */
    appName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractModule>>> {

    const rb = new RequestBuilder(this.rootUrl, ModulesService.ApiUsersListModulesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('appName', params.appName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractModule>>;
      })
    );
  }

  /**
   * Getting a list of sections (modules) of the application.
   *
   * ### Claim
   *  boservice.users.listmodules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListModulesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListModulesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * app name
     */
    appName?: string;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractModule>> {

    return this.apiUsersListModulesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractModule>>) => r.body as Array<UserServiceContractModule>)
    );
  }

}
