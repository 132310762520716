import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SafeHtmlPipe } from '@pipes/safe-html/safe-html.pipe';
import { NgIf } from '@angular/common';
import { UserCardMenuComponent } from '@ui/user-card-menu/user-card-menu.component';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';

/**
 * User card in the header
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    standalone: true,
    imports: [
        SafeHtmlPipe,
        NgIf,
        UserCardMenuComponent,
    ],
})
export class UserCardComponent implements OnInit, OnDestroy {
    readonly #router: Router = inject(Router);
    readonly #cd: ChangeDetectorRef = inject(ChangeDetectorRef);

    /**
     * User data
     */
    @Input() user: LoginResponse;
    /**
     * User context menu display flag
     */
    public showCard: boolean = false;

    private destroy$: Subject<unknown> = new Subject<unknown>();

    public ngOnInit(): void {
        this.#router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe((event: NavigationEnd) => {
                setTimeout(() => {
                    this.showCard = false;
                    this.#cd.markForCheck();
                }, 0);
            });
    }

    public onClickCard($event: Event): void {
        $event.stopPropagation();
    }

    @HostListener('body:click', ['$event'])
    public applyBodyClick(ev: Event): void {
        this.showCard = false;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
