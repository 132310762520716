<div class="lang-checker">
    <ng-select [selectOnTab]="true"
               [(ngModel)]="selected"
               [items]="languages"
               [ngClass]="'ng-select--lang'"
               [bindLabel]="'name'"
               [bindValue]="'code'"
               [clearable]="false"
               [appearance]="'outline'"
               [hideSelected]="true"
               [searchable]="false"
               (change)="handlerChangeLang($event)">

        <ng-template ng-label-tmp let-item="item">
            {{ item.name }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
        </ng-template>
    </ng-select>
</div>
