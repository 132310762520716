import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InventoryService } from '@api/services/inventory.service';
import { UserState } from '@core/state/user.state';
import { ErrorService } from '@libs/error/error.service';
import { ResponseCodes } from '@core/api/response-codes';
import { UsersService } from '@api/services/users.service';


@Injectable({
    providedIn: 'root',
})
export class ErrorHttpInterceptor implements HttpInterceptor {
    readonly #userState: UserState = inject(UserState);
    readonly #errorService: ErrorService = inject(ErrorService);

    readonly #excludeShowErrorUrlList: string[] = [
        InventoryService.ApiEventModuleApiEvtTagListGetPath,
        UsersService.ApiUsersListLanguagesGetPath,
    ];

    public intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(this.errorHandler());
    }

    public errorHandler(): OperatorFunction<HttpEvent<unknown>, HttpEvent<unknown>> {
        return catchError((error: HttpErrorResponse) => {

            switch (error.status) {
                case ResponseCodes.Unauthorized:
                    this.#userState.obsoleteToken();

                    break;
                case ResponseCodes.BadRequest:
                case ResponseCodes.BadGateway:
                case ResponseCodes.GatewayTimeout:
                case ResponseCodes.ServiceUnavailable:
                case ResponseCodes.InternalServerError:
                    if (!this.#excludeShowErrorUrlList.some((url: string) => error.url.includes(url))) {
                        this.#errorService.show(typeof error.error === 'string' ? JSON.parse(error.error) : error.error);
                    }

                    break;
                default:
                    break;
            }

            return throwError(error);
        });
    }
}
