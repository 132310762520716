/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetFileResponse as BatchLoadContractGetFileResponse } from '../models/BatchLoad/Contract/get-file-response';
import { LdDataRcAddRequest as BatchLoadContractLdDataRcAddRequest } from '../models/BatchLoad/Contract/ld-data-rc-add-request';
import { LdDataRcListResponse as BatchLoadContractLdDataRcListResponse } from '../models/BatchLoad/Contract/ld-data-rc-list-response';
import { LdFileAddRequest as BatchLoadContractLdFileAddRequest } from '../models/BatchLoad/Contract/ld-file-add-request';
import { LdFileAddResponse as BatchLoadContractLdFileAddResponse } from '../models/BatchLoad/Contract/ld-file-add-response';
import { LdFileListResponse as BatchLoadContractLdFileListResponse } from '../models/BatchLoad/Contract/ld-file-list-response';
import { LdTypeListResponse as BatchLoadContractLdTypeListResponse } from '../models/BatchLoad/Contract/ld-type-list-response';
import { LoadFileResponse as BatchLoadContractLoadFileResponse } from '../models/BatchLoad/Contract/load-file-response';

@Injectable({
  providedIn: 'root',
})
export class BatchLoadService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiBatchLoadApiLdTypeListGet
   */
  static readonly ApiBatchLoadApiLdTypeListGetPath = '/api/BatchLoadApi/LdTypeList';

  /**
   * ### Claim
   *  boservice.batchloadapi.ldtypelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdTypeListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdTypeListGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BatchLoadContractLdTypeListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdTypeListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BatchLoadContractLdTypeListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldtypelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdTypeListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdTypeListGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BatchLoadContractLdTypeListResponse>> {

    return this.apiBatchLoadApiLdTypeListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BatchLoadContractLdTypeListResponse>>) => r.body as Array<BatchLoadContractLdTypeListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldtypelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdTypeListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdTypeListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BatchLoadContractLdTypeListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdTypeListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BatchLoadContractLdTypeListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldtypelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdTypeListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdTypeListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BatchLoadContractLdTypeListResponse>> {

    return this.apiBatchLoadApiLdTypeListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BatchLoadContractLdTypeListResponse>>) => r.body as Array<BatchLoadContractLdTypeListResponse>)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiLdFileListGet
   */
  static readonly ApiBatchLoadApiLdFileListGetPath = '/api/BatchLoadApi/LdFileList';

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfilelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdFileListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdFileListGet$Plain$Response(params?: {
    lang?: string;
    ldfId?: number;
    ldType?: string;
    dateFrom?: string;
    dateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BatchLoadContractLdFileListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdFileListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('ldType', params.ldType, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BatchLoadContractLdFileListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfilelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdFileListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdFileListGet$Plain(params?: {
    lang?: string;
    ldfId?: number;
    ldType?: string;
    dateFrom?: string;
    dateTo?: string;
  },
  context?: HttpContext

): Observable<Array<BatchLoadContractLdFileListResponse>> {

    return this.apiBatchLoadApiLdFileListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BatchLoadContractLdFileListResponse>>) => r.body as Array<BatchLoadContractLdFileListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfilelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdFileListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdFileListGet$Response(params?: {
    lang?: string;
    ldfId?: number;
    ldType?: string;
    dateFrom?: string;
    dateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BatchLoadContractLdFileListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdFileListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('ldType', params.ldType, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BatchLoadContractLdFileListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfilelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdFileListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdFileListGet(params?: {
    lang?: string;
    ldfId?: number;
    ldType?: string;
    dateFrom?: string;
    dateTo?: string;
  },
  context?: HttpContext

): Observable<Array<BatchLoadContractLdFileListResponse>> {

    return this.apiBatchLoadApiLdFileListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BatchLoadContractLdFileListResponse>>) => r.body as Array<BatchLoadContractLdFileListResponse>)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiLdFileAddPost
   */
  static readonly ApiBatchLoadApiLdFileAddPostPath = '/api/BatchLoadApi/LdFileAdd';

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfileadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdFileAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBatchLoadApiLdFileAddPost$Plain$Response(params?: {
    lang?: string;
    body?: BatchLoadContractLdFileAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractLdFileAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdFileAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractLdFileAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfileadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdFileAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBatchLoadApiLdFileAddPost$Plain(params?: {
    lang?: string;
    body?: BatchLoadContractLdFileAddRequest
  },
  context?: HttpContext

): Observable<BatchLoadContractLdFileAddResponse> {

    return this.apiBatchLoadApiLdFileAddPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractLdFileAddResponse>) => r.body as BatchLoadContractLdFileAddResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfileadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdFileAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBatchLoadApiLdFileAddPost$Response(params?: {
    lang?: string;
    body?: BatchLoadContractLdFileAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractLdFileAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdFileAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractLdFileAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.ldfileadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdFileAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBatchLoadApiLdFileAddPost(params?: {
    lang?: string;
    body?: BatchLoadContractLdFileAddRequest
  },
  context?: HttpContext

): Observable<BatchLoadContractLdFileAddResponse> {

    return this.apiBatchLoadApiLdFileAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractLdFileAddResponse>) => r.body as BatchLoadContractLdFileAddResponse)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiLdDataRcAddPost
   */
  static readonly ApiBatchLoadApiLdDataRcAddPostPath = '/api/BatchLoadApi/LdDataRcAdd';

  /**
   * ### Claim
   *  boservice.batchloadapi.lddatarcadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdDataRcAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBatchLoadApiLdDataRcAddPost$Response(params?: {
    lang?: string;
    body?: BatchLoadContractLdDataRcAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdDataRcAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.lddatarcadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdDataRcAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBatchLoadApiLdDataRcAddPost(params?: {
    lang?: string;
    body?: BatchLoadContractLdDataRcAddRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiBatchLoadApiLdDataRcAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiLdDataRcListGet
   */
  static readonly ApiBatchLoadApiLdDataRcListGetPath = '/api/BatchLoadApi/LdDataRcList';

  /**
   * ### Claim
   *  boservice.batchloadapi.lddatarclist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdDataRcListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdDataRcListGet$Plain$Response(params?: {
    lang?: string;
    ldfId?: number;
    hasError?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BatchLoadContractLdDataRcListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdDataRcListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('hasError', params.hasError, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BatchLoadContractLdDataRcListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.lddatarclist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdDataRcListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdDataRcListGet$Plain(params?: {
    lang?: string;
    ldfId?: number;
    hasError?: number;
  },
  context?: HttpContext

): Observable<Array<BatchLoadContractLdDataRcListResponse>> {

    return this.apiBatchLoadApiLdDataRcListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BatchLoadContractLdDataRcListResponse>>) => r.body as Array<BatchLoadContractLdDataRcListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.lddatarclist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLdDataRcListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdDataRcListGet$Response(params?: {
    lang?: string;
    ldfId?: number;
    hasError?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BatchLoadContractLdDataRcListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLdDataRcListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('hasError', params.hasError, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BatchLoadContractLdDataRcListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.lddatarclist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLdDataRcListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiLdDataRcListGet(params?: {
    lang?: string;
    ldfId?: number;
    hasError?: number;
  },
  context?: HttpContext

): Observable<Array<BatchLoadContractLdDataRcListResponse>> {

    return this.apiBatchLoadApiLdDataRcListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BatchLoadContractLdDataRcListResponse>>) => r.body as Array<BatchLoadContractLdDataRcListResponse>)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiLoadRcFilePost
   */
  static readonly ApiBatchLoadApiLoadRcFilePostPath = '/api/BatchLoadApi/LoadRcFile';

  /**
   * ### Claim
   *  boservice.batchloadapi.loadrcfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLoadRcFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadRcFilePost$Plain$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractLoadFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLoadRcFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractLoadFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.loadrcfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLoadRcFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadRcFilePost$Plain(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<BatchLoadContractLoadFileResponse> {

    return this.apiBatchLoadApiLoadRcFilePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractLoadFileResponse>) => r.body as BatchLoadContractLoadFileResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.loadrcfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLoadRcFilePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadRcFilePost$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractLoadFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLoadRcFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractLoadFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.loadrcfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLoadRcFilePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadRcFilePost(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<BatchLoadContractLoadFileResponse> {

    return this.apiBatchLoadApiLoadRcFilePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractLoadFileResponse>) => r.body as BatchLoadContractLoadFileResponse)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiGetRcFileGet
   */
  static readonly ApiBatchLoadApiGetRcFileGetPath = '/api/BatchLoadApi/GetRcFile';

  /**
   * ### Claim
   *  boservice.batchloadapi.getrcfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiGetRcFileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetRcFileGet$Plain$Response(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractGetFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiGetRcFileGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractGetFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.getrcfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiGetRcFileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetRcFileGet$Plain(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
  },
  context?: HttpContext

): Observable<BatchLoadContractGetFileResponse> {

    return this.apiBatchLoadApiGetRcFileGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractGetFileResponse>) => r.body as BatchLoadContractGetFileResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.getrcfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiGetRcFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetRcFileGet$Response(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractGetFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiGetRcFileGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractGetFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.getrcfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiGetRcFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetRcFileGet(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
  },
  context?: HttpContext

): Observable<BatchLoadContractGetFileResponse> {

    return this.apiBatchLoadApiGetRcFileGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractGetFileResponse>) => r.body as BatchLoadContractGetFileResponse)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiLoadUidFilePost
   */
  static readonly ApiBatchLoadApiLoadUidFilePostPath = '/api/BatchLoadApi/LoadUidFile';

  /**
   * ### Claim
   *  boservice.batchloadapi.loaduidfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLoadUidFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadUidFilePost$Plain$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'MediumType'?: number;
'ByteOrder'?: number;
'Sak'?: number;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractLoadFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLoadUidFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractLoadFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.loaduidfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLoadUidFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadUidFilePost$Plain(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'MediumType'?: number;
'ByteOrder'?: number;
'Sak'?: number;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<BatchLoadContractLoadFileResponse> {

    return this.apiBatchLoadApiLoadUidFilePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractLoadFileResponse>) => r.body as BatchLoadContractLoadFileResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.loaduidfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiLoadUidFilePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadUidFilePost$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'MediumType'?: number;
'ByteOrder'?: number;
'Sak'?: number;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractLoadFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiLoadUidFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractLoadFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.loaduidfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiLoadUidFilePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBatchLoadApiLoadUidFilePost(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'MediumType'?: number;
'ByteOrder'?: number;
'Sak'?: number;
'LdfType'?: string;
}
  },
  context?: HttpContext

): Observable<BatchLoadContractLoadFileResponse> {

    return this.apiBatchLoadApiLoadUidFilePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractLoadFileResponse>) => r.body as BatchLoadContractLoadFileResponse)
    );
  }

  /**
   * Path part for operation apiBatchLoadApiGetUidFileGet
   */
  static readonly ApiBatchLoadApiGetUidFileGetPath = '/api/BatchLoadApi/GetUidFile';

  /**
   * ### Claim
   *  boservice.batchloadapi.getuidfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiGetUidFileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetUidFileGet$Plain$Response(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
    hasError?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractGetFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiGetUidFileGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('fileName', params.fileName, {});
      rb.query('hasError', params.hasError, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractGetFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.getuidfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiGetUidFileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetUidFileGet$Plain(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
    hasError?: number;
  },
  context?: HttpContext

): Observable<BatchLoadContractGetFileResponse> {

    return this.apiBatchLoadApiGetUidFileGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractGetFileResponse>) => r.body as BatchLoadContractGetFileResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.getuidfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBatchLoadApiGetUidFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetUidFileGet$Response(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
    hasError?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BatchLoadContractGetFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BatchLoadService.ApiBatchLoadApiGetUidFileGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ldfId', params.ldfId, {});
      rb.query('fileName', params.fileName, {});
      rb.query('hasError', params.hasError, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BatchLoadContractGetFileResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.batchloadapi.getuidfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBatchLoadApiGetUidFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBatchLoadApiGetUidFileGet(params?: {
    lang?: string;
    ldfId?: number;
    fileName?: string;
    hasError?: number;
  },
  context?: HttpContext

): Observable<BatchLoadContractGetFileResponse> {

    return this.apiBatchLoadApiGetUidFileGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BatchLoadContractGetFileResponse>) => r.body as BatchLoadContractGetFileResponse)
    );
  }

}
