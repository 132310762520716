/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddOrganizationRequest as UserServiceContractAddOrganizationRequest } from '../models/UserService/Contract/add-organization-request';
import { AddOrganizationResponse as UserServiceContractAddOrganizationResponse } from '../models/UserService/Contract/add-organization-response';
import { SetOrgDataRequest as UserServiceContractSetOrgDataRequest } from '../models/UserService/Contract/set-org-data-request';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUsersRemoveOrganizationOrgIdDelete
   */
  static readonly ApiUsersRemoveOrganizationOrgIdDeletePath = '/api/Users/RemoveOrganization/{orgId}';

  /**
   * Deleting a division.
   *
   * ### Claim
   *  boservice.users.removeorganization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersRemoveOrganizationOrgIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersRemoveOrganizationOrgIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID units
     */
    orgId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiUsersRemoveOrganizationOrgIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a division.
   *
   * ### Claim
   *  boservice.users.removeorganization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersRemoveOrganizationOrgIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersRemoveOrganizationOrgIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID units
     */
    orgId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersRemoveOrganizationOrgIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersAddOrganizationPost
   */
  static readonly ApiUsersAddOrganizationPostPath = '/api/Users/AddOrganization';

  /**
   * Create a division.
   *
   * ### Claim
   *  boservice.users.addorganization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersAddOrganizationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddOrganizationPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New division
     */
    body?: UserServiceContractAddOrganizationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractAddOrganizationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiUsersAddOrganizationPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractAddOrganizationResponse>;
      })
    );
  }

  /**
   * Create a division.
   *
   * ### Claim
   *  boservice.users.addorganization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersAddOrganizationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddOrganizationPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New division
     */
    body?: UserServiceContractAddOrganizationRequest
  },
  context?: HttpContext

): Observable<UserServiceContractAddOrganizationResponse> {

    return this.apiUsersAddOrganizationPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractAddOrganizationResponse>) => r.body as UserServiceContractAddOrganizationResponse)
    );
  }

  /**
   * Create a division.
   *
   * ### Claim
   *  boservice.users.addorganization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersAddOrganizationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddOrganizationPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New division
     */
    body?: UserServiceContractAddOrganizationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserServiceContractAddOrganizationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiUsersAddOrganizationPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserServiceContractAddOrganizationResponse>;
      })
    );
  }

  /**
   * Create a division.
   *
   * ### Claim
   *  boservice.users.addorganization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersAddOrganizationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddOrganizationPost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * New division
     */
    body?: UserServiceContractAddOrganizationRequest
  },
  context?: HttpContext

): Observable<UserServiceContractAddOrganizationResponse> {

    return this.apiUsersAddOrganizationPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserServiceContractAddOrganizationResponse>) => r.body as UserServiceContractAddOrganizationResponse)
    );
  }

  /**
   * Path part for operation apiUsersSetOrgDataOrgIdPost
   */
  static readonly ApiUsersSetOrgDataOrgIdPostPath = '/api/Users/SetOrgData/{orgId}';

  /**
   * Changing the name of the division.
   *
   * ### Claim
   *  boservice.users.setorgdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersSetOrgDataOrgIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetOrgDataOrgIdPost$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Subdivision name
     */
    orgId: number;

    /**
     * Parent organization/department ID
     */
    body?: UserServiceContractSetOrgDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiUsersSetOrgDataOrgIdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('orgId', params.orgId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing the name of the division.
   *
   * ### Claim
   *  boservice.users.setorgdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersSetOrgDataOrgIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetOrgDataOrgIdPost(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Subdivision name
     */
    orgId: number;

    /**
     * Parent organization/department ID
     */
    body?: UserServiceContractSetOrgDataRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersSetOrgDataOrgIdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
