/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddDisplayTariffGroupRequest as TariffEditorApiContractAddDisplayTariffGroupRequest } from '../models/TariffEditorApi/Contract/add-display-tariff-group-request';
import { AddDisplayTariffGroupResponse as TariffEditorApiContractAddDisplayTariffGroupResponse } from '../models/TariffEditorApi/Contract/add-display-tariff-group-response';
import { AddTariffGroupRepRequest as TariffEditorApiContractAddTariffGroupRepRequest } from '../models/TariffEditorApi/Contract/add-tariff-group-rep-request';
import { AddTariffGroupRepResponse as TariffEditorApiContractAddTariffGroupRepResponse } from '../models/TariffEditorApi/Contract/add-tariff-group-rep-response';
import { SetDisplayTariffGroupRequest as TariffEditorApiContractSetDisplayTariffGroupRequest } from '../models/TariffEditorApi/Contract/set-display-tariff-group-request';
import { SetTariffGroupRepRequest as TariffEditorApiContractSetTariffGroupRepRequest } from '../models/TariffEditorApi/Contract/set-tariff-group-rep-request';

@Injectable({
  providedIn: 'root',
})
export class TariffGroupsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffAddDisplayTariffGroupPost
   */
  static readonly ApiTariffAddDisplayTariffGroupPostPath = '/api/Tariff/AddDisplayTariffGroup';

  /**
   * Adding a rate group to display.
   *
   * ### Claim
   *  boservice.tariff.adddisplaytariffgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddDisplayTariffGroupPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDisplayTariffGroupPost$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddDisplayTariffGroupRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddDisplayTariffGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffAddDisplayTariffGroupPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddDisplayTariffGroupResponse>;
      })
    );
  }

  /**
   * Adding a rate group to display.
   *
   * ### Claim
   *  boservice.tariff.adddisplaytariffgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddDisplayTariffGroupPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDisplayTariffGroupPost$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddDisplayTariffGroupRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddDisplayTariffGroupResponse> {

    return this.apiTariffAddDisplayTariffGroupPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddDisplayTariffGroupResponse>) => r.body as TariffEditorApiContractAddDisplayTariffGroupResponse)
    );
  }

  /**
   * Adding a rate group to display.
   *
   * ### Claim
   *  boservice.tariff.adddisplaytariffgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddDisplayTariffGroupPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDisplayTariffGroupPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddDisplayTariffGroupRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddDisplayTariffGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffAddDisplayTariffGroupPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddDisplayTariffGroupResponse>;
      })
    );
  }

  /**
   * Adding a rate group to display.
   *
   * ### Claim
   *  boservice.tariff.adddisplaytariffgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddDisplayTariffGroupPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDisplayTariffGroupPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddDisplayTariffGroupRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddDisplayTariffGroupResponse> {

    return this.apiTariffAddDisplayTariffGroupPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddDisplayTariffGroupResponse>) => r.body as TariffEditorApiContractAddDisplayTariffGroupResponse)
    );
  }

  /**
   * Path part for operation apiTariffSetDisplayTariffGroupPost
   */
  static readonly ApiTariffSetDisplayTariffGroupPostPath = '/api/Tariff/SetDisplayTariffGroup';

  /**
   * Editing a rate group for display.
   *
   * ### Claim
   *  boservice.tariff.setdisplaytariffgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetDisplayTariffGroupPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetDisplayTariffGroupPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetDisplayTariffGroupRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffSetDisplayTariffGroupPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing a rate group for display.
   *
   * ### Claim
   *  boservice.tariff.setdisplaytariffgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetDisplayTariffGroupPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetDisplayTariffGroupPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetDisplayTariffGroupRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetDisplayTariffGroupPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffRemoveDisplayTariffGroupDelete
   */
  static readonly ApiTariffRemoveDisplayTariffGroupDeletePath = '/api/Tariff/RemoveDisplayTariffGroup';

  /**
   * Deleting a rate group to display.
   *
   * ### Claim
   *  boservice.tariff.removedisplaytariffgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveDisplayTariffGroupDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveDisplayTariffGroupDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * rate group id
     */
    dtgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffRemoveDisplayTariffGroupDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dtgId', params.dtgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a rate group to display.
   *
   * ### Claim
   *  boservice.tariff.removedisplaytariffgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveDisplayTariffGroupDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveDisplayTariffGroupDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * rate group id
     */
    dtgId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveDisplayTariffGroupDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffAddTariffGroupRepPost
   */
  static readonly ApiTariffAddTariffGroupRepPostPath = '/api/Tariff/AddTariffGroupRep';

  /**
   * Adding a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.addtariffgrouprep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddTariffGroupRepPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffGroupRepPost$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffGroupRepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddTariffGroupRepResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffAddTariffGroupRepPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddTariffGroupRepResponse>;
      })
    );
  }

  /**
   * Adding a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.addtariffgrouprep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddTariffGroupRepPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffGroupRepPost$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffGroupRepRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddTariffGroupRepResponse> {

    return this.apiTariffAddTariffGroupRepPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddTariffGroupRepResponse>) => r.body as TariffEditorApiContractAddTariffGroupRepResponse)
    );
  }

  /**
   * Adding a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.addtariffgrouprep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddTariffGroupRepPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffGroupRepPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffGroupRepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddTariffGroupRepResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffAddTariffGroupRepPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddTariffGroupRepResponse>;
      })
    );
  }

  /**
   * Adding a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.addtariffgrouprep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddTariffGroupRepPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffGroupRepPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffGroupRepRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddTariffGroupRepResponse> {

    return this.apiTariffAddTariffGroupRepPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddTariffGroupRepResponse>) => r.body as TariffEditorApiContractAddTariffGroupRepResponse)
    );
  }

  /**
   * Path part for operation apiTariffSetTariffGroupRepPost
   */
  static readonly ApiTariffSetTariffGroupRepPostPath = '/api/Tariff/SetTariffGroupRep';

  /**
   * Editing a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.settariffgrouprep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetTariffGroupRepPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetTariffGroupRepPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetTariffGroupRepRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffSetTariffGroupRepPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.settariffgrouprep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetTariffGroupRepPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetTariffGroupRepPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetTariffGroupRepRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetTariffGroupRepPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffRemoveTariffGroupRepDelete
   */
  static readonly ApiTariffRemoveTariffGroupRepDeletePath = '/api/Tariff/RemoveTariffGroupRep';

  /**
   * Deleting a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.removetariffgrouprep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveTariffGroupRepDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveTariffGroupRepDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * rate group id
     */
    trgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffGroupsService.ApiTariffRemoveTariffGroupRepDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trgId', params.trgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.removetariffgrouprep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveTariffGroupRepDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveTariffGroupRepDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * rate group id
     */
    trgId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveTariffGroupRepDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
