import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { commonErrorRoutes, commonModuleRoutes } from '@core/router/routing.common';
import { RouteEntity } from '@core/router/route.entity';
import { AuthGuard } from '@core/auth/auth.guard';
import { HeaderMonTechComponent } from './shared/header-mon-tech/header-mon-tech.component';
import { PrivilegesModules } from '@core/privileges/privileges-modules';


const routes: Routes = [
    ...commonModuleRoutes,
    {
        path: RouteEntity.Empty,
        redirectTo: RouteEntity.Monitoring,
        pathMatch: 'full',
    },
    {
        path: RouteEntity.Monitoring,
        loadChildren: () =>
            import('./features/monitoring-tech/monitoring-tech.module').then(
                (m) => m.MonitoringTechModule,
            ),
        canLoad: [AuthGuard],
        data: {
            moduleMenu: {
                objName: [PrivilegesModules.ValmanMonitoring],
            },
        },
    },
    ...commonErrorRoutes,
];

const headerRoutes: Route = routes.find(route => route.outlet === 'header');
headerRoutes.component = HeaderMonTechComponent;

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
