/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListCardTypeResponse as ClientSupportApiContractChargebackListCardTypeResponse } from '../models/ClientSupportApi/Contract/Chargeback/list-card-type-response';
import { ListPaymentResponse as ClientSupportApiContractChargebackListPaymentResponse } from '../models/ClientSupportApi/Contract/Chargeback/list-payment-response';
import { SetBalanceReduceRequest as ClientSupportApiContractChargebackSetBalanceReduceRequest } from '../models/ClientSupportApi/Contract/Chargeback/set-balance-reduce-request';

@Injectable({
  providedIn: 'root',
})
export class ChargebackService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportListPaymentGet
   */
  static readonly ApiSupportListPaymentGetPath = '/api/Support/ListPayment';

  /**
   * Find payment transaction.
   *
   * List of payment transactions
   * Output is limited by 100 records.
   * ### Claim
   *  boservice.support.listpayment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPaymentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPaymentGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	start search date (including)
     */
    dateBegin?: string;

    /**
     * *	end search date (including)
     */
    dateEnd?: string;

    /**
     * card type code
     */
    methodSubtype?: string;

    /**
     * card last 4 digits
     */
    accNum?: string;

    /**
     * authorization code
     */
    authCode?: string;

    /**
     * rrn (not used now)
     */
    rrn?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractChargebackListPaymentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ChargebackService.ApiSupportListPaymentGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dateBegin', params.dateBegin, {});
      rb.query('dateEnd', params.dateEnd, {});
      rb.query('methodSubtype', params.methodSubtype, {});
      rb.query('accNum', params.accNum, {});
      rb.query('authCode', params.authCode, {});
      rb.query('rrn', params.rrn, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractChargebackListPaymentResponse>>;
      })
    );
  }

  /**
   * Find payment transaction.
   *
   * List of payment transactions
   * Output is limited by 100 records.
   * ### Claim
   *  boservice.support.listpayment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPaymentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPaymentGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	start search date (including)
     */
    dateBegin?: string;

    /**
     * *	end search date (including)
     */
    dateEnd?: string;

    /**
     * card type code
     */
    methodSubtype?: string;

    /**
     * card last 4 digits
     */
    accNum?: string;

    /**
     * authorization code
     */
    authCode?: string;

    /**
     * rrn (not used now)
     */
    rrn?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractChargebackListPaymentResponse>> {

    return this.apiSupportListPaymentGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractChargebackListPaymentResponse>>) => r.body as Array<ClientSupportApiContractChargebackListPaymentResponse>)
    );
  }

  /**
   * Find payment transaction.
   *
   * List of payment transactions
   * Output is limited by 100 records.
   * ### Claim
   *  boservice.support.listpayment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPaymentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPaymentGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	start search date (including)
     */
    dateBegin?: string;

    /**
     * *	end search date (including)
     */
    dateEnd?: string;

    /**
     * card type code
     */
    methodSubtype?: string;

    /**
     * card last 4 digits
     */
    accNum?: string;

    /**
     * authorization code
     */
    authCode?: string;

    /**
     * rrn (not used now)
     */
    rrn?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractChargebackListPaymentResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ChargebackService.ApiSupportListPaymentGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dateBegin', params.dateBegin, {});
      rb.query('dateEnd', params.dateEnd, {});
      rb.query('methodSubtype', params.methodSubtype, {});
      rb.query('accNum', params.accNum, {});
      rb.query('authCode', params.authCode, {});
      rb.query('rrn', params.rrn, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractChargebackListPaymentResponse>>;
      })
    );
  }

  /**
   * Find payment transaction.
   *
   * List of payment transactions
   * Output is limited by 100 records.
   * ### Claim
   *  boservice.support.listpayment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPaymentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPaymentGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	start search date (including)
     */
    dateBegin?: string;

    /**
     * *	end search date (including)
     */
    dateEnd?: string;

    /**
     * card type code
     */
    methodSubtype?: string;

    /**
     * card last 4 digits
     */
    accNum?: string;

    /**
     * authorization code
     */
    authCode?: string;

    /**
     * rrn (not used now)
     */
    rrn?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractChargebackListPaymentResponse>> {

    return this.apiSupportListPaymentGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractChargebackListPaymentResponse>>) => r.body as Array<ClientSupportApiContractChargebackListPaymentResponse>)
    );
  }

  /**
   * Path part for operation apiSupportListCardTypeGet
   */
  static readonly ApiSupportListCardTypeGetPath = '/api/Support/ListCardType';

  /**
   * List of payment card types.
   *
   * ### Claim
   *  boservice.support.listcardtype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListCardTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListCardTypeGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractChargebackListCardTypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ChargebackService.ApiSupportListCardTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractChargebackListCardTypeResponse>>;
      })
    );
  }

  /**
   * List of payment card types.
   *
   * ### Claim
   *  boservice.support.listcardtype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListCardTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListCardTypeGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractChargebackListCardTypeResponse>> {

    return this.apiSupportListCardTypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractChargebackListCardTypeResponse>>) => r.body as Array<ClientSupportApiContractChargebackListCardTypeResponse>)
    );
  }

  /**
   * List of payment card types.
   *
   * ### Claim
   *  boservice.support.listcardtype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListCardTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListCardTypeGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractChargebackListCardTypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ChargebackService.ApiSupportListCardTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractChargebackListCardTypeResponse>>;
      })
    );
  }

  /**
   * List of payment card types.
   *
   * ### Claim
   *  boservice.support.listcardtype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListCardTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListCardTypeGet(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractChargebackListCardTypeResponse>> {

    return this.apiSupportListCardTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractChargebackListCardTypeResponse>>) => r.body as Array<ClientSupportApiContractChargebackListCardTypeResponse>)
    );
  }

  /**
   * Path part for operation apiSupportSetBalanceReducePost
   */
  static readonly ApiSupportSetBalanceReducePostPath = '/api/Support/SetBalanceReduce';

  /**
   * Reduce balance.
   *
   * Reduce client's balance by specified amount
   * ### Claim
   *  boservice.support.setbalancereduce
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetBalanceReducePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetBalanceReducePost$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: ClientSupportApiContractChargebackSetBalanceReduceRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChargebackService.ApiSupportSetBalanceReducePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reduce balance.
   *
   * Reduce client's balance by specified amount
   * ### Claim
   *  boservice.support.setbalancereduce
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetBalanceReducePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetBalanceReducePost(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: ClientSupportApiContractChargebackSetBalanceReduceRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetBalanceReducePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
