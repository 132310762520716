<menu class="user-card-menu m-0 px-4 py-3">
    <div class="user-card-menu__item">
        <a class="a--border-off"
           [attr.title]="'global.labels.settings' | translate"
           [routerLink]="[routeEntity.Root, routeEntity.UserSettings]">{{ 'global.labels.settings' | translate }}</a>

    </div>
    <div class="user-card-menu__item">
        <a class="a--border-off"
           [attr.title]="'ui.user-card-menu.logout' | translate"
           [routerLink]="[routeEntity.Root, routeEntity.Logout]">
            {{ 'ui.user-card-menu.logout' | translate }}</a>
    </div>
</menu>
