/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddSpecDateConditionRequest as TariffEditorApiContractSpecDateAddSpecDateConditionRequest } from '../models/TariffEditorApi/Contract/SpecDate/add-spec-date-condition-request';
import { AddSpecDateConditionResponse as TariffEditorApiContractSpecDateAddSpecDateConditionResponse } from '../models/TariffEditorApi/Contract/SpecDate/add-spec-date-condition-response';
import { AddSpecDateListRequest as TariffEditorApiContractSpecDateAddSpecDateListRequest } from '../models/TariffEditorApi/Contract/SpecDate/add-spec-date-list-request';
import { AddSpecDateListResponse as TariffEditorApiContractSpecDateAddSpecDateListResponse } from '../models/TariffEditorApi/Contract/SpecDate/add-spec-date-list-response';
import { GetSpecDateDataResponse as TariffEditorApiContractSpecDateGetSpecDateDataResponse } from '../models/TariffEditorApi/Contract/SpecDate/get-spec-date-data-response';
import { ListSpecDateConditionResponse as TariffEditorApiContractSpecDateListSpecDateConditionResponse } from '../models/TariffEditorApi/Contract/SpecDate/list-spec-date-condition-response';
import { ListSpecDateResponse as TariffEditorApiContractSpecDateListSpecDateResponse } from '../models/TariffEditorApi/Contract/SpecDate/list-spec-date-response';
import { SetSpecDateNameRequest as TariffEditorApiContractSpecDateSetSpecDateNameRequest } from '../models/TariffEditorApi/Contract/SpecDate/set-spec-date-name-request';
import { SetTariffTicketPeriodNameRequest as TariffEditorApiContractSpecDateSetTariffTicketPeriodNameRequest } from '../models/TariffEditorApi/Contract/SpecDate/set-tariff-ticket-period-name-request';

@Injectable({
  providedIn: 'root',
})
export class SpecialDatesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffListSpecDateGet
   */
  static readonly ApiTariffListSpecDateGetPath = '/api/Tariff/ListSpecDate';

  /**
   * Getting a list of sets of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListSpecDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffListSpecDateGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateResponse>>;
      })
    );
  }

  /**
   * Getting a list of sets of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListSpecDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractSpecDateListSpecDateResponse>> {

    return this.apiTariffListSpecDateGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateResponse>>) => r.body as Array<TariffEditorApiContractSpecDateListSpecDateResponse>)
    );
  }

  /**
   * Getting a list of sets of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListSpecDateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffListSpecDateGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateResponse>>;
      })
    );
  }

  /**
   * Getting a list of sets of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListSpecDateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractSpecDateListSpecDateResponse>> {

    return this.apiTariffListSpecDateGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateResponse>>) => r.body as Array<TariffEditorApiContractSpecDateListSpecDateResponse>)
    );
  }

  /**
   * Path part for operation apiTariffListSpecDateConditionGet
   */
  static readonly ApiTariffListSpecDateConditionGetPath = '/api/Tariff/ListSpecDateCondition';

  /**
   * Getting a list of conditions for a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdatecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListSpecDateConditionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateConditionGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffListSpecDateConditionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('listId', params.listId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>>;
      })
    );
  }

  /**
   * Getting a list of conditions for a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdatecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListSpecDateConditionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateConditionGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>> {

    return this.apiTariffListSpecDateConditionGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>>) => r.body as Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>)
    );
  }

  /**
   * Getting a list of conditions for a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdatecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListSpecDateConditionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateConditionGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffListSpecDateConditionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('listId', params.listId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>>;
      })
    );
  }

  /**
   * Getting a list of conditions for a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.listspecdatecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListSpecDateConditionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListSpecDateConditionGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>> {

    return this.apiTariffListSpecDateConditionGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>>) => r.body as Array<TariffEditorApiContractSpecDateListSpecDateConditionResponse>)
    );
  }

  /**
   * Path part for operation apiTariffAddSpecDateConditionPost
   */
  static readonly ApiTariffAddSpecDateConditionPostPath = '/api/Tariff/AddSpecDateCondition';

  /**
   * Adding a condition to a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddSpecDateConditionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateConditionPost$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateConditionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateConditionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffAddSpecDateConditionPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateConditionResponse>;
      })
    );
  }

  /**
   * Adding a condition to a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddSpecDateConditionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateConditionPost$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateConditionRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractSpecDateAddSpecDateConditionResponse> {

    return this.apiTariffAddSpecDateConditionPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateConditionResponse>) => r.body as TariffEditorApiContractSpecDateAddSpecDateConditionResponse)
    );
  }

  /**
   * Adding a condition to a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddSpecDateConditionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateConditionPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateConditionRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateConditionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffAddSpecDateConditionPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateConditionResponse>;
      })
    );
  }

  /**
   * Adding a condition to a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddSpecDateConditionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateConditionPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateConditionRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractSpecDateAddSpecDateConditionResponse> {

    return this.apiTariffAddSpecDateConditionPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateConditionResponse>) => r.body as TariffEditorApiContractSpecDateAddSpecDateConditionResponse)
    );
  }

  /**
   * Path part for operation apiTariffAddSpecDateListPost
   */
  static readonly ApiTariffAddSpecDateListPostPath = '/api/Tariff/AddSpecDateList';

  /**
   * Adding a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddSpecDateListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateListPost$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateListRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffAddSpecDateListPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateListResponse>;
      })
    );
  }

  /**
   * Adding a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddSpecDateListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateListPost$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateListRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractSpecDateAddSpecDateListResponse> {

    return this.apiTariffAddSpecDateListPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateListResponse>) => r.body as TariffEditorApiContractSpecDateAddSpecDateListResponse)
    );
  }

  /**
   * Adding a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddSpecDateListPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateListPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateListRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffAddSpecDateListPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateListResponse>;
      })
    );
  }

  /**
   * Adding a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.addspecdatelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddSpecDateListPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddSpecDateListPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateAddSpecDateListRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractSpecDateAddSpecDateListResponse> {

    return this.apiTariffAddSpecDateListPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractSpecDateAddSpecDateListResponse>) => r.body as TariffEditorApiContractSpecDateAddSpecDateListResponse)
    );
  }

  /**
   * Path part for operation apiTariffRemoveSpecDateListDelete
   */
  static readonly ApiTariffRemoveSpecDateListDeletePath = '/api/Tariff/RemoveSpecDateList';

  /**
   * Deleting a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.removespecdatelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveSpecDateListDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveSpecDateListDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffRemoveSpecDateListDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('listId', params.listId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.removespecdatelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveSpecDateListDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveSpecDateListDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveSpecDateListDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffRemoveSpecDateConditionDelete
   */
  static readonly ApiTariffRemoveSpecDateConditionDeletePath = '/api/Tariff/RemoveSpecDateCondition';

  /**
   * Removing a condition from a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.removespecdatecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveSpecDateConditionDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveSpecDateConditionDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id conditions
     */
    spdcId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffRemoveSpecDateConditionDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('spdcId', params.spdcId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a condition from a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.removespecdatecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveSpecDateConditionDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveSpecDateConditionDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id conditions
     */
    spdcId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveSpecDateConditionDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffSetSpecDateNamePost
   */
  static readonly ApiTariffSetSpecDateNamePostPath = '/api/Tariff/SetSpecDateName';

  /**
   * Change the name of a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.setspecdatename
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetSpecDateNamePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetSpecDateNamePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateSetSpecDateNameRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffSetSpecDateNamePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change the name of a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.setspecdatename
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetSpecDateNamePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetSpecDateNamePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateSetSpecDateNameRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetSpecDateNamePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffSetTariffTicketPeriodNamePost
   */
  static readonly ApiTariffSetTariffTicketPeriodNamePostPath = '/api/Tariff/SetTariffTicketPeriodName';

  /**
   * Changing the name of a special period.
   *
   * ### Claim
   *  boservice.tariff.settariffticketperiodname
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetTariffTicketPeriodNamePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetTariffTicketPeriodNamePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateSetTariffTicketPeriodNameRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffSetTariffTicketPeriodNamePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing the name of a special period.
   *
   * ### Claim
   *  boservice.tariff.settariffticketperiodname
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetTariffTicketPeriodNamePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetTariffTicketPeriodNamePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSpecDateSetTariffTicketPeriodNameRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetTariffTicketPeriodNamePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffGetSpecDateDataGet
   */
  static readonly ApiTariffGetSpecDateDataGetPath = '/api/Tariff/GetSpecDateData';

  /**
   * Getting data about a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.getspecdatedata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetSpecDateDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetSpecDateDataGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractSpecDateGetSpecDateDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffGetSpecDateDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('listId', params.listId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractSpecDateGetSpecDateDataResponse>;
      })
    );
  }

  /**
   * Getting data about a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.getspecdatedata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetSpecDateDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetSpecDateDataGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractSpecDateGetSpecDateDataResponse> {

    return this.apiTariffGetSpecDateDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractSpecDateGetSpecDateDataResponse>) => r.body as TariffEditorApiContractSpecDateGetSpecDateDataResponse)
    );
  }

  /**
   * Getting data about a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.getspecdatedata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetSpecDateDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetSpecDateDataGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractSpecDateGetSpecDateDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SpecialDatesService.ApiTariffGetSpecDateDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('listId', params.listId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractSpecDateGetSpecDateDataResponse>;
      })
    );
  }

  /**
   * Getting data about a set of special dates.
   *
   * ### Claim
   *  boservice.tariff.getspecdatedata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetSpecDateDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetSpecDateDataGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * special date set id
     */
    listId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractSpecDateGetSpecDateDataResponse> {

    return this.apiTariffGetSpecDateDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractSpecDateGetSpecDateDataResponse>) => r.body as TariffEditorApiContractSpecDateGetSpecDateDataResponse)
    );
  }

}
