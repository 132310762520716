import { inject, Injectable } from '@angular/core';
import { StartWithUnit } from '@core/operators/start-with-unit';
import { RxState } from '@rx-angular/state';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { Observable } from 'rxjs';
import { ErrorResponse } from '@core/api/models/error-response.model';
import { LOCALIZATION_CONFIG, LocalizationConfig } from '@core/localization/localization.config';
import { TranslateService } from '@ngx-translate/core';
import { AvailableLanguage } from '@core/localization/available-language';
import { CookieService } from 'ngx-cookie-service';
import { StorageKeys } from '@core/storage/storage.keys';
import { environment } from '@environments/environment';
import { RouteEntity } from '@core/router/route.entity';
import { SettingsService } from '@libs/settings';
import { filter, tap } from 'rxjs/operators';
import { RoamingService } from '@api/services/roaming.service';
import { Language } from '@api/models/Postgres/Model/language';
import { Language as LanguageUser  } from '@api/models/UserService/Contract/language';
import { UsersService } from '@api/services/users.service';


export interface ILanguageState {
    loading: Record<StartWithUnit, boolean>;
    errors: Record<StartWithUnit, ErrorResponse>;
    languages: Language[];
    userLanguages: LanguageUser[];
    language: AvailableLanguage;
}

export interface ILanguageAction {
    requestLanguages: Language[];
    requestUserLanguages: LanguageUser[];
    setCurrentLanguage: AvailableLanguage;
}

@Injectable({
    providedIn: 'root',
})
export class LanguageState extends RxState<ILanguageState> {
    readonly #actions: RxActions<ILanguageAction> = new RxActionFactory<ILanguageAction>().create();
    readonly #localizationConfig: LocalizationConfig = inject(LOCALIZATION_CONFIG);
    readonly #translate: TranslateService = inject(TranslateService);
    readonly #cookieService: CookieService = inject(CookieService);
    readonly #settingsService: SettingsService = inject(SettingsService);
    readonly #roamingService: RoamingService = inject(RoamingService);
    readonly #usersService: UsersService = inject(UsersService);

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly languages$: Observable<Language[]> = this.select('languages');
    public readonly userLanguages$: Observable<LanguageUser[]> = this.select('userLanguages');
    public readonly language$: Observable<AvailableLanguage> = this.select('language');


    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();

        this.#translate.setDefaultLang(this.#localizationConfig.defaultLocale);
    }

    public requestLanguages(): void {
        this.hold(
            this.#roamingService.apiRoamingListLanguagesGet(),
            this.#actions.requestLanguages,
        );
    }

    public requestUserLanguages(): void {
        this.hold(
            this.#usersService.apiUsersListLanguagesGet(),
            this.#actions.requestUserLanguages,
        );
    }

    public setCurrentLanguage(language: Language): void {
        const isoCode: string = language.code?.toLowerCase();

        const cookieDomain: string = environment.isLocalhost ? null : this.#settingsService.settings.APP_SETTINGS_BASE_DOMAIN;

        this.#translate.use(isoCode);
        this.#cookieService.set(StorageKeys.CookieLang, isoCode, null, RouteEntity.Root, cookieDomain);
        this.#actions.setCurrentLanguage(isoCode as AvailableLanguage);
    }

    public get currentLang(): AvailableLanguage {
        return this.get('language') || this.#localizationConfig.defaultLocale;
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            errors: null,
            languages: null,
        });
    }

    private connectSelectors(): void {
        this.connect('languages', this.#actions.requestLanguages$.pipe(
            filter(Boolean),
            tap((languages: Language[]) => {
                const cookieLang: string = this.#cookieService.get(StorageKeys.CookieLang);
                const cookieLangInDb: Language = languages?.find((lang: Language) => lang.code.toLowerCase() === cookieLang);

                this.setCurrentLanguage(cookieLang && cookieLangInDb ? cookieLangInDb : languages[0]);
            })
        ));

        this.connect('language', this.#actions.setCurrentLanguage$);
        this.connect('userLanguages', this.#actions.requestUserLanguages$);
    }
}
