/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EvtChipListResponse as EventModuleApiContractInventoryEvtChipListResponse } from '../models/EventModuleApi/Contract/Inventory/evt-chip-list-response';
import { EvtInventoryAddResponse as EventModuleApiContractInventoryEvtInventoryAddResponse } from '../models/EventModuleApi/Contract/Inventory/evt-inventory-add-response';
import { EvtInventoryBatchAddResponse as EventModuleApiContractInventoryEvtInventoryBatchAddResponse } from '../models/EventModuleApi/Contract/Inventory/evt-inventory-batch-add-response';
import { EvtInventoryListResponse as EventModuleApiContractInventoryEvtInventoryListResponse } from '../models/EventModuleApi/Contract/Inventory/evt-inventory-list-response';
import { EvtInventoryRepackAddRequest as EventModuleApiContractInventoryEvtInventoryRepackAddRequest } from '../models/EventModuleApi/Contract/Inventory/evt-inventory-repack-add-request';
import { EvtMediumTypeListResponse as EventModuleApiContractInventoryEvtMediumTypeListResponse } from '../models/EventModuleApi/Contract/Inventory/evt-medium-type-list-response';
import { EvtTagListResponseBo as EventModuleApiContractInventoryEvtTagListResponseBo } from '../models/EventModuleApi/Contract/Inventory/evt-tag-list-response-bo';

@Injectable({
  providedIn: 'root',
})
export class InventoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEventModuleApiEvtChipListGet
   */
  static readonly ApiEventModuleApiEvtChipListGetPath = '/api/EventModuleApi/EvtChipList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtchiplist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtChipListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtChipListGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtChipListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtChipListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtChipListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtchiplist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtChipListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtChipListGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtChipListResponse>> {

    return this.apiEventModuleApiEvtChipListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtChipListResponse>>) => r.body as Array<EventModuleApiContractInventoryEvtChipListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtchiplist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtChipListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtChipListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtChipListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtChipListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtChipListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtchiplist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtChipListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtChipListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtChipListResponse>> {

    return this.apiEventModuleApiEvtChipListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtChipListResponse>>) => r.body as Array<EventModuleApiContractInventoryEvtChipListResponse>)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtMediumTypeListGet
   */
  static readonly ApiEventModuleApiEvtMediumTypeListGetPath = '/api/EventModuleApi/EvtMediumTypeList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtmediumtypelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtMediumTypeListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtMediumTypeListGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtMediumTypeListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtmediumtypelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtMediumTypeListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtMediumTypeListGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>> {

    return this.apiEventModuleApiEvtMediumTypeListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>>) => r.body as Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtmediumtypelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtMediumTypeListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtMediumTypeListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtMediumTypeListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtmediumtypelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtMediumTypeListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtMediumTypeListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>> {

    return this.apiEventModuleApiEvtMediumTypeListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>>) => r.body as Array<EventModuleApiContractInventoryEvtMediumTypeListResponse>)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtInventoryListGet
   */
  static readonly ApiEventModuleApiEvtInventoryListGetPath = '/api/EventModuleApi/EvtInventoryList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorylist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtInventoryListGet$Plain$Response(params?: {
    lang?: string;
    pkgDate?: string;
    repacked?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtInventoryListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pkgDate', params.pkgDate, {});
      rb.query('repacked', params.repacked, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtInventoryListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorylist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtInventoryListGet$Plain(params?: {
    lang?: string;
    pkgDate?: string;
    repacked?: number;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtInventoryListResponse>> {

    return this.apiEventModuleApiEvtInventoryListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtInventoryListResponse>>) => r.body as Array<EventModuleApiContractInventoryEvtInventoryListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorylist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtInventoryListGet$Response(params?: {
    lang?: string;
    pkgDate?: string;
    repacked?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtInventoryListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pkgDate', params.pkgDate, {});
      rb.query('repacked', params.repacked, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtInventoryListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorylist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtInventoryListGet(params?: {
    lang?: string;
    pkgDate?: string;
    repacked?: number;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtInventoryListResponse>> {

    return this.apiEventModuleApiEvtInventoryListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtInventoryListResponse>>) => r.body as Array<EventModuleApiContractInventoryEvtInventoryListResponse>)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtInventoryAddPost
   */
  static readonly ApiEventModuleApiEvtInventoryAddPostPath = '/api/EventModuleApi/EvtInventoryAdd';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventoryadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryAddPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryAddPost$Plain$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'Name'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventoryadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryAddPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryAddPost$Plain(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'Name'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<EventModuleApiContractInventoryEvtInventoryAddResponse> {

    return this.apiEventModuleApiEvtInventoryAddPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryAddResponse>) => r.body as EventModuleApiContractInventoryEvtInventoryAddResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventoryadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryAddPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryAddPost$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'Name'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventoryadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryAddPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryAddPost(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'Name'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<EventModuleApiContractInventoryEvtInventoryAddResponse> {

    return this.apiEventModuleApiEvtInventoryAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryAddResponse>) => r.body as EventModuleApiContractInventoryEvtInventoryAddResponse)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtInventoryBatchAddPost
   */
  static readonly ApiEventModuleApiEvtInventoryBatchAddPostPath = '/api/EventModuleApi/EvtInventoryBatchAdd';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorybatchadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryBatchAddPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryBatchAddPost$Plain$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryBatchAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryBatchAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryBatchAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorybatchadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryBatchAddPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryBatchAddPost$Plain(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<EventModuleApiContractInventoryEvtInventoryBatchAddResponse> {

    return this.apiEventModuleApiEvtInventoryBatchAddPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryBatchAddResponse>) => r.body as EventModuleApiContractInventoryEvtInventoryBatchAddResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorybatchadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryBatchAddPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryBatchAddPost$Response(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryBatchAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryBatchAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryBatchAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorybatchadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryBatchAddPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEventModuleApiEvtInventoryBatchAddPost(params?: {
    lang?: string;
    body?: {
'file'?: Blob;
'PkgDate'?: string;
'MediumType'?: number;
'ChipType'?: number;
'Sak'?: string;
'IsBigEndian'?: boolean;
}
  },
  context?: HttpContext

): Observable<EventModuleApiContractInventoryEvtInventoryBatchAddResponse> {

    return this.apiEventModuleApiEvtInventoryBatchAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractInventoryEvtInventoryBatchAddResponse>) => r.body as EventModuleApiContractInventoryEvtInventoryBatchAddResponse)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtInventoryDelDelete
   */
  static readonly ApiEventModuleApiEvtInventoryDelDeletePath = '/api/EventModuleApi/EvtInventoryDel';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorydel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryDelDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtInventoryDelDelete$Response(params?: {
    lang?: string;
    pkgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryDelDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pkgId', params.pkgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventorydel
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryDelDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtInventoryDelDelete(params?: {
    lang?: string;
    pkgId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiEventModuleApiEvtInventoryDelDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtInventoryRepackAddPost
   */
  static readonly ApiEventModuleApiEvtInventoryRepackAddPostPath = '/api/EventModuleApi/EvtInventoryRepackAdd';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventoryrepackadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtInventoryRepackAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtInventoryRepackAddPost$Response(params?: {
    lang?: string;
    body?: EventModuleApiContractInventoryEvtInventoryRepackAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtInventoryRepackAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evtinventoryrepackadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtInventoryRepackAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtInventoryRepackAddPost(params?: {
    lang?: string;
    body?: EventModuleApiContractInventoryEvtInventoryRepackAddRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiEventModuleApiEvtInventoryRepackAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtTagListGet
   */
  static readonly ApiEventModuleApiEvtTagListGetPath = '/api/EventModuleApi/EvtTagList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttaglist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtTagListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTagListGet$Plain$Response(params?: {
    lang?: string;
    pkgId?: number;
    pkgDate?: string;
    name?: string;
    limit?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtTagListResponseBo>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtTagListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pkgId', params.pkgId, {});
      rb.query('pkgDate', params.pkgDate, {});
      rb.query('name', params.name, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtTagListResponseBo>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttaglist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtTagListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTagListGet$Plain(params?: {
    lang?: string;
    pkgId?: number;
    pkgDate?: string;
    name?: string;
    limit?: number;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtTagListResponseBo>> {

    return this.apiEventModuleApiEvtTagListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtTagListResponseBo>>) => r.body as Array<EventModuleApiContractInventoryEvtTagListResponseBo>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttaglist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtTagListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTagListGet$Response(params?: {
    lang?: string;
    pkgId?: number;
    pkgDate?: string;
    name?: string;
    limit?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractInventoryEvtTagListResponseBo>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.ApiEventModuleApiEvtTagListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pkgId', params.pkgId, {});
      rb.query('pkgDate', params.pkgDate, {});
      rb.query('name', params.name, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractInventoryEvtTagListResponseBo>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttaglist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtTagListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTagListGet(params?: {
    lang?: string;
    pkgId?: number;
    pkgDate?: string;
    name?: string;
    limit?: number;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractInventoryEvtTagListResponseBo>> {

    return this.apiEventModuleApiEvtTagListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractInventoryEvtTagListResponseBo>>) => r.body as Array<EventModuleApiContractInventoryEvtTagListResponseBo>)
    );
  }

}
