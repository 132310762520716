import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteEntity } from '@core/router/route.entity';
import { NgForOf, NgIf } from '@angular/common';
import { SafeHtmlPipe } from '@pipes/safe-html/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentProtocolPipe } from '@pipes/current-protocol/current-protocol.pipe';
import { Module } from '@api/models/UserService/Contract/module';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';


/**
 * List modules (mobile menu)
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        SafeHtmlPipe,
        TranslateModule,
        CurrentProtocolPipe,
        NgForOf,
    ],
})
export class MobileMenuComponent {
    /**
     * List menu items
     */
    @Input() public menuItems: Module[];
    /**
     * User data
     */
    @Input() public user: LoginResponse;

    public readonly routeEntity: typeof RouteEntity = RouteEntity;

    public isOpen: boolean;
}
