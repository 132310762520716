/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddB2BProgramRequest as B2BServiceContractAddB2BProgramRequest } from '../models/B2bService/Contract/add-b-2-b-program-request';
import { AddPartnerProgram as B2BServiceContractAddPartnerProgram } from '../models/B2bService/Contract/add-partner-program';
import { B2BFile as B2BServiceContractB2BFile } from '../models/B2bService/Contract/b-2-b-file';
import { B2BRiderListResponse as B2BServiceContractB2BRiderListResponse } from '../models/B2bService/Contract/b-2-b-rider-list-response';
import { Load as B2BServiceContractLoad } from '../models/B2bService/Contract/load';
import { LoadData as B2BServiceContractLoadData } from '../models/B2bService/Contract/load-data';
import { MappingAddFileRequest as B2BServiceContractMappingMappingAddFileRequest } from '../models/B2bService/Contract/Mapping/mapping-add-file-request';
import { MappingFileResponse as B2BServiceContractMappingMappingFileResponse } from '../models/B2bService/Contract/Mapping/mapping-file-response';
import { PartnerProgram as B2BServiceContractPartnerProgram } from '../models/B2bService/Contract/partner-program';
import { AddB2BPtBatchRequest as B2BServiceContractPrintedTicketsAddB2BPtBatchRequest } from '../models/B2bService/Contract/PrintedTickets/add-b-2-b-pt-batch-request';
import { AddB2BPtBatchResponse as B2BServiceContractPrintedTicketsAddB2BPtBatchResponse } from '../models/B2bService/Contract/PrintedTickets/add-b-2-b-pt-batch-response';
import { ListB2BPtBatchResponse as B2BServiceContractPrintedTicketsListB2BPtBatchResponse } from '../models/B2bService/Contract/PrintedTickets/list-b-2-b-pt-batch-response';
import { ListB2BPtTariffResponse as B2BServiceContractPrintedTicketsListB2BPtTariffResponse } from '../models/B2bService/Contract/PrintedTickets/list-b-2-b-pt-tariff-response';
import { ListB2BPtTicketResponse as B2BServiceContractPrintedTicketsListB2BPtTicketResponse } from '../models/B2bService/Contract/PrintedTickets/list-b-2-b-pt-ticket-response';
import { RemoveB2BPtRequest as B2BServiceContractPrintedTicketsRemoveB2BPtRequest } from '../models/B2bService/Contract/PrintedTickets/remove-b-2-b-pt-request';
import { RemoveB2BPtResponse as B2BServiceContractPrintedTicketsRemoveB2BPtResponse } from '../models/B2bService/Contract/PrintedTickets/remove-b-2-b-pt-response';
import { Service as B2BServiceContractService } from '../models/B2bService/Contract/service';
import { Tariffs as B2BServiceContractTariffs } from '../models/B2bService/Contract/tariffs';

@Injectable({
  providedIn: 'root',
})
export class B2BService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiB2BListB2BTariffsGet
   */
  static readonly ApiB2BListB2BTariffsGetPath = '/api/B2b/ListB2bTariffs';

  /**
   * viewing tariffs.
   *
   * ### Claim
   *  boservice.b2b.listb2btariffs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BTariffsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BTariffsGet$Plain$Response(params?: {

    /**
     * language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractTariffs>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BTariffsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractTariffs>>;
      })
    );
  }

  /**
   * viewing tariffs.
   *
   * ### Claim
   *  boservice.b2b.listb2btariffs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BTariffsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BTariffsGet$Plain(params?: {

    /**
     * language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractTariffs>> {

    return this.apiB2BListB2BTariffsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractTariffs>>) => r.body as Array<B2BServiceContractTariffs>)
    );
  }

  /**
   * viewing tariffs.
   *
   * ### Claim
   *  boservice.b2b.listb2btariffs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BTariffsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BTariffsGet$Response(params?: {

    /**
     * language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractTariffs>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BTariffsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractTariffs>>;
      })
    );
  }

  /**
   * viewing tariffs.
   *
   * ### Claim
   *  boservice.b2b.listb2btariffs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BTariffsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BTariffsGet(params?: {

    /**
     * language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractTariffs>> {

    return this.apiB2BListB2BTariffsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractTariffs>>) => r.body as Array<B2BServiceContractTariffs>)
    );
  }

  /**
   * Path part for operation apiB2BListB2BServiceGet
   */
  static readonly ApiB2BListB2BServiceGetPath = '/api/B2b/ListB2bService';

  /**
   * viewing services.
   *
   * ### Claim
   *  boservice.b2b.listb2bservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BServiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BServiceGet$Plain$Response(params?: {

    /**
     * language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractService>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractService>>;
      })
    );
  }

  /**
   * viewing services.
   *
   * ### Claim
   *  boservice.b2b.listb2bservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BServiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BServiceGet$Plain(params?: {

    /**
     * language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractService>> {

    return this.apiB2BListB2BServiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractService>>) => r.body as Array<B2BServiceContractService>)
    );
  }

  /**
   * viewing services.
   *
   * ### Claim
   *  boservice.b2b.listb2bservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BServiceGet$Response(params?: {

    /**
     * language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractService>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractService>>;
      })
    );
  }

  /**
   * viewing services.
   *
   * ### Claim
   *  boservice.b2b.listb2bservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BServiceGet(params?: {

    /**
     * language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractService>> {

    return this.apiB2BListB2BServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractService>>) => r.body as Array<B2BServiceContractService>)
    );
  }

  /**
   * Path part for operation apiB2BAddB2BProgramPost
   */
  static readonly ApiB2BAddB2BProgramPostPath = '/api/B2b/AddB2bProgram';

  /**
   * Program creation.
   *
   * ### Claim
   *  boservice.b2b.addb2bprogram
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BAddB2BProgramPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BProgramPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractAddB2BProgramRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractAddPartnerProgram>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BAddB2BProgramPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractAddPartnerProgram>;
      })
    );
  }

  /**
   * Program creation.
   *
   * ### Claim
   *  boservice.b2b.addb2bprogram
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BAddB2BProgramPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BProgramPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractAddB2BProgramRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractAddPartnerProgram> {

    return this.apiB2BAddB2BProgramPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractAddPartnerProgram>) => r.body as B2BServiceContractAddPartnerProgram)
    );
  }

  /**
   * Program creation.
   *
   * ### Claim
   *  boservice.b2b.addb2bprogram
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BAddB2BProgramPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BProgramPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractAddB2BProgramRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractAddPartnerProgram>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BAddB2BProgramPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractAddPartnerProgram>;
      })
    );
  }

  /**
   * Program creation.
   *
   * ### Claim
   *  boservice.b2b.addb2bprogram
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BAddB2BProgramPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BProgramPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractAddB2BProgramRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractAddPartnerProgram> {

    return this.apiB2BAddB2BProgramPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractAddPartnerProgram>) => r.body as B2BServiceContractAddPartnerProgram)
    );
  }

  /**
   * Path part for operation apiB2BListB2BProgramsGet
   */
  static readonly ApiB2BListB2BProgramsGetPath = '/api/B2b/ListB2bPrograms';

  /**
   * getting a list of programs.
   *
   * ### Claim
   *  boservice.b2b.listb2bprograms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BProgramsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BProgramsGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPartnerProgram>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BProgramsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPartnerProgram>>;
      })
    );
  }

  /**
   * getting a list of programs.
   *
   * ### Claim
   *  boservice.b2b.listb2bprograms
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BProgramsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BProgramsGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPartnerProgram>> {

    return this.apiB2BListB2BProgramsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPartnerProgram>>) => r.body as Array<B2BServiceContractPartnerProgram>)
    );
  }

  /**
   * getting a list of programs.
   *
   * ### Claim
   *  boservice.b2b.listb2bprograms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BProgramsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BProgramsGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPartnerProgram>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BProgramsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPartnerProgram>>;
      })
    );
  }

  /**
   * getting a list of programs.
   *
   * ### Claim
   *  boservice.b2b.listb2bprograms
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BProgramsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BProgramsGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPartnerProgram>> {

    return this.apiB2BListB2BProgramsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPartnerProgram>>) => r.body as Array<B2BServiceContractPartnerProgram>)
    );
  }

  /**
   * Path part for operation apiB2BRemoveB2BProgramDelete
   */
  static readonly ApiB2BRemoveB2BProgramDeletePath = '/api/B2b/RemoveB2bProgram';

  /**
   * uninstalling a program.
   *
   * ### Claim
   *  boservice.b2b.removeb2bprogram
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BRemoveB2BProgramDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BRemoveB2BProgramDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program id (required)
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BRemoveB2BProgramDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * uninstalling a program.
   *
   * ### Claim
   *  boservice.b2b.removeb2bprogram
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BRemoveB2BProgramDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BRemoveB2BProgramDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program id (required)
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiB2BRemoveB2BProgramDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiB2BB2BAddFilePost
   */
  static readonly ApiB2BB2BAddFilePostPath = '/api/B2b/B2bAddFile';

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2baddfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BAddFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BAddFilePost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractB2BFile>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BB2BAddFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractB2BFile>;
      })
    );
  }

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2baddfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BAddFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BAddFilePost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<B2BServiceContractB2BFile> {

    return this.apiB2BB2BAddFilePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractB2BFile>) => r.body as B2BServiceContractB2BFile)
    );
  }

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2baddfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BAddFilePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BAddFilePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractB2BFile>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BB2BAddFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractB2BFile>;
      })
    );
  }

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2baddfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BAddFilePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BAddFilePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<B2BServiceContractB2BFile> {

    return this.apiB2BB2BAddFilePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractB2BFile>) => r.body as B2BServiceContractB2BFile)
    );
  }

  /**
   * Path part for operation apiB2BB2BRemoveFilePost
   */
  static readonly ApiB2BB2BRemoveFilePostPath = '/api/B2b/B2bRemoveFile';

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2bremovefile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BRemoveFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BRemoveFilePost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractB2BFile>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BB2BRemoveFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractB2BFile>;
      })
    );
  }

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2bremovefile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BRemoveFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BRemoveFilePost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<B2BServiceContractB2BFile> {

    return this.apiB2BB2BRemoveFilePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractB2BFile>) => r.body as B2BServiceContractB2BFile)
    );
  }

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2bremovefile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BRemoveFilePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BRemoveFilePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractB2BFile>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BB2BRemoveFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractB2BFile>;
      })
    );
  }

  /**
   * Loading.
   *
   * ### Claim
   *  boservice.b2b.b2bremovefile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BRemoveFilePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BB2BRemoveFilePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    prgId?: number;
    body?: {
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<B2BServiceContractB2BFile> {

    return this.apiB2BB2BRemoveFilePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractB2BFile>) => r.body as B2BServiceContractB2BFile)
    );
  }

  /**
   * Path part for operation apiB2BListB2BLoadGet
   */
  static readonly ApiB2BListB2BLoadGetPath = '/api/B2b/ListB2bLoad';

  /**
   * Search for software downloads.
   *
   * ### Claim
   *  boservice.b2b.listb2bload
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BLoadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractLoad>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BLoadGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractLoad>>;
      })
    );
  }

  /**
   * Search for software downloads.
   *
   * ### Claim
   *  boservice.b2b.listb2bload
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BLoadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractLoad>> {

    return this.apiB2BListB2BLoadGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractLoad>>) => r.body as Array<B2BServiceContractLoad>)
    );
  }

  /**
   * Search for software downloads.
   *
   * ### Claim
   *  boservice.b2b.listb2bload
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BLoadGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractLoad>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BLoadGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractLoad>>;
      })
    );
  }

  /**
   * Search for software downloads.
   *
   * ### Claim
   *  boservice.b2b.listb2bload
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BLoadGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractLoad>> {

    return this.apiB2BListB2BLoadGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractLoad>>) => r.body as Array<B2BServiceContractLoad>)
    );
  }

  /**
   * Path part for operation apiB2BListB2BLoadDataGet
   */
  static readonly ApiB2BListB2BLoadDataGetPath = '/api/B2b/ListB2bLoadData';

  /**
   * search in download results.
   *
   * ### Claim
   *  boservice.b2b.listb2bloaddata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BLoadDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadDataGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * b2b program ID
     */
    prgId?: number;

    /**
     * If i_fl_id is NULL, then search without taking into account the file, otherwise - only by file ID
     */
    flId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;

    /**
     * last name in the download data. null - any
     */
    surname?: string;

    /**
     * name in the download data. null - any
     */
    name?: string;

    /**
     * phone number. in the download data. null - any
     */
    mobnum?: string;

    /**
     * mail in download data. null - any
     */
    email?: string;

    /**
     * card number in the download data. null - any
     */
    cardnum?: string;

    /**
     * 0 - only no errors; 1 - only with errors; otherwise (NULL or any other value) -
     *             regardless of errors
     */
    errFlg?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractLoadData>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BLoadDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('flId', params.flId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('surname', params.surname, {});
      rb.query('name', params.name, {});
      rb.query('mobnum', params.mobnum, {});
      rb.query('email', params.email, {});
      rb.query('cardnum', params.cardnum, {});
      rb.query('errFlg', params.errFlg, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractLoadData>>;
      })
    );
  }

  /**
   * search in download results.
   *
   * ### Claim
   *  boservice.b2b.listb2bloaddata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BLoadDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadDataGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * b2b program ID
     */
    prgId?: number;

    /**
     * If i_fl_id is NULL, then search without taking into account the file, otherwise - only by file ID
     */
    flId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;

    /**
     * last name in the download data. null - any
     */
    surname?: string;

    /**
     * name in the download data. null - any
     */
    name?: string;

    /**
     * phone number. in the download data. null - any
     */
    mobnum?: string;

    /**
     * mail in download data. null - any
     */
    email?: string;

    /**
     * card number in the download data. null - any
     */
    cardnum?: string;

    /**
     * 0 - only no errors; 1 - only with errors; otherwise (NULL or any other value) -
     *             regardless of errors
     */
    errFlg?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractLoadData>> {

    return this.apiB2BListB2BLoadDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractLoadData>>) => r.body as Array<B2BServiceContractLoadData>)
    );
  }

  /**
   * search in download results.
   *
   * ### Claim
   *  boservice.b2b.listb2bloaddata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BLoadDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadDataGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * b2b program ID
     */
    prgId?: number;

    /**
     * If i_fl_id is NULL, then search without taking into account the file, otherwise - only by file ID
     */
    flId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;

    /**
     * last name in the download data. null - any
     */
    surname?: string;

    /**
     * name in the download data. null - any
     */
    name?: string;

    /**
     * phone number. in the download data. null - any
     */
    mobnum?: string;

    /**
     * mail in download data. null - any
     */
    email?: string;

    /**
     * card number in the download data. null - any
     */
    cardnum?: string;

    /**
     * 0 - only no errors; 1 - only with errors; otherwise (NULL or any other value) -
     *             regardless of errors
     */
    errFlg?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractLoadData>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BLoadDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('flId', params.flId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('surname', params.surname, {});
      rb.query('name', params.name, {});
      rb.query('mobnum', params.mobnum, {});
      rb.query('email', params.email, {});
      rb.query('cardnum', params.cardnum, {});
      rb.query('errFlg', params.errFlg, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractLoadData>>;
      })
    );
  }

  /**
   * search in download results.
   *
   * ### Claim
   *  boservice.b2b.listb2bloaddata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BLoadDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BLoadDataGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * b2b program ID
     */
    prgId?: number;

    /**
     * If i_fl_id is NULL, then search without taking into account the file, otherwise - only by file ID
     */
    flId?: number;

    /**
     * "date from" to start downloading. null - unlimited
     */
    dateFrom?: string;

    /**
     * "date to" to start downloading (inclusive). null - unlimited
     */
    dateTo?: string;

    /**
     * last name in the download data. null - any
     */
    surname?: string;

    /**
     * name in the download data. null - any
     */
    name?: string;

    /**
     * phone number. in the download data. null - any
     */
    mobnum?: string;

    /**
     * mail in download data. null - any
     */
    email?: string;

    /**
     * card number in the download data. null - any
     */
    cardnum?: string;

    /**
     * 0 - only no errors; 1 - only with errors; otherwise (NULL or any other value) -
     *             regardless of errors
     */
    errFlg?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractLoadData>> {

    return this.apiB2BListB2BLoadDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractLoadData>>) => r.body as Array<B2BServiceContractLoadData>)
    );
  }

  /**
   * Path part for operation apiB2BListLoadResultGet
   */
  static readonly ApiB2BListLoadResultGetPath = '/api/B2b/ListLoadResult';

  /**
   * Getting the download result.
   *
   * ### Claim
   *  boservice.b2b.listloadresult
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListLoadResultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListLoadResultGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * File ID
     */
    flId?: number;

    /**
     * 0 - only no errors; 1 - only with errors; otherwise (NULL or any other value) -
     *             regardless of errors
     */
    errFlg?: number;
    fileName?: string;
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListLoadResultGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('flId', params.flId, {});
      rb.query('errFlg', params.errFlg, {});
      rb.query('fileName', params.fileName, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Getting the download result.
   *
   * ### Claim
   *  boservice.b2b.listloadresult
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListLoadResultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListLoadResultGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * File ID
     */
    flId?: number;

    /**
     * 0 - only no errors; 1 - only with errors; otherwise (NULL or any other value) -
     *             regardless of errors
     */
    errFlg?: number;
    fileName?: string;
    token?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiB2BListLoadResultGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiB2BMappingFilePost
   */
  static readonly ApiB2BMappingFilePostPath = '/api/B2b/MappingFile';

  /**
   * Loading a data file.
   *
   * ### Claim
   *  boservice.b2b.mappingfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BMappingFilePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BMappingFilePost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractMappingMappingAddFileRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractMappingMappingFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BMappingFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractMappingMappingFileResponse>;
      })
    );
  }

  /**
   * Loading a data file.
   *
   * ### Claim
   *  boservice.b2b.mappingfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BMappingFilePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BMappingFilePost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractMappingMappingAddFileRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractMappingMappingFileResponse> {

    return this.apiB2BMappingFilePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractMappingMappingFileResponse>) => r.body as B2BServiceContractMappingMappingFileResponse)
    );
  }

  /**
   * Loading a data file.
   *
   * ### Claim
   *  boservice.b2b.mappingfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BMappingFilePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BMappingFilePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractMappingMappingAddFileRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractMappingMappingFileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BMappingFilePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractMappingMappingFileResponse>;
      })
    );
  }

  /**
   * Loading a data file.
   *
   * ### Claim
   *  boservice.b2b.mappingfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BMappingFilePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiB2BMappingFilePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractMappingMappingAddFileRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractMappingMappingFileResponse> {

    return this.apiB2BMappingFilePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractMappingMappingFileResponse>) => r.body as B2BServiceContractMappingMappingFileResponse)
    );
  }

  /**
   * Path part for operation apiB2BListB2BClientGet
   */
  static readonly ApiB2BListB2BClientGetPath = '/api/B2b/ListB2bClient';

  /**
   * list of program's clients.
   *
   * List of clients (participants) for downloading as Excel file
   * ### Claim
   *  boservice.b2b.listb2bclient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BClientGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BClientGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	b2b program id
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BClientGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * list of program's clients.
   *
   * List of clients (participants) for downloading as Excel file
   * ### Claim
   *  boservice.b2b.listb2bclient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BClientGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BClientGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	b2b program id
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiB2BListB2BClientGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * list of program's clients.
   *
   * List of clients (participants) for downloading as Excel file
   * ### Claim
   *  boservice.b2b.listb2bclient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BClientGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BClientGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	b2b program id
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BClientGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * list of program's clients.
   *
   * List of clients (participants) for downloading as Excel file
   * ### Claim
   *  boservice.b2b.listb2bclient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BClientGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BClientGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	b2b program id
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiB2BListB2BClientGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiB2BListB2BPtTariffGet
   */
  static readonly ApiB2BListB2BPtTariffGetPath = '/api/B2b/ListB2bPtTariff';

  /**
   * List of tariffs for printed tickets.
   *
   * List of prepaid tariffs available for B2B printed tickets.
   * Returns rows available for current user.
   * ### Claim
   *  boservice.b2b.listb2bpttariff
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtTariffGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTariffGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtTariffGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>>;
      })
    );
  }

  /**
   * List of tariffs for printed tickets.
   *
   * List of prepaid tariffs available for B2B printed tickets.
   * Returns rows available for current user.
   * ### Claim
   *  boservice.b2b.listb2bpttariff
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtTariffGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTariffGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>> {

    return this.apiB2BListB2BPtTariffGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>>) => r.body as Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>)
    );
  }

  /**
   * List of tariffs for printed tickets.
   *
   * List of prepaid tariffs available for B2B printed tickets.
   * Returns rows available for current user.
   * ### Claim
   *  boservice.b2b.listb2bpttariff
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtTariffGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTariffGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtTariffGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>>;
      })
    );
  }

  /**
   * List of tariffs for printed tickets.
   *
   * List of prepaid tariffs available for B2B printed tickets.
   * Returns rows available for current user.
   * ### Claim
   *  boservice.b2b.listb2bpttariff
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtTariffGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTariffGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>> {

    return this.apiB2BListB2BPtTariffGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>>) => r.body as Array<B2BServiceContractPrintedTicketsListB2BPtTariffResponse>)
    );
  }

  /**
   * Path part for operation apiB2BListB2BPtBatchGet
   */
  static readonly ApiB2BListB2BPtBatchGetPath = '/api/B2b/ListB2bPtBatch';

  /**
   * List of printed tickets batches.
   *
   * List of batches of B2B printed tickets.
   * ### Claim
   *  boservice.b2b.listb2bptbatch
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtBatchGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtBatchGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtBatchGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>>;
      })
    );
  }

  /**
   * List of printed tickets batches.
   *
   * List of batches of B2B printed tickets.
   * ### Claim
   *  boservice.b2b.listb2bptbatch
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtBatchGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtBatchGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>> {

    return this.apiB2BListB2BPtBatchGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>>) => r.body as Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>)
    );
  }

  /**
   * List of printed tickets batches.
   *
   * List of batches of B2B printed tickets.
   * ### Claim
   *  boservice.b2b.listb2bptbatch
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtBatchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtBatchGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtBatchGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>>;
      })
    );
  }

  /**
   * List of printed tickets batches.
   *
   * List of batches of B2B printed tickets.
   * ### Claim
   *  boservice.b2b.listb2bptbatch
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtBatchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtBatchGet(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>> {

    return this.apiB2BListB2BPtBatchGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>>) => r.body as Array<B2BServiceContractPrintedTicketsListB2BPtBatchResponse>)
    );
  }

  /**
   * Path part for operation apiB2BRemoveB2BPtPost
   */
  static readonly ApiB2BRemoveB2BPtPostPath = '/api/B2b/RemoveB2bPt';

  /**
   * Remove printed tickets from batch.
   *
   * Removes unused tickets in the specified number. Returns actual number of deleted tickets.
   * ### Claim
   *  boservice.b2b.removeb2bpt
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BRemoveB2BPtPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BRemoveB2BPtPost$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsRemoveB2BPtRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractPrintedTicketsRemoveB2BPtResponse>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BRemoveB2BPtPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractPrintedTicketsRemoveB2BPtResponse>;
      })
    );
  }

  /**
   * Remove printed tickets from batch.
   *
   * Removes unused tickets in the specified number. Returns actual number of deleted tickets.
   * ### Claim
   *  boservice.b2b.removeb2bpt
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BRemoveB2BPtPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BRemoveB2BPtPost$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsRemoveB2BPtRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractPrintedTicketsRemoveB2BPtResponse> {

    return this.apiB2BRemoveB2BPtPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractPrintedTicketsRemoveB2BPtResponse>) => r.body as B2BServiceContractPrintedTicketsRemoveB2BPtResponse)
    );
  }

  /**
   * Remove printed tickets from batch.
   *
   * Removes unused tickets in the specified number. Returns actual number of deleted tickets.
   * ### Claim
   *  boservice.b2b.removeb2bpt
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BRemoveB2BPtPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BRemoveB2BPtPost$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsRemoveB2BPtRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractPrintedTicketsRemoveB2BPtResponse>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BRemoveB2BPtPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractPrintedTicketsRemoveB2BPtResponse>;
      })
    );
  }

  /**
   * Remove printed tickets from batch.
   *
   * Removes unused tickets in the specified number. Returns actual number of deleted tickets.
   * ### Claim
   *  boservice.b2b.removeb2bpt
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BRemoveB2BPtPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BRemoveB2BPtPost(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsRemoveB2BPtRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractPrintedTicketsRemoveB2BPtResponse> {

    return this.apiB2BRemoveB2BPtPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractPrintedTicketsRemoveB2BPtResponse>) => r.body as B2BServiceContractPrintedTicketsRemoveB2BPtResponse)
    );
  }

  /**
   * Path part for operation apiB2BAddB2BPtBatchPost
   */
  static readonly ApiB2BAddB2BPtBatchPostPath = '/api/B2b/AddB2bPtBatch';

  /**
   * Generate new printed tickets batch.
   *
   * Creates batch with printed tickets in it.
   * ### Claim
   *  boservice.b2b.addb2bptbatch
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BAddB2BPtBatchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BPtBatchPost$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsAddB2BPtBatchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BAddB2BPtBatchPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse>;
      })
    );
  }

  /**
   * Generate new printed tickets batch.
   *
   * Creates batch with printed tickets in it.
   * ### Claim
   *  boservice.b2b.addb2bptbatch
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BAddB2BPtBatchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BPtBatchPost$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsAddB2BPtBatchRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse> {

    return this.apiB2BAddB2BPtBatchPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse>) => r.body as B2BServiceContractPrintedTicketsAddB2BPtBatchResponse)
    );
  }

  /**
   * Generate new printed tickets batch.
   *
   * Creates batch with printed tickets in it.
   * ### Claim
   *  boservice.b2b.addb2bptbatch
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BAddB2BPtBatchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BPtBatchPost$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsAddB2BPtBatchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BAddB2BPtBatchPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse>;
      })
    );
  }

  /**
   * Generate new printed tickets batch.
   *
   * Creates batch with printed tickets in it.
   * ### Claim
   *  boservice.b2b.addb2bptbatch
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BAddB2BPtBatchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BPtBatchPost(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: B2BServiceContractPrintedTicketsAddB2BPtBatchRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse> {

    return this.apiB2BAddB2BPtBatchPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractPrintedTicketsAddB2BPtBatchResponse>) => r.body as B2BServiceContractPrintedTicketsAddB2BPtBatchResponse)
    );
  }

  /**
   * Path part for operation apiB2BListB2BPtTicketGet
   */
  static readonly ApiB2BListB2BPtTicketGetPath = '/api/B2b/ListB2bPtTicket';

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtTicketGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtTicketGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pbId', params.pbId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>>;
      })
    );
  }

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtTicketGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>> {

    return this.apiB2BListB2BPtTicketGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>>) => r.body as Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>)
    );
  }

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtTicketGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtTicketGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pbId', params.pbId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>>;
      })
    );
  }

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtTicketGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>> {

    return this.apiB2BListB2BPtTicketGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>>) => r.body as Array<B2BServiceContractPrintedTicketsListB2BPtTicketResponse>)
    );
  }

  /**
   * Path part for operation apiB2BListB2BPtTicketFileGet
   */
  static readonly ApiB2BListB2BPtTicketFileGetPath = '/api/B2b/ListB2bPtTicketFile';

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticketfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtTicketFileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketFileGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;

    /**
     * *	user id
     */
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtTicketFileGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pbId', params.pbId, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticketfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtTicketFileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketFileGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;

    /**
     * *	user id
     */
    token?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiB2BListB2BPtTicketFileGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticketfile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BPtTicketFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketFileGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;

    /**
     * *	user id
     */
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BListB2BPtTicketFileGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('pbId', params.pbId, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * List of records of batch.
   *
   * List of prepaid tariffs in batch.
   * Used internally by service for file download.
   * ### Claim
   *  boservice.b2b.listb2bptticketfile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BPtTicketFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BPtTicketFileGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	batch id
     */
    pbId?: number;

    /**
     * *	user id
     */
    token?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiB2BListB2BPtTicketFileGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiB2BB2BRiderListGet
   */
  static readonly ApiB2BB2BRiderListGetPath = '/api/B2b/B2bRiderList';

  /**
   * ### Claim
   *  boservice.b2b.b2briderlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BRiderListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BB2BRiderListGet$Plain$Response(params?: {
    lang?: string;
    prgId?: number;
    dateFrom?: string;
    dateTo?: string;
    surname?: string;
    name?: string;
    mobnum?: string;
    email?: string;
    idNumber?: string;
    cardNum?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractB2BRiderListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BB2BRiderListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('surname', params.surname, {});
      rb.query('name', params.name, {});
      rb.query('mobnum', params.mobnum, {});
      rb.query('email', params.email, {});
      rb.query('idNumber', params.idNumber, {});
      rb.query('cardNum', params.cardNum, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractB2BRiderListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.b2b.b2briderlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BRiderListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BB2BRiderListGet$Plain(params?: {
    lang?: string;
    prgId?: number;
    dateFrom?: string;
    dateTo?: string;
    surname?: string;
    name?: string;
    mobnum?: string;
    email?: string;
    idNumber?: string;
    cardNum?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractB2BRiderListResponse>> {

    return this.apiB2BB2BRiderListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractB2BRiderListResponse>>) => r.body as Array<B2BServiceContractB2BRiderListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.b2b.b2briderlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BRiderListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BB2BRiderListGet$Response(params?: {
    lang?: string;
    prgId?: number;
    dateFrom?: string;
    dateTo?: string;
    surname?: string;
    name?: string;
    mobnum?: string;
    email?: string;
    idNumber?: string;
    cardNum?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractB2BRiderListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, B2BService.ApiB2BB2BRiderListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('surname', params.surname, {});
      rb.query('name', params.name, {});
      rb.query('mobnum', params.mobnum, {});
      rb.query('email', params.email, {});
      rb.query('idNumber', params.idNumber, {});
      rb.query('cardNum', params.cardNum, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractB2BRiderListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.b2b.b2briderlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BRiderListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BB2BRiderListGet(params?: {
    lang?: string;
    prgId?: number;
    dateFrom?: string;
    dateTo?: string;
    surname?: string;
    name?: string;
    mobnum?: string;
    email?: string;
    idNumber?: string;
    cardNum?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractB2BRiderListResponse>> {

    return this.apiB2BB2BRiderListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractB2BRiderListResponse>>) => r.body as Array<B2BServiceContractB2BRiderListResponse>)
    );
  }

}
