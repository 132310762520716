/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SmsCode as SmsSerivceModelSmsCode } from '../models/SMSSerivce/Model/sms-code';

@Injectable({
  providedIn: 'root',
})
export class SmsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSmsSendSmsCodeGet
   */
  static readonly ApiSmsSendSmsCodeGetPath = '/api/Sms/SendSmsCode';

  /**
   * Send verification code via SMS.
   *
   * ### Claim
   *  boservice.sms.sendsmscode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSmsSendSmsCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSmsSendSmsCodeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * phone number
     */
    phone?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SmsSerivceModelSmsCode>> {

    const rb = new RequestBuilder(this.rootUrl, SmsService.ApiSmsSendSmsCodeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('phone', params.phone, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsSerivceModelSmsCode>;
      })
    );
  }

  /**
   * Send verification code via SMS.
   *
   * ### Claim
   *  boservice.sms.sendsmscode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSmsSendSmsCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSmsSendSmsCodeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * phone number
     */
    phone?: string;
  },
  context?: HttpContext

): Observable<SmsSerivceModelSmsCode> {

    return this.apiSmsSendSmsCodeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SmsSerivceModelSmsCode>) => r.body as SmsSerivceModelSmsCode)
    );
  }

  /**
   * Send verification code via SMS.
   *
   * ### Claim
   *  boservice.sms.sendsmscode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSmsSendSmsCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSmsSendSmsCodeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * phone number
     */
    phone?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SmsSerivceModelSmsCode>> {

    const rb = new RequestBuilder(this.rootUrl, SmsService.ApiSmsSendSmsCodeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('phone', params.phone, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsSerivceModelSmsCode>;
      })
    );
  }

  /**
   * Send verification code via SMS.
   *
   * ### Claim
   *  boservice.sms.sendsmscode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSmsSendSmsCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSmsSendSmsCodeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * phone number
     */
    phone?: string;
  },
  context?: HttpContext

): Observable<SmsSerivceModelSmsCode> {

    return this.apiSmsSendSmsCodeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<SmsSerivceModelSmsCode>) => r.body as SmsSerivceModelSmsCode)
    );
  }

}
