/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Service as BoLankaContractService } from '../models/BoLanka/Contract/service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRoutesTrmIdServiceGet
   */
  static readonly ApiRoutesTrmIdServiceGetPath = '/api/routes/{trmId}/service';

  /**
   * Getting a list of transportation organizer services.
   *
   * ### Claim
   *  boservice.routes.listtrmservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoutesTrmIdServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdServiceGet$Response(params: {

    /**
     * The current date
     */
    date?: string;

    /**
     * Transport organizer ID
     */
    trmId: number;

    /**
     * id of a basic service. if not null then function returns list of complex services which contain specified id.
     */
    basicSrvId?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractService>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceService.ApiRoutesTrmIdServiceGetPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.path('trmId', params.trmId, {});
      rb.query('basicSrvId', params.basicSrvId, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractService>>;
      })
    );
  }

  /**
   * Getting a list of transportation organizer services.
   *
   * ### Claim
   *  boservice.routes.listtrmservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRoutesTrmIdServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoutesTrmIdServiceGet(params: {

    /**
     * The current date
     */
    date?: string;

    /**
     * Transport organizer ID
     */
    trmId: number;

    /**
     * id of a basic service. if not null then function returns list of complex services which contain specified id.
     */
    basicSrvId?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractService>> {

    return this.apiRoutesTrmIdServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractService>>) => r.body as Array<BoLankaContractService>)
    );
  }

}
