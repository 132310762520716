import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrentProtocolPipe } from '@pipes/current-protocol/current-protocol.pipe';
import { NgForOf, NgIf } from '@angular/common';
import { Module } from '@api/models/UserService/Contract/module';


/**
 * Modules menu lists
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-menu-application',
    templateUrl: './menu-application.component.html',
    styleUrls: ['./menu-application.component.scss'],
    standalone: true,
    imports: [
        CurrentProtocolPipe,
        NgForOf,
        NgIf,
    ],
})
export class MenuApplicationComponent {
    /**
     * Selected code value
     */
    @Input() selected: string = window.location.hostname;
    /**
     * List menu items
     */
    @Input() menuItems: Module[];
}
