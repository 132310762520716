/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApAdAddResponse as BannerApiContractApAdAddResponse } from '../models/BannerApi/Contract/ap-ad-add-response';
import { ApAdListResponse as BannerApiContractApAdListResponse } from '../models/BannerApi/Contract/ap-ad-list-response';

@Injectable({
  providedIn: 'root',
})
export class BannerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiBannerApAdListGet
   */
  static readonly ApiBannerApAdListGetPath = '/api/Banner/ApAdList';

  /**
   * Getting a list of banners (only relevant ones).
   *
   * ### Claim
   *  boservice.banner.apadlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApAdListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApAdListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BannerApiContractApAdListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.ApiBannerApAdListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BannerApiContractApAdListResponse>>;
      })
    );
  }

  /**
   * Getting a list of banners (only relevant ones).
   *
   * ### Claim
   *  boservice.banner.apadlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApAdListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApAdListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BannerApiContractApAdListResponse>> {

    return this.apiBannerApAdListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BannerApiContractApAdListResponse>>) => r.body as Array<BannerApiContractApAdListResponse>)
    );
  }

  /**
   * Path part for operation apiBannerApAdAddPost
   */
  static readonly ApiBannerApAdAddPostPath = '/api/Banner/ApAdAdd';

  /**
   * Adding a banner.
   *
   * File Type: JPG, PNG, GIF.
   * File size: no more than 300KB.
   * Image size: 600x300 px.
   * ### Claim
   *  boservice.banner.apadadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApAdAddPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerApAdAddPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: {
'DateFrom'?: string;
'DateTo'?: string;
'Note'?: string;
'BnrUrl'?: string;
'BnrFile'?: string;
'DisplayTime'?: number;
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BannerApiContractApAdAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.ApiBannerApAdAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BannerApiContractApAdAddResponse>;
      })
    );
  }

  /**
   * Adding a banner.
   *
   * File Type: JPG, PNG, GIF.
   * File size: no more than 300KB.
   * Image size: 600x300 px.
   * ### Claim
   *  boservice.banner.apadadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApAdAddPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerApAdAddPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: {
'DateFrom'?: string;
'DateTo'?: string;
'Note'?: string;
'BnrUrl'?: string;
'BnrFile'?: string;
'DisplayTime'?: number;
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<BannerApiContractApAdAddResponse> {

    return this.apiBannerApAdAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BannerApiContractApAdAddResponse>) => r.body as BannerApiContractApAdAddResponse)
    );
  }

  /**
   * Path part for operation apiBannerApAdDeleteDelete
   */
  static readonly ApiBannerApAdDeleteDeletePath = '/api/Banner/ApAdDelete';

  /**
   * Removing a banner.
   *
   * ### Claim
   *  boservice.banner.apaddelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApAdDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApAdDeleteDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id records
     */
    adId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.ApiBannerApAdDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('adId', params.adId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a banner.
   *
   * ### Claim
   *  boservice.banner.apaddelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApAdDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApAdDeleteDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id records
     */
    adId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiBannerApAdDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
