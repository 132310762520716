/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddUserRoleRequest as UserServiceContractAddUserRoleRequest } from '../models/UserService/Contract/add-user-role-request';
import { ListOrgRolesResponse as UserServiceContractListOrgRolesResponse } from '../models/UserService/Contract/list-org-roles-response';
import { SetOrgRolesRequest as UserServiceContractSetOrgRolesRequest } from '../models/UserService/Contract/set-org-roles-request';
import { UserRole as UserServiceContractUserRole } from '../models/UserService/Contract/user-role';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUsersListRolesUsrIdGet
   */
  static readonly ApiUsersListRolesUsrIdGetPath = '/api/Users/ListRoles/{usrId}';

  /**
   * Get a list of roles.
   *
   * ### Claim
   *  boservice.users.listroles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListRolesUsrIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListRolesUsrIdGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User ID
     */
    usrId: number;

    /**
     * organization/department id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractUserRole>>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersListRolesUsrIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('usrId', params.usrId, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractUserRole>>;
      })
    );
  }

  /**
   * Get a list of roles.
   *
   * ### Claim
   *  boservice.users.listroles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListRolesUsrIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListRolesUsrIdGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User ID
     */
    usrId: number;

    /**
     * organization/department id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractUserRole>> {

    return this.apiUsersListRolesUsrIdGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractUserRole>>) => r.body as Array<UserServiceContractUserRole>)
    );
  }

  /**
   * Get a list of roles.
   *
   * ### Claim
   *  boservice.users.listroles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListRolesUsrIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListRolesUsrIdGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User ID
     */
    usrId: number;

    /**
     * organization/department id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractUserRole>>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersListRolesUsrIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('usrId', params.usrId, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractUserRole>>;
      })
    );
  }

  /**
   * Get a list of roles.
   *
   * ### Claim
   *  boservice.users.listroles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListRolesUsrIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListRolesUsrIdGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User ID
     */
    usrId: number;

    /**
     * organization/department id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractUserRole>> {

    return this.apiUsersListRolesUsrIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractUserRole>>) => r.body as Array<UserServiceContractUserRole>)
    );
  }

  /**
   * Path part for operation apiUsersAddUserRolePost
   */
  static readonly ApiUsersAddUserRolePostPath = '/api/Users/AddUserRole';

  /**
   * Adding a user role.
   *
   * ### Claim
   *  boservice.users.adduserrole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersAddUserRolePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddUserRolePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User role to add
     */
    body?: UserServiceContractAddUserRoleRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersAddUserRolePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adding a user role.
   *
   * ### Claim
   *  boservice.users.adduserrole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersAddUserRolePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersAddUserRolePost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * User role to add
     */
    body?: UserServiceContractAddUserRoleRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersAddUserRolePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersRemoveUserRoleSetUserIdDelete
   */
  static readonly ApiUsersRemoveUserRoleSetUserIdDeletePath = '/api/Users/RemoveUserRole/{setUserId}';

  /**
   * Removing a user role.
   *
   * ### Claim
   *  boservice.users.removeuserrole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersRemoveUserRoleSetUserIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersRemoveUserRoleSetUserIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Edited User ID
     */
    setUserId: number;

    /**
     * ID role
     */
    role?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersRemoveUserRoleSetUserIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('setUserId', params.setUserId, {});
      rb.query('role', params.role, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a user role.
   *
   * ### Claim
   *  boservice.users.removeuserrole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersRemoveUserRoleSetUserIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersRemoveUserRoleSetUserIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Edited User ID
     */
    setUserId: number;

    /**
     * ID role
     */
    role?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersRemoveUserRoleSetUserIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersListOrgRolesGet
   */
  static readonly ApiUsersListOrgRolesGetPath = '/api/Users/ListOrgRoles';

  /**
   * List of roles for organization.
   *
   * ### Claim
   *  boservice.users.listorgroles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListOrgRolesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListOrgRolesGet$Plain$Response(params?: {

    /**
     * language code
     */
    lang?: string;

    /**
     * *	organization id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractListOrgRolesResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersListOrgRolesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractListOrgRolesResponse>>;
      })
    );
  }

  /**
   * List of roles for organization.
   *
   * ### Claim
   *  boservice.users.listorgroles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListOrgRolesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListOrgRolesGet$Plain(params?: {

    /**
     * language code
     */
    lang?: string;

    /**
     * *	organization id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractListOrgRolesResponse>> {

    return this.apiUsersListOrgRolesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractListOrgRolesResponse>>) => r.body as Array<UserServiceContractListOrgRolesResponse>)
    );
  }

  /**
   * List of roles for organization.
   *
   * ### Claim
   *  boservice.users.listorgroles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersListOrgRolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListOrgRolesGet$Response(params?: {

    /**
     * language code
     */
    lang?: string;

    /**
     * *	organization id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserServiceContractListOrgRolesResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersListOrgRolesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserServiceContractListOrgRolesResponse>>;
      })
    );
  }

  /**
   * List of roles for organization.
   *
   * ### Claim
   *  boservice.users.listorgroles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersListOrgRolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersListOrgRolesGet(params?: {

    /**
     * language code
     */
    lang?: string;

    /**
     * *	organization id
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<Array<UserServiceContractListOrgRolesResponse>> {

    return this.apiUsersListOrgRolesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserServiceContractListOrgRolesResponse>>) => r.body as Array<UserServiceContractListOrgRolesResponse>)
    );
  }

  /**
   * Path part for operation apiUsersSetOrgRolesPost
   */
  static readonly ApiUsersSetOrgRolesPostPath = '/api/Users/SetOrgRoles';

  /**
   * Set roles for organization.
   *
   * ### Claim
   *  boservice.users.setorgroles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersSetOrgRolesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetOrgRolesPost$Response(params?: {

    /**
     * language code
     */
    lang?: string;
    body?: UserServiceContractSetOrgRolesRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.ApiUsersSetOrgRolesPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set roles for organization.
   *
   * ### Claim
   *  boservice.users.setorgroles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUsersSetOrgRolesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUsersSetOrgRolesPost(params?: {

    /**
     * language code
     */
    lang?: string;
    body?: UserServiceContractSetOrgRolesRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiUsersSetOrgRolesPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
