import { NgModule } from '@angular/core';
import { StartPreloaderComponent } from './start-preloader.component';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';


@NgModule({
    declarations: [StartPreloaderComponent],
    imports: [PreLoaderModule],
    exports: [StartPreloaderComponent],
})
export class StartPreloaderModule {
}
