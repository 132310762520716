/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AgentService } from './services/agent.service';
import { AgentSitesService } from './services/agent-sites.service';
import { AnalyticApiService } from './services/analytic-api.service';
import { AntifrodService } from './services/antifrod.service';
import { B2BService } from './services/b-2-b.service';
import { ProgramService } from './services/program.service';
import { InvoiceService } from './services/invoice.service';
import { ManualRiderOperationsService } from './services/manual-rider-operations.service';
import { BannerService } from './services/banner.service';
import { DesignElementsService } from './services/design-elements.service';
import { BatchLoadService } from './services/batch-load.service';
import { ConfigurationService } from './services/configuration.service';
import { CouponService } from './services/coupon.service';
import { DataTransferService } from './services/data-transfer.service';
import { EmailService } from './services/email.service';
import { EventService } from './services/event.service';
import { InventoryService } from './services/inventory.service';
import { LoginService } from './services/login.service';
import { MonitoringService } from './services/monitoring.service';
import { MonitoringWebService } from './services/monitoring-web.service';
import { ProviderService } from './services/provider.service';
import { ProviderEditorService } from './services/provider-editor.service';
import { RoamingService } from './services/roaming.service';
import { DomainService } from './services/domain.service';
import { ServiceService } from './services/service.service';
import { RoutesService } from './services/routes.service';
import { ProviderServiceService } from './services/provider-service.service';
import { ConductorService } from './services/conductor.service';
import { SettingsService } from './services/settings.service';
import { SmsService } from './services/sms.service';
import { SupportService } from './services/support.service';
import { BlockService } from './services/block.service';
import { RefundService } from './services/refund.service';
import { PrivService } from './services/priv.service';
import { PhotoService } from './services/photo.service';
import { ParkingService } from './services/parking.service';
import { TicketsService } from './services/tickets.service';
import { CourtesyService } from './services/courtesy.service';
import { ChangePhoneEmailService } from './services/change-phone-email.service';
import { ChargebackService } from './services/chargeback.service';
import { TariffService } from './services/tariff.service';
import { CategoriesOfBenefitsService } from './services/categories-of-benefits.service';
import { DocumentTypesService } from './services/document-types.service';
import { TariffGroupsService } from './services/tariff-groups.service';
import { PeriodsService } from './services/periods.service';
import { SpecialDatesService } from './services/special-dates.service';
import { UpchargeService } from './services/upcharge.service';
import { UsersService } from './services/users.service';
import { OrganizationService } from './services/organization.service';
import { RolesService } from './services/roles.service';
import { VisOrgService } from './services/vis-org.service';
import { ModulesService } from './services/modules.service';
import { VersionService } from './services/version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AgentService,
    AgentSitesService,
    AnalyticApiService,
    AntifrodService,
    B2BService,
    ProgramService,
    InvoiceService,
    ManualRiderOperationsService,
    BannerService,
    DesignElementsService,
    BatchLoadService,
    ConfigurationService,
    CouponService,
    DataTransferService,
    EmailService,
    EventService,
    InventoryService,
    LoginService,
    MonitoringService,
    MonitoringWebService,
    ProviderService,
    ProviderEditorService,
    RoamingService,
    DomainService,
    ServiceService,
    RoutesService,
    ProviderServiceService,
    ConductorService,
    SettingsService,
    SmsService,
    SupportService,
    BlockService,
    RefundService,
    PrivService,
    PhotoService,
    ParkingService,
    TicketsService,
    CourtesyService,
    ChangePhoneEmailService,
    ChargebackService,
    TariffService,
    CategoriesOfBenefitsService,
    DocumentTypesService,
    TariffGroupsService,
    PeriodsService,
    SpecialDatesService,
    UpchargeService,
    UsersService,
    OrganizationService,
    RolesService,
    VisOrgService,
    ModulesService,
    VersionService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
