/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddB2BInvoiceRequest as B2BServiceContractInvoiceAddB2BInvoiceRequest } from '../models/B2bService/Contract/Invoice/add-b-2-b-invoice-request';
import { AddB2BInvoiceResponse as B2BServiceContractInvoiceAddB2BInvoiceResponse } from '../models/B2bService/Contract/Invoice/add-b-2-b-invoice-response';
import { GetB2BInvoiceResponse as B2BServiceContractInvoiceGetB2BInvoiceResponse } from '../models/B2bService/Contract/Invoice/get-b-2-b-invoice-response';
import { ListB2BInvoiceResponse as B2BServiceContractInvoiceListB2BInvoiceResponse } from '../models/B2bService/Contract/Invoice/list-b-2-b-invoice-response';
import { ListB2BInvoiceStatusResponse as B2BServiceContractInvoiceListB2BInvoiceStatusResponse } from '../models/B2bService/Contract/Invoice/list-b-2-b-invoice-status-response';
import { SetB2BInvoiceRequest as B2BServiceContractInvoiceSetB2BInvoiceRequest } from '../models/B2bService/Contract/Invoice/set-b-2-b-invoice-request';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiB2BListB2BInvoiceStatusGet
   */
  static readonly ApiB2BListB2BInvoiceStatusGetPath = '/api/B2b/ListB2bInvoiceStatus';

  /**
   * Getting a list of account statuses.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoicestatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BInvoiceStatusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceStatusGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BListB2BInvoiceStatusGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>>;
      })
    );
  }

  /**
   * Getting a list of account statuses.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoicestatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BInvoiceStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceStatusGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>> {

    return this.apiB2BListB2BInvoiceStatusGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>>) => r.body as Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>)
    );
  }

  /**
   * Getting a list of account statuses.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoicestatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BInvoiceStatusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceStatusGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BListB2BInvoiceStatusGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>>;
      })
    );
  }

  /**
   * Getting a list of account statuses.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoicestatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BInvoiceStatusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceStatusGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>> {

    return this.apiB2BListB2BInvoiceStatusGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>>) => r.body as Array<B2BServiceContractInvoiceListB2BInvoiceStatusResponse>)
    );
  }

  /**
   * Path part for operation apiB2BListB2BInvoiceGet
   */
  static readonly ApiB2BListB2BInvoiceGetPath = '/api/B2b/ListB2bInvoice';

  /**
   * Getting a list of accounts.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BInvoiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID (required)
     */
    prgId?: number;

    /**
     * invoice date since (inclusive) null
     */
    dateFrom?: string;

    /**
     * invoice date up to (inclusive) null
     */
    dateTo?: string;

    /**
     * account status code null
     */
    stCode?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BListB2BInvoiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('stCode', params.stCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>>;
      })
    );
  }

  /**
   * Getting a list of accounts.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BInvoiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID (required)
     */
    prgId?: number;

    /**
     * invoice date since (inclusive) null
     */
    dateFrom?: string;

    /**
     * invoice date up to (inclusive) null
     */
    dateTo?: string;

    /**
     * account status code null
     */
    stCode?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>> {

    return this.apiB2BListB2BInvoiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>>) => r.body as Array<B2BServiceContractInvoiceListB2BInvoiceResponse>)
    );
  }

  /**
   * Getting a list of accounts.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BListB2BInvoiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID (required)
     */
    prgId?: number;

    /**
     * invoice date since (inclusive) null
     */
    dateFrom?: string;

    /**
     * invoice date up to (inclusive) null
     */
    dateTo?: string;

    /**
     * account status code null
     */
    stCode?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BListB2BInvoiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('stCode', params.stCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>>;
      })
    );
  }

  /**
   * Getting a list of accounts.
   *
   * ### Claim
   *  boservice.b2b.listb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BListB2BInvoiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BListB2BInvoiceGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID (required)
     */
    prgId?: number;

    /**
     * invoice date since (inclusive) null
     */
    dateFrom?: string;

    /**
     * invoice date up to (inclusive) null
     */
    dateTo?: string;

    /**
     * account status code null
     */
    stCode?: number;
  },
  context?: HttpContext

): Observable<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>> {

    return this.apiB2BListB2BInvoiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<B2BServiceContractInvoiceListB2BInvoiceResponse>>) => r.body as Array<B2BServiceContractInvoiceListB2BInvoiceResponse>)
    );
  }

  /**
   * Path part for operation apiB2BAddB2BInvoicePost
   */
  static readonly ApiB2BAddB2BInvoicePostPath = '/api/B2b/AddB2bInvoice';

  /**
   * Creating an account for a b2b program.
   *
   * ### Claim
   *  boservice.b2b.addb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BAddB2BInvoicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BInvoicePost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractInvoiceAddB2BInvoiceRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractInvoiceAddB2BInvoiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BAddB2BInvoicePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractInvoiceAddB2BInvoiceResponse>;
      })
    );
  }

  /**
   * Creating an account for a b2b program.
   *
   * ### Claim
   *  boservice.b2b.addb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BAddB2BInvoicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BInvoicePost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractInvoiceAddB2BInvoiceRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractInvoiceAddB2BInvoiceResponse> {

    return this.apiB2BAddB2BInvoicePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractInvoiceAddB2BInvoiceResponse>) => r.body as B2BServiceContractInvoiceAddB2BInvoiceResponse)
    );
  }

  /**
   * Creating an account for a b2b program.
   *
   * ### Claim
   *  boservice.b2b.addb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BAddB2BInvoicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BInvoicePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractInvoiceAddB2BInvoiceRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractInvoiceAddB2BInvoiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BAddB2BInvoicePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractInvoiceAddB2BInvoiceResponse>;
      })
    );
  }

  /**
   * Creating an account for a b2b program.
   *
   * ### Claim
   *  boservice.b2b.addb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BAddB2BInvoicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BAddB2BInvoicePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractInvoiceAddB2BInvoiceRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractInvoiceAddB2BInvoiceResponse> {

    return this.apiB2BAddB2BInvoicePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractInvoiceAddB2BInvoiceResponse>) => r.body as B2BServiceContractInvoiceAddB2BInvoiceResponse)
    );
  }

  /**
   * Path part for operation apiB2BRemoveB2BInvoiceDelete
   */
  static readonly ApiB2BRemoveB2BInvoiceDeletePath = '/api/B2b/RemoveB2bInvoice';

  /**
   * Deleting an account.
   *
   * ### Claim
   *  boservice.b2b.removeb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BRemoveB2BInvoiceDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BRemoveB2BInvoiceDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BRemoveB2BInvoiceDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('invId', params.invId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting an account.
   *
   * ### Claim
   *  boservice.b2b.removeb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BRemoveB2BInvoiceDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BRemoveB2BInvoiceDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiB2BRemoveB2BInvoiceDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiB2BSetB2BInvoicePost
   */
  static readonly ApiB2BSetB2BInvoicePostPath = '/api/B2b/SetB2bInvoice';

  /**
   * Account status change.
   *
   * ### Claim
   *  boservice.b2b.setb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BSetB2BInvoicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BSetB2BInvoicePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractInvoiceSetB2BInvoiceRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BSetB2BInvoicePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Account status change.
   *
   * ### Claim
   *  boservice.b2b.setb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BSetB2BInvoicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BSetB2BInvoicePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: B2BServiceContractInvoiceSetB2BInvoiceRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiB2BSetB2BInvoicePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiB2BGetB2BInvoiceGet
   */
  static readonly ApiB2BGetB2BInvoiceGetPath = '/api/B2b/GetB2bInvoice';

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BGetB2BInvoiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoiceGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractInvoiceGetB2BInvoiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BGetB2BInvoiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('invId', params.invId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractInvoiceGetB2BInvoiceResponse>;
      })
    );
  }

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BGetB2BInvoiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoiceGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
  },
  context?: HttpContext

): Observable<B2BServiceContractInvoiceGetB2BInvoiceResponse> {

    return this.apiB2BGetB2BInvoiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractInvoiceGetB2BInvoiceResponse>) => r.body as B2BServiceContractInvoiceGetB2BInvoiceResponse)
    );
  }

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BGetB2BInvoiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoiceGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractInvoiceGetB2BInvoiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BGetB2BInvoiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('invId', params.invId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractInvoiceGetB2BInvoiceResponse>;
      })
    );
  }

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BGetB2BInvoiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoiceGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
  },
  context?: HttpContext

): Observable<B2BServiceContractInvoiceGetB2BInvoiceResponse> {

    return this.apiB2BGetB2BInvoiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractInvoiceGetB2BInvoiceResponse>) => r.body as B2BServiceContractInvoiceGetB2BInvoiceResponse)
    );
  }

  /**
   * Path part for operation apiB2BGetB2BInvoicePdfGet
   */
  static readonly ApiB2BGetB2BInvoicePdfGetPath = '/api/B2b/GetB2bInvoicePdf';

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoicepdf
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BGetB2BInvoicePdfGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoicePdfGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BGetB2BInvoicePdfGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('invId', params.invId, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoicepdf
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BGetB2BInvoicePdfGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoicePdfGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
    token?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiB2BGetB2BInvoicePdfGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoicepdf
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BGetB2BInvoicePdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoicePdfGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.ApiB2BGetB2BInvoicePdfGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('invId', params.invId, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Getting invoice data for a document.
   *
   * ### Claim
   *  boservice.b2b.getb2binvoicepdf
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BGetB2BInvoicePdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiB2BGetB2BInvoicePdfGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * account ID
     */
    invId?: number;
    token?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiB2BGetB2BInvoicePdfGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
