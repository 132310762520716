/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddPrivCategoryRequest as TariffEditorApiContractAddPrivCategoryRequest } from '../models/TariffEditorApi/Contract/add-priv-category-request';
import { AddPrivCategoryResponse as TariffEditorApiContractAddPrivCategoryResponse } from '../models/TariffEditorApi/Contract/add-priv-category-response';
import { GetPrivCategDataResponse as TariffEditorApiContractGetPrivCategDataResponse } from '../models/TariffEditorApi/Contract/get-priv-categ-data-response';
import { ListDoctypeResponse as TariffEditorApiContractListDoctypeResponse } from '../models/TariffEditorApi/Contract/list-doctype-response';
import { SetPrivCategDataRequest as TariffEditorApiContractSetPrivCategDataRequest } from '../models/TariffEditorApi/Contract/set-priv-categ-data-request';

@Injectable({
  providedIn: 'root',
})
export class CategoriesOfBenefitsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffAddPrivCategoryPost
   */
  static readonly ApiTariffAddPrivCategoryPostPath = '/api/Tariff/AddPrivCategory';

  /**
   * Adding a New Benefit Category.
   *
   * ### Claim
   *  boservice.tariff.addprivcategory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddPrivCategoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddPrivCategoryPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddPrivCategoryRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddPrivCategoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffAddPrivCategoryPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddPrivCategoryResponse>;
      })
    );
  }

  /**
   * Adding a New Benefit Category.
   *
   * ### Claim
   *  boservice.tariff.addprivcategory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddPrivCategoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddPrivCategoryPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddPrivCategoryRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddPrivCategoryResponse> {

    return this.apiTariffAddPrivCategoryPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddPrivCategoryResponse>) => r.body as TariffEditorApiContractAddPrivCategoryResponse)
    );
  }

  /**
   * Adding a New Benefit Category.
   *
   * ### Claim
   *  boservice.tariff.addprivcategory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddPrivCategoryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddPrivCategoryPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddPrivCategoryRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddPrivCategoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffAddPrivCategoryPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddPrivCategoryResponse>;
      })
    );
  }

  /**
   * Adding a New Benefit Category.
   *
   * ### Claim
   *  boservice.tariff.addprivcategory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddPrivCategoryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddPrivCategoryPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddPrivCategoryRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddPrivCategoryResponse> {

    return this.apiTariffAddPrivCategoryPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddPrivCategoryResponse>) => r.body as TariffEditorApiContractAddPrivCategoryResponse)
    );
  }

  /**
   * Path part for operation apiTariffSetPrivCategDataPost
   */
  static readonly ApiTariffSetPrivCategDataPostPath = '/api/Tariff/SetPrivCategData';

  /**
   * Changing Benefit Category Settings.
   *
   * ### Claim
   *  boservice.tariff.setprivcategdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetPrivCategDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetPrivCategDataPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetPrivCategDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffSetPrivCategDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing Benefit Category Settings.
   *
   * ### Claim
   *  boservice.tariff.setprivcategdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetPrivCategDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetPrivCategDataPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetPrivCategDataRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetPrivCategDataPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffGetPrivCategDataGet
   */
  static readonly ApiTariffGetPrivCategDataGetPath = '/api/Tariff/GetPrivCategData';

  /**
   * Get data by benefit category.
   *
   * ### Claim
   *  boservice.tariff.getprivcategdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetPrivCategDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetPrivCategDataGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetPrivCategDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffGetPrivCategDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('privId', params.privId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetPrivCategDataResponse>;
      })
    );
  }

  /**
   * Get data by benefit category.
   *
   * ### Claim
   *  boservice.tariff.getprivcategdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetPrivCategDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetPrivCategDataGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetPrivCategDataResponse> {

    return this.apiTariffGetPrivCategDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetPrivCategDataResponse>) => r.body as TariffEditorApiContractGetPrivCategDataResponse)
    );
  }

  /**
   * Get data by benefit category.
   *
   * ### Claim
   *  boservice.tariff.getprivcategdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetPrivCategDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetPrivCategDataGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetPrivCategDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffGetPrivCategDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('privId', params.privId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetPrivCategDataResponse>;
      })
    );
  }

  /**
   * Get data by benefit category.
   *
   * ### Claim
   *  boservice.tariff.getprivcategdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetPrivCategDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetPrivCategDataGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetPrivCategDataResponse> {

    return this.apiTariffGetPrivCategDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetPrivCategDataResponse>) => r.body as TariffEditorApiContractGetPrivCategDataResponse)
    );
  }

  /**
   * Path part for operation apiTariffListDoctypeGet
   */
  static readonly ApiTariffListDoctypeGetPath = '/api/Tariff/ListDoctype';

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListDoctypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffListDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>;
      })
    );
  }

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListDoctypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListDoctypeResponse>> {

    return this.apiTariffListDoctypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>) => r.body as Array<TariffEditorApiContractListDoctypeResponse>)
    );
  }

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListDoctypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesOfBenefitsService.ApiTariffListDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>;
      })
    );
  }

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListDoctypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListDoctypeResponse>> {

    return this.apiTariffListDoctypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>) => r.body as Array<TariffEditorApiContractListDoctypeResponse>)
    );
  }

}
