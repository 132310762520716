export function LocalStorage(key?: string) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (target: Object, propName: string) => {
        const factKey: string = key || propName;

        function getValue(): string {
            return localStorage.getItem(factKey);
        }

        function setValue(val: unknown): void {
            if (val) {
                localStorage.setItem(factKey, typeof val === 'string' ? val : JSON.stringify(val));
            } else {
                localStorage.removeItem(factKey);
            }
        }

        Object.defineProperty(target, propName, {
            configurable: true,
            enumerable: true,
            get: getValue,
            set: setValue,
        });
    };
}
