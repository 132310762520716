<div class="logo"
     *ngIf="logo"
     [ngClass]="logo.additionalClass ? 'logo--' + logo.additionalClass : null">

    <a class="a--border-off" [routerLink]="routeEntity.Root">
        <img class="logo__image"
             [height]="logo.height ? logo.height : null"
             [width]="logo.width ? logo.width : null"
             [ngSrc]="logo.path + '.' + logo.ext"
             alt="" />
    </a>
</div>
