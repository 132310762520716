import { HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpTranslateLoaderFactory } from '@core/app/app.module.helpers';
import { defaultLocalizationConfig, LOCALIZATION_CONFIG } from '@core/localization/localization.config';


@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslateLoaderFactory,
                deps: [HttpBackend],
            },
        }),
    ],
    providers: [
        { provide: LOCALIZATION_CONFIG, useValue: defaultLocalizationConfig },
    ],
})
export class LocalizationModule {
}
