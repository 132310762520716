import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { MonitoringErrorFilterState } from '@libs/monitoring/state/monitoring-error-filter.state';


@NgModule({
    imports: [
        CommonAppModule,
        AppRoutingModule,
    ],
    providers: [

        MonitoringErrorFilterState,
    ],
    bootstrap: [CommonAppComponent],
})
export class AppModule {
}
