/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EvtEventAddRequest as EventModuleApiContractEventEvtEventAddRequest } from '../models/EventModuleApi/Contract/Event/evt-event-add-request';
import { EvtEventAddResponse as EventModuleApiContractEventEvtEventAddResponse } from '../models/EventModuleApi/Contract/Event/evt-event-add-response';
import { EvtEventListResponse as EventModuleApiContractEventEvtEventListResponse } from '../models/EventModuleApi/Contract/Event/evt-event-list-response';
import { EvtEventPackageAddRequest as EventModuleApiContractEventEvtEventPackageAddRequest } from '../models/EventModuleApi/Contract/Event/evt-event-package-add-request';
import { EvtEventPackageDelRequest as EventModuleApiContractEventEvtEventPackageDelRequest } from '../models/EventModuleApi/Contract/Event/evt-event-package-del-request';
import { EvtEventPackageListResponse as EventModuleApiContractEventEvtEventPackageListResponse } from '../models/EventModuleApi/Contract/Event/evt-event-package-list-response';
import { EvtEventSetRequest as EventModuleApiContractEventEvtEventSetRequest } from '../models/EventModuleApi/Contract/Event/evt-event-set-request';
import { EvtTariffListResponse as EventModuleApiContractEventEvtTariffListResponse } from '../models/EventModuleApi/Contract/Event/evt-tariff-list-response';
import { EvtEventTagListResponseBo as EventModuleApiContractEvtEventTagListResponseBo } from '../models/EventModuleApi/Contract/evt-event-tag-list-response-bo';

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEventModuleApiEvtTariffListGet
   */
  static readonly ApiEventModuleApiEvtTariffListGetPath = '/api/EventModuleApi/EvtTariffList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttarifflist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtTariffListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTariffListGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractEventEvtTariffListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtTariffListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractEventEvtTariffListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttarifflist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtTariffListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTariffListGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractEventEvtTariffListResponse>> {

    return this.apiEventModuleApiEvtTariffListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractEventEvtTariffListResponse>>) => r.body as Array<EventModuleApiContractEventEvtTariffListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttarifflist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtTariffListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTariffListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractEventEvtTariffListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtTariffListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractEventEvtTariffListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evttarifflist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtTariffListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtTariffListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractEventEvtTariffListResponse>> {

    return this.apiEventModuleApiEvtTariffListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractEventEvtTariffListResponse>>) => r.body as Array<EventModuleApiContractEventEvtTariffListResponse>)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventListGet
   */
  static readonly ApiEventModuleApiEvtEventListGetPath = '/api/EventModuleApi/EvtEventList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventListGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractEventEvtEventListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractEventEvtEventListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventListGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractEventEvtEventListResponse>> {

    return this.apiEventModuleApiEvtEventListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractEventEvtEventListResponse>>) => r.body as Array<EventModuleApiContractEventEvtEventListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractEventEvtEventListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractEventEvtEventListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractEventEvtEventListResponse>> {

    return this.apiEventModuleApiEvtEventListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractEventEvtEventListResponse>>) => r.body as Array<EventModuleApiContractEventEvtEventListResponse>)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventPackageListGet
   */
  static readonly ApiEventModuleApiEvtEventPackageListGetPath = '/api/EventModuleApi/EvtEventPackageList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackagelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventPackageListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventPackageListGet$Plain$Response(params?: {
    lang?: string;
    evtId?: number;
    pkgDate?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractEventEvtEventPackageListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventPackageListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('evtId', params.evtId, {});
      rb.query('pkgDate', params.pkgDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractEventEvtEventPackageListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackagelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventPackageListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventPackageListGet$Plain(params?: {
    lang?: string;
    evtId?: number;
    pkgDate?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractEventEvtEventPackageListResponse>> {

    return this.apiEventModuleApiEvtEventPackageListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractEventEvtEventPackageListResponse>>) => r.body as Array<EventModuleApiContractEventEvtEventPackageListResponse>)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackagelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventPackageListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventPackageListGet$Response(params?: {
    lang?: string;
    evtId?: number;
    pkgDate?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventModuleApiContractEventEvtEventPackageListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventPackageListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('evtId', params.evtId, {});
      rb.query('pkgDate', params.pkgDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventModuleApiContractEventEvtEventPackageListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackagelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventPackageListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventPackageListGet(params?: {
    lang?: string;
    evtId?: number;
    pkgDate?: string;
  },
  context?: HttpContext

): Observable<Array<EventModuleApiContractEventEvtEventPackageListResponse>> {

    return this.apiEventModuleApiEvtEventPackageListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventModuleApiContractEventEvtEventPackageListResponse>>) => r.body as Array<EventModuleApiContractEventEvtEventPackageListResponse>)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventAddPost
   */
  static readonly ApiEventModuleApiEvtEventAddPostPath = '/api/EventModuleApi/EvtEventAdd';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventAddPost$Plain$Response(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractEventEvtEventAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractEventEvtEventAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventAddPost$Plain(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventAddRequest
  },
  context?: HttpContext

): Observable<EventModuleApiContractEventEvtEventAddResponse> {

    return this.apiEventModuleApiEvtEventAddPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractEventEvtEventAddResponse>) => r.body as EventModuleApiContractEventEvtEventAddResponse)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventAddPost$Response(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractEventEvtEventAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractEventEvtEventAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventAddPost(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventAddRequest
  },
  context?: HttpContext

): Observable<EventModuleApiContractEventEvtEventAddResponse> {

    return this.apiEventModuleApiEvtEventAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractEventEvtEventAddResponse>) => r.body as EventModuleApiContractEventEvtEventAddResponse)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventDelDelete
   */
  static readonly ApiEventModuleApiEvtEventDelDeletePath = '/api/EventModuleApi/EvtEventDel';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventdel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventDelDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventDelDelete$Response(params?: {
    usdId?: number;
    lang?: string;
    evtId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventDelDeletePath, 'delete');
    if (params) {
      rb.query('usdId', params.usdId, {});
      rb.query('lang', params.lang, {});
      rb.query('evtId', params.evtId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventdel
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventDelDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventDelDelete(params?: {
    usdId?: number;
    lang?: string;
    evtId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiEventModuleApiEvtEventDelDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventSetPost
   */
  static readonly ApiEventModuleApiEvtEventSetPostPath = '/api/EventModuleApi/EvtEventSet';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventSetPost$Response(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventSetPost(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiEventModuleApiEvtEventSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventPackageAddPost
   */
  static readonly ApiEventModuleApiEvtEventPackageAddPostPath = '/api/EventModuleApi/EvtEventPackageAdd';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackageadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventPackageAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventPackageAddPost$Response(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventPackageAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventPackageAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackageadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventPackageAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventPackageAddPost(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventPackageAddRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiEventModuleApiEvtEventPackageAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventPackageDelPost
   */
  static readonly ApiEventModuleApiEvtEventPackageDelPostPath = '/api/EventModuleApi/EvtEventPackageDel';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackagedel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventPackageDelPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventPackageDelPost$Response(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventPackageDelRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventPackageDelPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventpackagedel
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventPackageDelPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEventModuleApiEvtEventPackageDelPost(params?: {
    lang?: string;
    body?: EventModuleApiContractEventEvtEventPackageDelRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiEventModuleApiEvtEventPackageDelPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventModuleApiEvtEventTagListGet
   */
  static readonly ApiEventModuleApiEvtEventTagListGetPath = '/api/EventModuleApi/EvtEventTagList';

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventtaglist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventTagListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventTagListGet$Plain$Response(params?: {
    lang?: string;
    evtId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractEvtEventTagListResponseBo>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventTagListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('evtId', params.evtId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractEvtEventTagListResponseBo>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventtaglist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventTagListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventTagListGet$Plain(params?: {
    lang?: string;
    evtId?: number;
  },
  context?: HttpContext

): Observable<EventModuleApiContractEvtEventTagListResponseBo> {

    return this.apiEventModuleApiEvtEventTagListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractEvtEventTagListResponseBo>) => r.body as EventModuleApiContractEvtEventTagListResponseBo)
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventtaglist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventModuleApiEvtEventTagListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventTagListGet$Response(params?: {
    lang?: string;
    evtId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventModuleApiContractEvtEventTagListResponseBo>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.ApiEventModuleApiEvtEventTagListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('evtId', params.evtId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventModuleApiContractEvtEventTagListResponseBo>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.eventmoduleapi.evteventtaglist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEventModuleApiEvtEventTagListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventModuleApiEvtEventTagListGet(params?: {
    lang?: string;
    evtId?: number;
  },
  context?: HttpContext

): Observable<EventModuleApiContractEvtEventTagListResponseBo> {

    return this.apiEventModuleApiEvtEventTagListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventModuleApiContractEvtEventTagListResponseBo>) => r.body as EventModuleApiContractEvtEventTagListResponseBo)
    );
  }

}
