import { StartWithUnit } from '@core/operators/start-with-unit';
import { ErrorResponse } from '@core/api/models/error-response.model';
import { Module } from '@api/models/UserService/Contract/module';
import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { Observable } from 'rxjs';
import { ModulesService } from '@api/services/modules.service';
import { APP_NAME } from '@libs/constants';


export interface IAppState {
    loading: Record<StartWithUnit, boolean>;
    errors: Record<StartWithUnit, ErrorResponse>;
    modules: Module[];
}

export interface IAppAction {
    requestModules: Module[];
}

@Injectable({
    providedIn: 'root',
})
export class AppState extends RxState<IAppState> {
    readonly #modulesService: ModulesService = inject(ModulesService);
    readonly #actions: RxActions<IAppAction> = new RxActionFactory<IAppAction>().create();

    public readonly modules$: Observable<Module[]> = this.select('modules');
    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');


    constructor() {
        super();

        this.connectSelectors();
        this.setDefaultState();
    }

    public requestModules(): void {
        this.hold(
            this.#modulesService.apiUsersListModulesGet({
                appName: APP_NAME,
            }),
            this.#actions.requestModules,
        );
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            errors: null,
            modules: [],
        });
    }

    private connectSelectors(): void {
        this.connect('modules', this.#actions.requestModules$);
    }

}
