/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BasketItemHist as ClientSupportApiContractBasketItemHist } from '../models/ClientSupportApi/Contract/basket-item-hist';
import { BasketSources as ClientSupportApiContractBasketSources } from '../models/ClientSupportApi/Contract/basket-sources';
import { ListBasketDataResponse as ClientSupportApiContractListBasketDataResponse } from '../models/ClientSupportApi/Contract/list-basket-data-response';

@Injectable({
  providedIn: 'root',
})
export class TicketsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportClientsIdBasketItemsGet
   */
  static readonly ApiSupportClientsIdBasketItemsGetPath = '/api/Support/clients/{id}/basketItems';

  /**
   * Getting the composition of the basket (tickets in the basket). The cost of fares (tickets) is taken as of the date of creation of the basket.
   *
   * ### Claim
   *  boservice.support.listbasketitem
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBasketItemsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketItemsGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractBasketItemHist>>> {

    const rb = new RequestBuilder(this.rootUrl, TicketsService.ApiSupportClientsIdBasketItemsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.query('bskId', params.bskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractBasketItemHist>>;
      })
    );
  }

  /**
   * Getting the composition of the basket (tickets in the basket). The cost of fares (tickets) is taken as of the date of creation of the basket.
   *
   * ### Claim
   *  boservice.support.listbasketitem
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBasketItemsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketItemsGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractBasketItemHist>> {

    return this.apiSupportClientsIdBasketItemsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractBasketItemHist>>) => r.body as Array<ClientSupportApiContractBasketItemHist>)
    );
  }

  /**
   * Getting the composition of the basket (tickets in the basket). The cost of fares (tickets) is taken as of the date of creation of the basket.
   *
   * ### Claim
   *  boservice.support.listbasketitem
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBasketItemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketItemsGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractBasketItemHist>>> {

    const rb = new RequestBuilder(this.rootUrl, TicketsService.ApiSupportClientsIdBasketItemsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.query('bskId', params.bskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractBasketItemHist>>;
      })
    );
  }

  /**
   * Getting the composition of the basket (tickets in the basket). The cost of fares (tickets) is taken as of the date of creation of the basket.
   *
   * ### Claim
   *  boservice.support.listbasketitem
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBasketItemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketItemsGet(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractBasketItemHist>> {

    return this.apiSupportClientsIdBasketItemsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractBasketItemHist>>) => r.body as Array<ClientSupportApiContractBasketItemHist>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdBasketSourcesGet
   */
  static readonly ApiSupportClientsIdBasketSourcesGetPath = '/api/Support/clients/{id}/basketSources';

  /**
   * Getting a list of cart payment sources.
   *
   * ### Claim
   *  boservice.support.listbasketsources
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBasketSourcesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketSourcesGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractBasketSources>>> {

    const rb = new RequestBuilder(this.rootUrl, TicketsService.ApiSupportClientsIdBasketSourcesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.query('bskId', params.bskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractBasketSources>>;
      })
    );
  }

  /**
   * Getting a list of cart payment sources.
   *
   * ### Claim
   *  boservice.support.listbasketsources
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBasketSourcesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketSourcesGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractBasketSources>> {

    return this.apiSupportClientsIdBasketSourcesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractBasketSources>>) => r.body as Array<ClientSupportApiContractBasketSources>)
    );
  }

  /**
   * Getting a list of cart payment sources.
   *
   * ### Claim
   *  boservice.support.listbasketsources
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBasketSourcesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketSourcesGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractBasketSources>>> {

    const rb = new RequestBuilder(this.rootUrl, TicketsService.ApiSupportClientsIdBasketSourcesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.query('bskId', params.bskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractBasketSources>>;
      })
    );
  }

  /**
   * Getting a list of cart payment sources.
   *
   * ### Claim
   *  boservice.support.listbasketsources
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBasketSourcesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketSourcesGet(params: {

    /**
     * Interface language
     */
    lang?: string;
    id: number;

    /**
     * cart id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractBasketSources>> {

    return this.apiSupportClientsIdBasketSourcesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractBasketSources>>) => r.body as Array<ClientSupportApiContractBasketSources>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdBasketGet
   */
  static readonly ApiSupportClientsIdBasketGetPath = '/api/Support/clients/{id}/basket';

  /**
   * Get cart data.
   *
   * ### Claim
   *  boservice.support.listbasketdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBasketGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketGet$Plain$Response(params: {

    /**
     * ui language code
     */
    lang?: string;
    id: number;

    /**
     * basket (cart) id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractListBasketDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TicketsService.ApiSupportClientsIdBasketGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.query('bskId', params.bskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractListBasketDataResponse>;
      })
    );
  }

  /**
   * Get cart data.
   *
   * ### Claim
   *  boservice.support.listbasketdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBasketGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketGet$Plain(params: {

    /**
     * ui language code
     */
    lang?: string;
    id: number;

    /**
     * basket (cart) id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractListBasketDataResponse> {

    return this.apiSupportClientsIdBasketGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractListBasketDataResponse>) => r.body as ClientSupportApiContractListBasketDataResponse)
    );
  }

  /**
   * Get cart data.
   *
   * ### Claim
   *  boservice.support.listbasketdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBasketGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketGet$Response(params: {

    /**
     * ui language code
     */
    lang?: string;
    id: number;

    /**
     * basket (cart) id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractListBasketDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TicketsService.ApiSupportClientsIdBasketGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.query('bskId', params.bskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractListBasketDataResponse>;
      })
    );
  }

  /**
   * Get cart data.
   *
   * ### Claim
   *  boservice.support.listbasketdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBasketGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBasketGet(params: {

    /**
     * ui language code
     */
    lang?: string;
    id: number;

    /**
     * basket (cart) id
     */
    bskId?: number;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractListBasketDataResponse> {

    return this.apiSupportClientsIdBasketGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractListBasketDataResponse>) => r.body as ClientSupportApiContractListBasketDataResponse)
    );
  }

}
