import { inject, Inject, Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { LOCAL_LOGIN_URL } from '@libs/constants';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { intersection } from 'lodash-es';
import { ModuleAccessService } from '@services/module-access/module-access.service';
import { RouteEntity } from '@core/router/route.entity';
import { UserState } from '@core/state/user.state';
import { filter } from 'rxjs/operators';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';


@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanLoad {
    readonly #userState: UserState = inject(UserState);

    public constructor(
        private route: Router,
        @Inject('window') private window: Window,
    ) {
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Promise<boolean> | boolean {
        return new Promise((resolve) => {
            this.#userState.user$.pipe(filter(Boolean)).subscribe(
                (user: LoginResponse) => {
                    if (user) {
                        if (user.newPwdRequired) {
                            this.changeTempPasswordRedirect();
                            return false;
                        }
                        // Get the list of modules
                        const modules: MenuItemsModel[] =
                            route.hasOwnProperty('data') &&
                            route.data.hasOwnProperty('moduleMenu')
                                ? route.data.moduleMenu
                                : [];

                        if (!modules || !modules.length) {
                            resolve(true);
                            return false;
                        }
                        // Looking for the target module
                        const targetModule = modules.find(
                            (module: MenuItemsModel) => module.url === '/' + segments[0].path,
                        );
                        const privsNameObjects = user.privs.map((priv) => priv.objName);
                        // If the target module exists
                        if (targetModule) {
                            if (ModuleAccessService.checkMenuItemAccess(targetModule, user.privs)) {
                                resolve(true);
                            } else {
                                const availableAnotherModule = modules.find((module) =>
                                    intersection(module.objName, privsNameObjects)?.length,
                                );

                                if (ModuleAccessService.checkMenuItemAccess(availableAnotherModule, user.privs)) {
                                    resolve(false);
                                    this.window.location.assign(availableAnotherModule.url);
                                } else {
                                    resolve(false);
                                    this.notAccessRedirect();
                                }
                            }
                        } else {
                            resolve(false);
                            this.notAccessRedirect();
                        }
                    } else {
                        resolve(false);
                        this.loginRedirect();
                    }
                },
                () => {
                    this.loginRedirect();
                    resolve(false);
                },
            );
        });
    }

    private loginRedirect(): void {
        setTimeout(() => this.window.location.assign(LOCAL_LOGIN_URL));
    }

    private changeTempPasswordRedirect(): void {
        setTimeout(() => this.route.navigate([RouteEntity.Root, RouteEntity.UserSettings]));
    }

    private notAccessRedirect(): void {
        setTimeout(() => this.route.navigate([RouteEntity.Root, RouteEntity.Error, RouteEntity.Error403]));
    }
}
