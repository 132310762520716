/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AfDetectedListPgResponse as AntifrodApiContractAfDetectedListPgResponse } from '../models/AntifrodApi/Contract/af-detected-list-pg-response';
import { AfDetectedListResponse as AntifrodApiContractAfDetectedListResponse } from '../models/AntifrodApi/Contract/af-detected-list-response';
import { AfDetectedPassListResponse as AntifrodApiContractAfDetectedPassListResponse } from '../models/AntifrodApi/Contract/af-detected-pass-list-response';
import { AfDetectedProcRequest as AntifrodApiContractAfDetectedProcRequest } from '../models/AntifrodApi/Contract/af-detected-proc-request';
import { AfMediumListResponse as AntifrodApiContractAfMediumListResponse } from '../models/AntifrodApi/Contract/af-medium-list-response';
import { AfMediumSetRequest as AntifrodApiContractAfMediumSetRequest } from '../models/AntifrodApi/Contract/af-medium-set-request';
import { AfPassErrorListResponse as AntifrodApiContractAfPassErrorListResponse } from '../models/AntifrodApi/Contract/af-pass-error-list-response';
import { AfReasonListResponse as AntifrodApiContractAfReasonListResponse } from '../models/AntifrodApi/Contract/af-reason-list-response';
import { AfReasonSetRequest as AntifrodApiContractAfReasonSetRequest } from '../models/AntifrodApi/Contract/af-reason-set-request';
import { ListIdtypeResponse as AntifrodApiContractListIdtypeResponse } from '../models/AntifrodApi/Contract/list-idtype-response';

@Injectable({
  providedIn: 'root',
})
export class AntifrodService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAntifrodAfReasonListGet
   */
  static readonly ApiAntifrodAfReasonListGetPath = '/api/Antifrod/AfReasonList';

  /**
   * Getting a list of suspicious criteria.
   *
   * ### Claim
   *  boservice.antifrod.afreasonlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfReasonListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfReasonListGet$Response(params?: {

    /**
     * * language interface language interface. If not specified, the language system is the default
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AntifrodApiContractAfReasonListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfReasonListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AntifrodApiContractAfReasonListResponse>>;
      })
    );
  }

  /**
   * Getting a list of suspicious criteria.
   *
   * ### Claim
   *  boservice.antifrod.afreasonlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfReasonListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfReasonListGet(params?: {

    /**
     * * language interface language interface. If not specified, the language system is the default
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AntifrodApiContractAfReasonListResponse>> {

    return this.apiAntifrodAfReasonListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AntifrodApiContractAfReasonListResponse>>) => r.body as Array<AntifrodApiContractAfReasonListResponse>)
    );
  }

  /**
   * Path part for operation apiAntifrodAfReasonSetPost
   */
  static readonly ApiAntifrodAfReasonSetPostPath = '/api/Antifrod/AfReasonSet';

  /**
   * Changing the settings for the suspicious criterion.
   *
   * ### Claim
   *  boservice.antifrod.afreasonset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfReasonSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAntifrodAfReasonSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AntifrodApiContractAfReasonSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfReasonSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing the settings for the suspicious criterion.
   *
   * ### Claim
   *  boservice.antifrod.afreasonset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfReasonSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAntifrodAfReasonSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AntifrodApiContractAfReasonSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAntifrodAfReasonSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAntifrodAfDetectedListGet
   */
  static readonly ApiAntifrodAfDetectedListGetPath = '/api/Antifrod/AfDetectedList';

  /**
   * Getting a list of suspicious identifiers.
   *
   * ### Claim
   *  boservice.antifrod.afdetectedlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfDetectedListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfDetectedListGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * date of detection initial (inclusive)
     */
    dateFrom?: string;

    /**
     * detection time initial (in seconds) if null then 00:00
     */
    timeFrom?: number;

    /**
     * * date of discovery final (inclusive)
     */
    dateTo?: string;

    /**
     * detection time end (in seconds) if null then equals 24:00 (until the end of i_date_to)
     */
    timeTo?: number;

    /**
     * suspicious criterion id/null
     */
    rsnCode?: number;

    /**
     * sign of processing: null - all 0 - unprocessed 1 - processed
     */
    procFlag?: number;

    /**
     * media type id/null
     */
    mediumType?: number;

    /**
     * displayed media number/null
     */
    mediumN?: string;

    /**
     * ticket series
     */
    ticketS?: string;

    /**
     * ticket number
     */
    ticketN?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AntifrodApiContractAfDetectedListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfDetectedListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('timeFrom', params.timeFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('timeTo', params.timeTo, {});
      rb.query('rsnCode', params.rsnCode, {});
      rb.query('procFlag', params.procFlag, {});
      rb.query('mediumType', params.mediumType, {});
      rb.query('mediumN', params.mediumN, {});
      rb.query('ticketS', params.ticketS, {});
      rb.query('ticketN', params.ticketN, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AntifrodApiContractAfDetectedListResponse>>;
      })
    );
  }

  /**
   * Getting a list of suspicious identifiers.
   *
   * ### Claim
   *  boservice.antifrod.afdetectedlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfDetectedListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfDetectedListGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * date of detection initial (inclusive)
     */
    dateFrom?: string;

    /**
     * detection time initial (in seconds) if null then 00:00
     */
    timeFrom?: number;

    /**
     * * date of discovery final (inclusive)
     */
    dateTo?: string;

    /**
     * detection time end (in seconds) if null then equals 24:00 (until the end of i_date_to)
     */
    timeTo?: number;

    /**
     * suspicious criterion id/null
     */
    rsnCode?: number;

    /**
     * sign of processing: null - all 0 - unprocessed 1 - processed
     */
    procFlag?: number;

    /**
     * media type id/null
     */
    mediumType?: number;

    /**
     * displayed media number/null
     */
    mediumN?: string;

    /**
     * ticket series
     */
    ticketS?: string;

    /**
     * ticket number
     */
    ticketN?: string;
  },
  context?: HttpContext

): Observable<Array<AntifrodApiContractAfDetectedListResponse>> {

    return this.apiAntifrodAfDetectedListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AntifrodApiContractAfDetectedListResponse>>) => r.body as Array<AntifrodApiContractAfDetectedListResponse>)
    );
  }

  /**
   * Path part for operation apiAntifrodAfDetectedListPgGet
   */
  static readonly ApiAntifrodAfDetectedListPgGetPath = '/api/Antifrod/AfDetectedListPg';

  /**
   * List of suspicious IDs detected with pagination and sort options.
   *
   * ### Claim
   *  boservice.antifrod.afdetectedlistpg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfDetectedListPgGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfDetectedListPgGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * detection date from (including)
     */
    dateFrom?: string;

    /**
     * detection time from (including) null is treated as 00:00
     */
    timeFrom?: number;

    /**
     * * detection date to (including)
     */
    dateTo?: string;

    /**
     * detection time to (including) null is treated as 24:00 (to the end of i_date_to)
     */
    timeTo?: number;

    /**
     * suspect reason id/null
     */
    rsnCode?: number;

    /**
     * process sign: null � all 0 � unprocessed 1 � processed
     */
    procFlag?: number;

    /**
     * media type/null
     */
    mediumType?: number;

    /**
     * media number/null
     */
    mediumN?: string;

    /**
     * ticket series/null
     */
    ticketS?: string;

    /**
     * ticket number/null
     */
    ticketN?: string;

    /**
     * maximum number of records to return >=0. if == null then number is unlimited. if == 0 then no records returned but o_rec_cnt contains total number of records.
     */
    recLimit?: number;

    /**
     * first record number to return >= 1. null treated as 1. ignored if i_rec_limit == 0.
     */
    recFirst?: number;

    /**
     * records sort order. if null then records return in function's default order. one of the values accepted: � medium_type_name � media type name (id type) � medium_n - media number (id) � ticket - fare product (ticket_n then ticket_s) � rsn_name � suspect reason name (reason) � trn_time_first � first detection date-time (first) � trn_time_last � last detection date-time (last) � detect_cnt � number of detections (counter) � proc_flag - process sign (status)
     */
    sortOption?: string;

    /**
     * records sort order. if null then records return in function's default order. 0 � ascending 1 - descending
     */
    sortOrder?: number;

    /**
     * update number of records
     */
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AntifrodApiContractAfDetectedListPgResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfDetectedListPgGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('timeFrom', params.timeFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('timeTo', params.timeTo, {});
      rb.query('rsnCode', params.rsnCode, {});
      rb.query('procFlag', params.procFlag, {});
      rb.query('mediumType', params.mediumType, {});
      rb.query('mediumN', params.mediumN, {});
      rb.query('ticketS', params.ticketS, {});
      rb.query('ticketN', params.ticketN, {});
      rb.query('recLimit', params.recLimit, {});
      rb.query('recFirst', params.recFirst, {});
      rb.query('sortOption', params.sortOption, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AntifrodApiContractAfDetectedListPgResponse>;
      })
    );
  }

  /**
   * List of suspicious IDs detected with pagination and sort options.
   *
   * ### Claim
   *  boservice.antifrod.afdetectedlistpg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfDetectedListPgGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfDetectedListPgGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * detection date from (including)
     */
    dateFrom?: string;

    /**
     * detection time from (including) null is treated as 00:00
     */
    timeFrom?: number;

    /**
     * * detection date to (including)
     */
    dateTo?: string;

    /**
     * detection time to (including) null is treated as 24:00 (to the end of i_date_to)
     */
    timeTo?: number;

    /**
     * suspect reason id/null
     */
    rsnCode?: number;

    /**
     * process sign: null � all 0 � unprocessed 1 � processed
     */
    procFlag?: number;

    /**
     * media type/null
     */
    mediumType?: number;

    /**
     * media number/null
     */
    mediumN?: string;

    /**
     * ticket series/null
     */
    ticketS?: string;

    /**
     * ticket number/null
     */
    ticketN?: string;

    /**
     * maximum number of records to return >=0. if == null then number is unlimited. if == 0 then no records returned but o_rec_cnt contains total number of records.
     */
    recLimit?: number;

    /**
     * first record number to return >= 1. null treated as 1. ignored if i_rec_limit == 0.
     */
    recFirst?: number;

    /**
     * records sort order. if null then records return in function's default order. one of the values accepted: � medium_type_name � media type name (id type) � medium_n - media number (id) � ticket - fare product (ticket_n then ticket_s) � rsn_name � suspect reason name (reason) � trn_time_first � first detection date-time (first) � trn_time_last � last detection date-time (last) � detect_cnt � number of detections (counter) � proc_flag - process sign (status)
     */
    sortOption?: string;

    /**
     * records sort order. if null then records return in function's default order. 0 � ascending 1 - descending
     */
    sortOrder?: number;

    /**
     * update number of records
     */
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<AntifrodApiContractAfDetectedListPgResponse> {

    return this.apiAntifrodAfDetectedListPgGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AntifrodApiContractAfDetectedListPgResponse>) => r.body as AntifrodApiContractAfDetectedListPgResponse)
    );
  }

  /**
   * Path part for operation apiAntifrodAfDetectedPassListGet
   */
  static readonly ApiAntifrodAfDetectedPassListGetPath = '/api/Antifrod/AfDetectedPassList';

  /**
   * Getting a list of passes by suspicious identifiers.
   *
   * ### Claim
   *  boservice.antifrod.afdetectedpasslist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfDetectedPassListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfDetectedPassListGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * id of the suspicious media
     */
    afdId?: number;

    /**
     * * start date of passage (inclusive)
     */
    dateFrom?: string;

    /**
     * pass time initial if null then equal to 00:00
     */
    timeFrom?: number;

    /**
     * * end date of passage (inclusive)
     */
    dateTo?: string;

    /**
     * transit time end if null then equal to 24:00 (until the end of i_date_to)
     */
    timeTo?: number;

    /**
     * return passes: 0 - suspicious 1 - all
     */
    allPassFlag?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AntifrodApiContractAfDetectedPassListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfDetectedPassListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('afdId', params.afdId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('timeFrom', params.timeFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('timeTo', params.timeTo, {});
      rb.query('allPassFlag', params.allPassFlag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AntifrodApiContractAfDetectedPassListResponse>>;
      })
    );
  }

  /**
   * Getting a list of passes by suspicious identifiers.
   *
   * ### Claim
   *  boservice.antifrod.afdetectedpasslist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfDetectedPassListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfDetectedPassListGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * id of the suspicious media
     */
    afdId?: number;

    /**
     * * start date of passage (inclusive)
     */
    dateFrom?: string;

    /**
     * pass time initial if null then equal to 00:00
     */
    timeFrom?: number;

    /**
     * * end date of passage (inclusive)
     */
    dateTo?: string;

    /**
     * transit time end if null then equal to 24:00 (until the end of i_date_to)
     */
    timeTo?: number;

    /**
     * return passes: 0 - suspicious 1 - all
     */
    allPassFlag?: number;
  },
  context?: HttpContext

): Observable<Array<AntifrodApiContractAfDetectedPassListResponse>> {

    return this.apiAntifrodAfDetectedPassListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AntifrodApiContractAfDetectedPassListResponse>>) => r.body as Array<AntifrodApiContractAfDetectedPassListResponse>)
    );
  }

  /**
   * Path part for operation apiAntifrodAfDetectedProcPost
   */
  static readonly ApiAntifrodAfDetectedProcPostPath = '/api/Antifrod/AfDetectedProc';

  /**
   * Handling Suspicious Media.
   *
   * If any entry from the array has already been processed, then it is ignored
   * ### Claim
   *  boservice.antifrod.afdetectedproc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfDetectedProcPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAntifrodAfDetectedProcPost$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: AntifrodApiContractAfDetectedProcRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfDetectedProcPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Handling Suspicious Media.
   *
   * If any entry from the array has already been processed, then it is ignored
   * ### Claim
   *  boservice.antifrod.afdetectedproc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfDetectedProcPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAntifrodAfDetectedProcPost(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: AntifrodApiContractAfDetectedProcRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAntifrodAfDetectedProcPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAntifrodAfMediumListGet
   */
  static readonly ApiAntifrodAfMediumListGetPath = '/api/Antifrod/AfMediumList';

  /**
   * Getting a list of blocked/ignored identifiers.
   *
   * ### Claim
   *  boservice.antifrod.afmediumlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfMediumListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfMediumListGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * carrier status: null - all 1 - blocked 2 - ignored
     */
    afStatus?: number;

    /**
     * date of status setting initial (inclusive)
     */
    dateFrom?: string;

    /**
     * date of status setting final (inclusive)
     */
    dateTo?: string;

    /**
     * media type id/null
     */
    mediumType?: number;

    /**
     * displayed media number/null
     */
    mediumN?: string;

    /**
     * sign of automatic blocking: null - all 0 - no 1 - yes
     */
    procAuto?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AntifrodApiContractAfMediumListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfMediumListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('afStatus', params.afStatus, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('mediumType', params.mediumType, {});
      rb.query('mediumN', params.mediumN, {});
      rb.query('procAuto', params.procAuto, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AntifrodApiContractAfMediumListResponse>>;
      })
    );
  }

  /**
   * Getting a list of blocked/ignored identifiers.
   *
   * ### Claim
   *  boservice.antifrod.afmediumlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfMediumListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfMediumListGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * carrier status: null - all 1 - blocked 2 - ignored
     */
    afStatus?: number;

    /**
     * date of status setting initial (inclusive)
     */
    dateFrom?: string;

    /**
     * date of status setting final (inclusive)
     */
    dateTo?: string;

    /**
     * media type id/null
     */
    mediumType?: number;

    /**
     * displayed media number/null
     */
    mediumN?: string;

    /**
     * sign of automatic blocking: null - all 0 - no 1 - yes
     */
    procAuto?: number;
  },
  context?: HttpContext

): Observable<Array<AntifrodApiContractAfMediumListResponse>> {

    return this.apiAntifrodAfMediumListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AntifrodApiContractAfMediumListResponse>>) => r.body as Array<AntifrodApiContractAfMediumListResponse>)
    );
  }

  /**
   * Path part for operation apiAntifrodAfMediumSetPost
   */
  static readonly ApiAntifrodAfMediumSetPostPath = '/api/Antifrod/AfMediumSet';

  /**
   * Changing Media Status (Lock/Unlock).
   *
   * ### Claim
   *  boservice.antifrod.afmediumset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfMediumSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAntifrodAfMediumSetPost$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: AntifrodApiContractAfMediumSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfMediumSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing Media Status (Lock/Unlock).
   *
   * ### Claim
   *  boservice.antifrod.afmediumset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfMediumSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAntifrodAfMediumSetPost(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: AntifrodApiContractAfMediumSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAntifrodAfMediumSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAntifrodAfPassErrorListGet
   */
  static readonly ApiAntifrodAfPassErrorListGetPath = '/api/Antifrod/AfPassErrorList';

  /**
   * Getting a List of Declined Transactions.
   *
   * ### Claim
   *  boservice.antifrod.afpasserrorlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodAfPassErrorListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfPassErrorListGet$Response(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * pass date initial (inclusive) null
     */
    dateFrom?: string;

    /**
     * end date of passage (inclusive) null
     */
    dateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AntifrodApiContractAfPassErrorListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodAfPassErrorListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AntifrodApiContractAfPassErrorListResponse>>;
      })
    );
  }

  /**
   * Getting a List of Declined Transactions.
   *
   * ### Claim
   *  boservice.antifrod.afpasserrorlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodAfPassErrorListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodAfPassErrorListGet(params?: {

    /**
     * * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * pass date initial (inclusive) null
     */
    dateFrom?: string;

    /**
     * end date of passage (inclusive) null
     */
    dateTo?: string;
  },
  context?: HttpContext

): Observable<Array<AntifrodApiContractAfPassErrorListResponse>> {

    return this.apiAntifrodAfPassErrorListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AntifrodApiContractAfPassErrorListResponse>>) => r.body as Array<AntifrodApiContractAfPassErrorListResponse>)
    );
  }

  /**
   * Path part for operation apiAntifrodListIdtypeGet
   */
  static readonly ApiAntifrodListIdtypeGetPath = '/api/Antifrod/ListIdtype';

  /**
   * Getting a list of identifier types (actual).
   *
   * ### Claim
   *  boservice.antifrod.listidtype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAntifrodListIdtypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodListIdtypeGet$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AntifrodApiContractListIdtypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AntifrodService.ApiAntifrodListIdtypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AntifrodApiContractListIdtypeResponse>>;
      })
    );
  }

  /**
   * Getting a list of identifier types (actual).
   *
   * ### Claim
   *  boservice.antifrod.listidtype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAntifrodListIdtypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAntifrodListIdtypeGet(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AntifrodApiContractListIdtypeResponse>> {

    return this.apiAntifrodListIdtypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AntifrodApiContractListIdtypeResponse>>) => r.body as Array<AntifrodApiContractListIdtypeResponse>)
    );
  }

}
