import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteEntity } from '@core/router/route.entity';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';


/**
 * User card context menu
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-user-card-menu',
    templateUrl: './user-card-menu.component.html',
    styleUrls: ['./user-card-menu.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        TranslateModule,
    ],
})
export class UserCardMenuComponent {
    /**
     * System user data object
     */
    @Input() public user: LoginResponse;

    public readonly routeEntity: typeof RouteEntity = RouteEntity;
}
