import { ErrorResponse } from '@core/api/models/error-response.model';
import { StartWithUnit } from '@core/operators/start-with-unit';
import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { StorageKeys } from '@core/storage/storage.keys';
import { LocalStorage } from '@core/storage/local-storage';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { filter, map, tap } from 'rxjs/operators';
import { LoginService } from '@api/services/login.service';
import { LoginRequestEx } from '@api/models/BoService/Models/login-request-ex';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';
import { Router } from '@angular/router';
import { RouteEntity } from '@core/router/route.entity';
import { startWithLoading } from '@core/operators/start-with-loading';
import { startWithErrors } from '@core/operators/start-with-errors';
import { UsersService } from '@api/services/users.service';
import { SetNewPasswordRequest } from '@api/models/UserService/Contract/set-new-password-request';
import { PrivilegesUsers } from '@core/privileges/privileges-users';
import { Priv } from '@api/models/UserService/Contract/priv';


export interface IUserState {
    loading: Record<StartWithUnit, boolean>;
    errors: Record<StartWithUnit, ErrorResponse>;
    isAuthorized: boolean;
    user: LoginResponse;
}

export interface IUserAction {
    setIsAuthorizesFlag: boolean;
    requestMe: LoginResponse;
}

@Injectable({
    providedIn: 'root',
})
export class UserState extends RxState<IUserState> {
    @LocalStorage(StorageKeys.Access)
    protected token: string;

    readonly #actions: RxActions<IUserAction> = new RxActionFactory<IUserAction>().create();
    readonly #loginService: LoginService = inject(LoginService);
    readonly #usersService: UsersService = inject(UsersService);
    readonly #router: Router = inject(Router);

    public readonly isAuthorized$: Observable<boolean> = this.select('isAuthorized');
    public readonly user$: Observable<LoginResponse> = this.select('user');
    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly errors$: Observable<Record<StartWithUnit, ErrorResponse>> = this.select('errors');

    constructor() {
        super();

        this.connectSelectors();
        this.setDefaultState();
    }

    public signIn(body: LoginRequestEx): void {
        this.hold(
            this.#loginService.apiLoginCheckLoginPost({ body }).pipe(
                startWithLoading(this, StartWithUnit.SignIn),
                startWithErrors(this, StartWithUnit.SignIn),
            ),
            (loginData: LoginResponse) => {
                this.token = String(loginData.usrId);
                this.requestMe();
                this.redirectToMainPage();
            },
        );
    }

    public requestMe(): void {
        this.hold(
            this.#loginService.apiLoginGetUserDataGet(),
            this.#actions.requestMe,
        );
    }

    public obsoleteToken(): void {
        this.token = null;
        this.#actions.setIsAuthorizesFlag(false);
    }

    public logOut(): Observable<void> {
        return this.#loginService.apiLoginLogoutGet().pipe(
            tap(() => {
                this.token = null;
                this.#actions.requestMe(null);
                this.#actions.setIsAuthorizesFlag(false);
            }),
        );
    }

    /**
     * Change the password of the current user
     */
    public changePassword(body: SetNewPasswordRequest): Observable<void> {
        return this.#usersService.apiUsersSetNewPasswdPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.ChangeMyPassword),
        );
    }

    /**
     * Check user rights for a privilege in a module
     *
     * @param privilege - privilege
     * @param module - module
     *@private
     */
    public checkPrivilege(
        privilege: PrivilegesUsers,
        module: string,
    ): Observable<boolean> {
        return this.user$.pipe(
            map((user: LoginResponse) => {
                if (user) {
                    return (
                        user.privs
                            .filter((priv: Priv) => priv.objName === module)
                            .map((item: Priv) => item.privName)
                            .indexOf(privilege) > -1
                    );
                } else {
                    return false;
                }
            }),
        );
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            errors: null,
            isAuthorized: false,
            user: null,
        });
    }

    private connectSelectors(): void {
        this.connect('user', this.#actions.requestMe$.pipe(
                filter(Boolean),
                tap((loginData: LoginResponse) => {
                        this.token = String(loginData.usrId);
                        this.#actions.setIsAuthorizesFlag(true);
                    },
                ),
            ),
        );

        this.connect('isAuthorized', this.#actions.setIsAuthorizesFlag$.pipe(
            tap((isAuthorized: boolean) => {
                if (!isAuthorized) {
                    this.redirectToSinIn();
                }
            }),
        ));
    }

    public redirectToMainPage(): void {
        this.#router.navigate([RouteEntity.Root]);
    }

    private redirectToSinIn(): void {
        this.#router.navigate([RouteEntity.Root, RouteEntity.Login]);
    }
}
