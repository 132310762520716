/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddFarePassRequest as TariffEditorApiContractAddFarePassRequest } from '../models/TariffEditorApi/Contract/add-fare-pass-request';
import { AddFarePassResponse as TariffEditorApiContractAddFarePassResponse } from '../models/TariffEditorApi/Contract/add-fare-pass-response';
import { AddFareTicketRequest as TariffEditorApiContractAddFareTicketRequest } from '../models/TariffEditorApi/Contract/add-fare-ticket-request';
import { AddFareTicketResponse as TariffEditorApiContractAddFareTicketResponse } from '../models/TariffEditorApi/Contract/add-fare-ticket-response';
import { CopyFareTicket as TariffEditorApiContractCopyFareTicket } from '../models/TariffEditorApi/Contract/copy-fare-ticket';
import { CopyFareTicketRequest as TariffEditorApiContractCopyFareTicketRequest } from '../models/TariffEditorApi/Contract/copy-fare-ticket-request';
import { Domain as TariffEditorApiContractDomain } from '../models/TariffEditorApi/Contract/domain';
import { DomainListResponse as TariffEditorApiContractDomainListResponse } from '../models/TariffEditorApi/Contract/domain-list-response';
import { DomainThreshold as TariffEditorApiContractDomainThreshold } from '../models/TariffEditorApi/Contract/domain-threshold';
import { FarePassData as TariffEditorApiContractFarePassData } from '../models/TariffEditorApi/Contract/fare-pass-data';
import { FarePassDataRequest as TariffEditorApiContractFarePassDataRequest } from '../models/TariffEditorApi/Contract/fare-pass-data-request';
import { FareTicketData as TariffEditorApiContractFareTicketData } from '../models/TariffEditorApi/Contract/fare-ticket-data';
import { FareTicketDataRequest as TariffEditorApiContractFareTicketDataRequest } from '../models/TariffEditorApi/Contract/fare-ticket-data-request';
import { GetDbtimeResponse as TariffEditorApiContractGetDbtimeResponse } from '../models/TariffEditorApi/Contract/get-dbtime-response';
import { GetDisplayTariffGroupDataResponse as TariffEditorApiContractGetDisplayTariffGroupDataResponse } from '../models/TariffEditorApi/Contract/get-display-tariff-group-data-response';
import { GetTariffGroupRepDataResponse as TariffEditorApiContractGetTariffGroupRepDataResponse } from '../models/TariffEditorApi/Contract/get-tariff-group-rep-data-response';
import { ListFareLimitResponse as TariffEditorApiContractListFareLimitResponse } from '../models/TariffEditorApi/Contract/list-fare-limit-response';
import { ListFareMenuResponse as TariffEditorApiContractListFareMenuResponse } from '../models/TariffEditorApi/Contract/list-fare-menu-response';
import { ListFarePriceTypeResponse as TariffEditorApiContractListFarePriceTypeResponse } from '../models/TariffEditorApi/Contract/list-fare-price-type-response';
import { ListFarePrvPartResponse as TariffEditorApiContractListFarePrvPartResponse } from '../models/TariffEditorApi/Contract/list-fare-prv-part-response';
import { ListPriceFsResponse as TariffEditorApiContractListPriceFsResponse } from '../models/TariffEditorApi/Contract/list-price-fs-response';
import { ListPriceZoneRequest as TariffEditorApiContractListPriceZoneRequest } from '../models/TariffEditorApi/Contract/list-price-zone-request';
import { ListPrivCategResponse as TariffEditorApiContractListPrivCategResponse } from '../models/TariffEditorApi/Contract/list-priv-categ-response';
import { ListTariffZoneResponse as TariffEditorApiContractListTariffZoneResponse } from '../models/TariffEditorApi/Contract/list-tariff-zone-response';
import { ListTicketPriceZoneRequest as TariffEditorApiContractListTicketPriceZoneRequest } from '../models/TariffEditorApi/Contract/list-ticket-price-zone-request';
import { PeriodExclude as TariffEditorApiContractPeriodExclude } from '../models/TariffEditorApi/Contract/period-exclude';
import { PeriodInclude as TariffEditorApiContractPeriodInclude } from '../models/TariffEditorApi/Contract/period-include';
import { PriceZone as TariffEditorApiContractPriceZone } from '../models/TariffEditorApi/Contract/price-zone';
import { PriceZoneARequest as TariffEditorApiContractPriceZoneARequest } from '../models/TariffEditorApi/Contract/price-zone-a-request';
import { PrivGroup as TariffEditorApiContractPrivGroup } from '../models/TariffEditorApi/Contract/priv-group';
import { RouteGroup as TariffEditorApiContractRouteGroup } from '../models/TariffEditorApi/Contract/route-group';
import { Service as TariffEditorApiContractService } from '../models/TariffEditorApi/Contract/service';
import { SetFareLimitRequest as TariffEditorApiContractSetFareLimitRequest } from '../models/TariffEditorApi/Contract/set-fare-limit-request';
import { SetFarePassRequest as TariffEditorApiContractSetFarePassRequest } from '../models/TariffEditorApi/Contract/set-fare-pass-request';
import { SetFarePrvPartRequest as TariffEditorApiContractSetFarePrvPartRequest } from '../models/TariffEditorApi/Contract/set-fare-prv-part-request';
import { SetFareSortRequest as TariffEditorApiContractSetFareSortRequest } from '../models/TariffEditorApi/Contract/set-fare-sort-request';
import { SetFareTicketParamsRequest as TariffEditorApiContractSetFareTicketParamsRequest } from '../models/TariffEditorApi/Contract/set-fare-ticket-params-request';
import { SetFareTicketRequest as TariffEditorApiContractSetFareTicketRequest } from '../models/TariffEditorApi/Contract/set-fare-ticket-request';
import { SetPriceFsRequest as TariffEditorApiContractSetPriceFsRequest } from '../models/TariffEditorApi/Contract/set-price-fs-request';
import { TariffCap as TariffEditorApiContractTariffCap } from '../models/TariffEditorApi/Contract/tariff-cap';
import { TariffCapBody as TariffEditorApiContractTariffCapBody } from '../models/TariffEditorApi/Contract/tariff-cap-body';
import { TariffCondition as TariffEditorApiContractTariffCondition } from '../models/TariffEditorApi/Contract/tariff-condition';
import { TariffDomain as TariffEditorApiContractTariffDomain } from '../models/TariffEditorApi/Contract/tariff-domain';
import { TariffDomainBody as TariffEditorApiContractTariffDomainBody } from '../models/TariffEditorApi/Contract/tariff-domain-body';
import { TariffGroup as TariffEditorApiContractTariffGroup } from '../models/TariffEditorApi/Contract/tariff-group';
import { TariffGroupRep as TariffEditorApiContractTariffGroupRep } from '../models/TariffEditorApi/Contract/tariff-group-rep';
import { TariffPass as TariffEditorApiContractTariffPass } from '../models/TariffEditorApi/Contract/tariff-pass';
import { TariffPassBody as TariffEditorApiContractTariffPassBody } from '../models/TariffEditorApi/Contract/tariff-pass-body';
import { TariffTicket as TariffEditorApiContractTariffTicket } from '../models/TariffEditorApi/Contract/tariff-ticket';
import { TariffVersion as TariffEditorApiContractTariffVersion } from '../models/TariffEditorApi/Contract/tariff-version';
import { TariffVersionBody as TariffEditorApiContractTariffVersionBody } from '../models/TariffEditorApi/Contract/tariff-version-body';
import { TicketPeriod as TariffEditorApiContractTicketPeriod } from '../models/TariffEditorApi/Contract/ticket-period';
import { TicketPriceZone as TariffEditorApiContractTicketPriceZone } from '../models/TariffEditorApi/Contract/ticket-price-zone';
import { Zones as TariffEditorApiContractZones } from '../models/TariffEditorApi/Contract/zones';

@Injectable({
  providedIn: 'root',
})
export class TariffService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffVersionsGet
   */
  static readonly ApiTariffVersionsGetPath = '/api/Tariff/versions';

  /**
   * Get all tariff versions.
   *
   * ### Claim
   *  boservice.tariff.gettariffsversions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffVersionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffVersionsGet$Plain$Response(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffVersion>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffVersionsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffVersion>>;
      })
    );
  }

  /**
   * Get all tariff versions.
   *
   * ### Claim
   *  boservice.tariff.gettariffsversions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffVersionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffVersionsGet$Plain(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffVersion>> {

    return this.apiTariffVersionsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffVersion>>) => r.body as Array<TariffEditorApiContractTariffVersion>)
    );
  }

  /**
   * Get all tariff versions.
   *
   * ### Claim
   *  boservice.tariff.gettariffsversions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffVersionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffVersionsGet$Response(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffVersion>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffVersionsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffVersion>>;
      })
    );
  }

  /**
   * Get all tariff versions.
   *
   * ### Claim
   *  boservice.tariff.gettariffsversions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffVersionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffVersionsGet(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffVersion>> {

    return this.apiTariffVersionsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffVersion>>) => r.body as Array<TariffEditorApiContractTariffVersion>)
    );
  }

  /**
   * Path part for operation apiTariffVersionsPost
   */
  static readonly ApiTariffVersionsPostPath = '/api/Tariff/versions';

  /**
   * Add new tariff version with comment.
   *
   * ### Claim
   *  boservice.tariff.addtariffversion
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffVersionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffVersionsPost$Plain$Response(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * Tariff version body
     */
    body?: TariffEditorApiContractTariffVersionBody
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractTariffVersion>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffVersionsPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractTariffVersion>;
      })
    );
  }

  /**
   * Add new tariff version with comment.
   *
   * ### Claim
   *  boservice.tariff.addtariffversion
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffVersionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffVersionsPost$Plain(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * Tariff version body
     */
    body?: TariffEditorApiContractTariffVersionBody
  },
  context?: HttpContext

): Observable<TariffEditorApiContractTariffVersion> {

    return this.apiTariffVersionsPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractTariffVersion>) => r.body as TariffEditorApiContractTariffVersion)
    );
  }

  /**
   * Add new tariff version with comment.
   *
   * ### Claim
   *  boservice.tariff.addtariffversion
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffVersionsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffVersionsPost$Response(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * Tariff version body
     */
    body?: TariffEditorApiContractTariffVersionBody
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractTariffVersion>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffVersionsPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractTariffVersion>;
      })
    );
  }

  /**
   * Add new tariff version with comment.
   *
   * ### Claim
   *  boservice.tariff.addtariffversion
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffVersionsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffVersionsPost(params?: {
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * Tariff version body
     */
    body?: TariffEditorApiContractTariffVersionBody
  },
  context?: HttpContext

): Observable<TariffEditorApiContractTariffVersion> {

    return this.apiTariffVersionsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractTariffVersion>) => r.body as TariffEditorApiContractTariffVersion)
    );
  }

  /**
   * Path part for operation apiTariffVersionsDeleteGet
   */
  static readonly ApiTariffVersionsDeleteGetPath = '/api/Tariff/versions/delete';

  /**
   * Delete tariff version.
   *
   * ### Claim
   *  boservice.tariff.deletetariffversion
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffVersionsDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffVersionsDeleteGet$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffVersionsDeleteGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete tariff version.
   *
   * ### Claim
   *  boservice.tariff.deletetariffversion
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffVersionsDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffVersionsDeleteGet(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffVersionsDeleteGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffTariffsPassGet
   */
  static readonly ApiTariffTariffsPassGetPath = '/api/Tariff/tariffs/pass';

  /**
   * Get all tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.gettariffspass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsPassGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsPassGet$Plain$Response(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsPassGetPath, 'get');
    if (params) {
      rb.query('serviceId', params.serviceId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.gettariffspass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsPassGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsPassGet$Plain(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffPass>> {

    return this.apiTariffTariffsPassGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>) => r.body as Array<TariffEditorApiContractTariffPass>)
    );
  }

  /**
   * Get all tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.gettariffspass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsPassGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsPassGet$Response(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsPassGetPath, 'get');
    if (params) {
      rb.query('serviceId', params.serviceId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.gettariffspass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsPassGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsPassGet(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffPass>> {

    return this.apiTariffTariffsPassGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>) => r.body as Array<TariffEditorApiContractTariffPass>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsPassPut
   */
  static readonly ApiTariffTariffsPassPutPath = '/api/Tariff/tariffs/pass';

  /**
   * Create or update list of tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.puttariffspass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsPassPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsPassPut$Plain$Response(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsPassPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>;
      })
    );
  }

  /**
   * Create or update list of tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.puttariffspass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsPassPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsPassPut$Plain(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffPass>> {

    return this.apiTariffTariffsPassPut$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>) => r.body as Array<TariffEditorApiContractTariffPass>)
    );
  }

  /**
   * Create or update list of tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.puttariffspass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsPassPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsPassPut$Response(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsPassPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>;
      })
    );
  }

  /**
   * Create or update list of tariffs of type "Pass".
   *
   * ### Claim
   *  boservice.tariff.puttariffspass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsPassPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsPassPut(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffPass>> {

    return this.apiTariffTariffsPassPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffPass>>) => r.body as Array<TariffEditorApiContractTariffPass>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsPassDisableGet
   */
  static readonly ApiTariffTariffsPassDisableGetPath = '/api/Tariff/tariffs/pass/disable';

  /**
   * Disable list of pass tariffs.
   *
   * ### Claim
   *  boservice.tariff.disabletariffspass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsPassDisableGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsPassDisableGet$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Array of tariff ids
     */
    tariffIds?: Array<number>;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsPassDisableGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('tariffIds', params.tariffIds, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable list of pass tariffs.
   *
   * ### Claim
   *  boservice.tariff.disabletariffspass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsPassDisableGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsPassDisableGet(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Array of tariff ids
     */
    tariffIds?: Array<number>;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffTariffsPassDisableGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffTariffsTicketGet
   */
  static readonly ApiTariffTariffsTicketGetPath = '/api/Tariff/tariffs/ticket';

  /**
   * Get all tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.gettariffsticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsTicketGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsTicketGet$Plain$Response(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsTicketGetPath, 'get');
    if (params) {
      rb.query('serviceId', params.serviceId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.gettariffsticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsTicketGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsTicketGet$Plain(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffTicket>> {

    return this.apiTariffTariffsTicketGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>) => r.body as Array<TariffEditorApiContractTariffTicket>)
    );
  }

  /**
   * Get all tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.gettariffsticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsTicketGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsTicketGet$Response(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsTicketGetPath, 'get');
    if (params) {
      rb.query('serviceId', params.serviceId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.gettariffsticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsTicketGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsTicketGet(params?: {

    /**
     * Service id
     */
    serviceId?: number;

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffTicket>> {

    return this.apiTariffTariffsTicketGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>) => r.body as Array<TariffEditorApiContractTariffTicket>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsTicketPut
   */
  static readonly ApiTariffTariffsTicketPutPath = '/api/Tariff/tariffs/ticket';

  /**
   * Create or update list of tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.puttariffsticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsTicketPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsTicketPut$Plain$Response(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsTicketPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>;
      })
    );
  }

  /**
   * Create or update list of tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.puttariffsticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsTicketPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsTicketPut$Plain(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffTicket>> {

    return this.apiTariffTariffsTicketPut$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>) => r.body as Array<TariffEditorApiContractTariffTicket>)
    );
  }

  /**
   * Create or update list of tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.puttariffsticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsTicketPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsTicketPut$Response(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsTicketPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>;
      })
    );
  }

  /**
   * Create or update list of tariffs of type "Ticket".
   *
   * ### Claim
   *  boservice.tariff.puttariffsticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsTicketPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsTicketPut(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffPassBody>
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffTicket>> {

    return this.apiTariffTariffsTicketPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffTicket>>) => r.body as Array<TariffEditorApiContractTariffTicket>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsTicketDisableGet
   */
  static readonly ApiTariffTariffsTicketDisableGetPath = '/api/Tariff/tariffs/ticket/disable';

  /**
   * Disable list of ticket tariffs.
   *
   * ### Claim
   *  boservice.tariff.disabletariffsticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsTicketDisableGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsTicketDisableGet$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Array of tariff ids
     */
    tariffIds?: Array<number>;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsTicketDisableGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('tariffIds', params.tariffIds, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable list of ticket tariffs.
   *
   * ### Claim
   *  boservice.tariff.disabletariffsticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsTicketDisableGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsTicketDisableGet(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Array of tariff ids
     */
    tariffIds?: Array<number>;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffTariffsTicketDisableGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffTariffsCapGet
   */
  static readonly ApiTariffTariffsCapGetPath = '/api/Tariff/tariffs/cap';

  /**
   * Get all tariffs of type "Cap".
   *
   * ### Claim
   *  boservice.tariff.gettariffscap
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsCapGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsCapGet$Plain$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffCap>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsCapGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffCap>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Cap".
   *
   * ### Claim
   *  boservice.tariff.gettariffscap
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsCapGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsCapGet$Plain(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffCap>> {

    return this.apiTariffTariffsCapGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffCap>>) => r.body as Array<TariffEditorApiContractTariffCap>)
    );
  }

  /**
   * Get all tariffs of type "Cap".
   *
   * ### Claim
   *  boservice.tariff.gettariffscap
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsCapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsCapGet$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffCap>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsCapGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffCap>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Cap".
   *
   * ### Claim
   *  boservice.tariff.gettariffscap
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsCapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsCapGet(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffCap>> {

    return this.apiTariffTariffsCapGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffCap>>) => r.body as Array<TariffEditorApiContractTariffCap>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsCapPut
   */
  static readonly ApiTariffTariffsCapPutPath = '/api/Tariff/tariffs/cap';

  /**
   * Create or update list of tariffs of type "Cap".
   *
   * ### Claim
   *  boservice.tariff.puttariffscap
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsCapPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsCapPut$Response(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffCapBody>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsCapPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create or update list of tariffs of type "Cap".
   *
   * ### Claim
   *  boservice.tariff.puttariffscap
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsCapPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsCapPut(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffCapBody>
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffTariffsCapPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffTariffsCapDisableGet
   */
  static readonly ApiTariffTariffsCapDisableGetPath = '/api/Tariff/tariffs/cap/disable';

  /**
   * Disable list of cap tariffs.
   *
   * ### Claim
   *  boservice.tariff.disabletariffscap
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsCapDisableGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsCapDisableGet$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Array of tariff ids
     */
    tariffIds?: Array<number>;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsCapDisableGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('tariffIds', params.tariffIds, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable list of cap tariffs.
   *
   * ### Claim
   *  boservice.tariff.disabletariffscap
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsCapDisableGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsCapDisableGet(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Array of tariff ids
     */
    tariffIds?: Array<number>;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffTariffsCapDisableGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffTariffsDomainGet
   */
  static readonly ApiTariffTariffsDomainGetPath = '/api/Tariff/tariffs/domain';

  /**
   * Get "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsDomainGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainGet$Plain$Response(params?: {

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractDomain>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsDomainGetPath, 'get');
    if (params) {
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractDomain>>;
      })
    );
  }

  /**
   * Get "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsDomainGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainGet$Plain(params?: {

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractDomain>> {

    return this.apiTariffTariffsDomainGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractDomain>>) => r.body as Array<TariffEditorApiContractDomain>)
    );
  }

  /**
   * Get "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsDomainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainGet$Response(params?: {

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractDomain>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsDomainGetPath, 'get');
    if (params) {
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractDomain>>;
      })
    );
  }

  /**
   * Get "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsDomainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainGet(params?: {

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractDomain>> {

    return this.apiTariffTariffsDomainGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractDomain>>) => r.body as Array<TariffEditorApiContractDomain>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsDomainPut
   */
  static readonly ApiTariffTariffsDomainPutPath = '/api/Tariff/tariffs/domain';

  /**
   * Create or update list of tariffs of type "Domain".
   *
   * ### Claim
   *  boservice.tariff.puttariffsdomain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsDomainPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsDomainPut$Response(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffDomainBody>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsDomainPutPath, 'put');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create or update list of tariffs of type "Domain".
   *
   * ### Claim
   *  boservice.tariff.puttariffsdomain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsDomainPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsDomainPut(params?: {

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
    body?: Array<TariffEditorApiContractTariffDomainBody>
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffTariffsDomainPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffTariffsDomainThresholdGet
   */
  static readonly ApiTariffTariffsDomainThresholdGetPath = '/api/Tariff/tariffs/domainThreshold';

  /**
   * Get all tariffs of type "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsDomainThresholdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainThresholdGet$Plain$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffDomain>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsDomainThresholdGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffDomain>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsDomainThresholdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainThresholdGet$Plain(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffDomain>> {

    return this.apiTariffTariffsDomainThresholdGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffDomain>>) => r.body as Array<TariffEditorApiContractTariffDomain>)
    );
  }

  /**
   * Get all tariffs of type "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsDomainThresholdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainThresholdGet$Response(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffDomain>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsDomainThresholdGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffDomain>>;
      })
    );
  }

  /**
   * Get all tariffs of type "Domain".
   *
   * ### Claim
   *  boservice.tariff.gettariffsdomain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsDomainThresholdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffTariffsDomainThresholdGet(params?: {

    /**
     * Date/time of version start
     */
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffDomain>> {

    return this.apiTariffTariffsDomainThresholdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffDomain>>) => r.body as Array<TariffEditorApiContractTariffDomain>)
    );
  }

  /**
   * Path part for operation apiTariffServicesGet
   */
  static readonly ApiTariffServicesGetPath = '/api/Tariff/services';

  /**
   * Get a list of available services.
   *
   * ### Claim
   *  boservice.tariff.getservices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffServicesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffServicesGet$Plain$Response(params?: {
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * id of a basic service. if not null then function returns list of complex services which contain specified id.
     */
    basicSrvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractService>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffServicesGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.query('basicSrvId', params.basicSrvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractService>>;
      })
    );
  }

  /**
   * Get a list of available services.
   *
   * ### Claim
   *  boservice.tariff.getservices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffServicesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffServicesGet$Plain(params?: {
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * id of a basic service. if not null then function returns list of complex services which contain specified id.
     */
    basicSrvId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractService>> {

    return this.apiTariffServicesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractService>>) => r.body as Array<TariffEditorApiContractService>)
    );
  }

  /**
   * Get a list of available services.
   *
   * ### Claim
   *  boservice.tariff.getservices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffServicesGet$Response(params?: {
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * id of a basic service. if not null then function returns list of complex services which contain specified id.
     */
    basicSrvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractService>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffServicesGetPath, 'get');
    if (params) {
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.query('basicSrvId', params.basicSrvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractService>>;
      })
    );
  }

  /**
   * Get a list of available services.
   *
   * ### Claim
   *  boservice.tariff.getservices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffServicesGet(params?: {
    dateFrom?: string;

    /**
     * Request language
     */
    lang?: string;

    /**
     * transport organizer ID
     */
    trmId?: number;

    /**
     * id of a basic service. if not null then function returns list of complex services which contain specified id.
     */
    basicSrvId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractService>> {

    return this.apiTariffServicesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractService>>) => r.body as Array<TariffEditorApiContractService>)
    );
  }

  /**
   * Path part for operation apiTariffTariffsDomainDomainPut
   */
  static readonly ApiTariffTariffsDomainDomainPutPath = '/api/Tariff/tariffs/domain/{domain}';

  /**
   * ### Claim
   *  boservice.tariff.adddomainthreshold
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffTariffsDomainDomainPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsDomainDomainPut$Response(params: {
    domain: number;
    lang?: string;
    body?: TariffEditorApiContractDomainThreshold
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffTariffsDomainDomainPutPath, 'put');
    if (params) {
      rb.path('domain', params.domain, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.tariff.adddomainthreshold
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffTariffsDomainDomainPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffTariffsDomainDomainPut(params: {
    domain: number;
    lang?: string;
    body?: TariffEditorApiContractDomainThreshold
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffTariffsDomainDomainPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffGetFareTicketDataPost
   */
  static readonly ApiTariffGetFareTicketDataPostPath = '/api/Tariff/GetFareTicketData';

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfareticketdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetFareTicketDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFareTicketDataPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFareTicketDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractFareTicketData>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetFareTicketDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractFareTicketData>;
      })
    );
  }

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfareticketdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetFareTicketDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFareTicketDataPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFareTicketDataRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractFareTicketData> {

    return this.apiTariffGetFareTicketDataPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractFareTicketData>) => r.body as TariffEditorApiContractFareTicketData)
    );
  }

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfareticketdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetFareTicketDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFareTicketDataPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFareTicketDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractFareTicketData>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetFareTicketDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractFareTicketData>;
      })
    );
  }

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfareticketdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetFareTicketDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFareTicketDataPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFareTicketDataRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractFareTicketData> {

    return this.apiTariffGetFareTicketDataPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractFareTicketData>) => r.body as TariffEditorApiContractFareTicketData)
    );
  }

  /**
   * Path part for operation apiTariffListZonesGet
   */
  static readonly ApiTariffListZonesGetPath = '/api/Tariff/ListZones';

  /**
   * Getting a list of zones.
   *
   * ### Claim
   *  boservice.tariff.listzones
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListZonesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListZonesGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractZones>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListZonesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractZones>>;
      })
    );
  }

  /**
   * Getting a list of zones.
   *
   * ### Claim
   *  boservice.tariff.listzones
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListZonesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListZonesGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractZones>> {

    return this.apiTariffListZonesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractZones>>) => r.body as Array<TariffEditorApiContractZones>)
    );
  }

  /**
   * Getting a list of zones.
   *
   * ### Claim
   *  boservice.tariff.listzones
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListZonesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListZonesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractZones>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListZonesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractZones>>;
      })
    );
  }

  /**
   * Getting a list of zones.
   *
   * ### Claim
   *  boservice.tariff.listzones
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListZonesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListZonesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractZones>> {

    return this.apiTariffListZonesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractZones>>) => r.body as Array<TariffEditorApiContractZones>)
    );
  }

  /**
   * Path part for operation apiTariffListPriceZonePost
   */
  static readonly ApiTariffListPriceZonePostPath = '/api/Tariff/ListPriceZone';

  /**
   * Getting prices zone-zone.
   *
   * ### Claim
   *  boservice.tariff.listpricezone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPriceZonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListPriceZonePost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListPriceZoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPriceZone>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPriceZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPriceZone>>;
      })
    );
  }

  /**
   * Getting prices zone-zone.
   *
   * ### Claim
   *  boservice.tariff.listpricezone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPriceZonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListPriceZonePost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListPriceZoneRequest
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPriceZone>> {

    return this.apiTariffListPriceZonePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPriceZone>>) => r.body as Array<TariffEditorApiContractPriceZone>)
    );
  }

  /**
   * Getting prices zone-zone.
   *
   * ### Claim
   *  boservice.tariff.listpricezone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPriceZonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListPriceZonePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListPriceZoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPriceZone>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPriceZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPriceZone>>;
      })
    );
  }

  /**
   * Getting prices zone-zone.
   *
   * ### Claim
   *  boservice.tariff.listpricezone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPriceZonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListPriceZonePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListPriceZoneRequest
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPriceZone>> {

    return this.apiTariffListPriceZonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPriceZone>>) => r.body as Array<TariffEditorApiContractPriceZone>)
    );
  }

  /**
   * Path part for operation apiTariffGetFarePassDataPost
   */
  static readonly ApiTariffGetFarePassDataPostPath = '/api/Tariff/GetFarePassData';

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfarepassdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetFarePassDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFarePassDataPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFarePassDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractFarePassData>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetFarePassDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractFarePassData>;
      })
    );
  }

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfarepassdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetFarePassDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFarePassDataPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFarePassDataRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractFarePassData> {

    return this.apiTariffGetFarePassDataPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractFarePassData>) => r.body as TariffEditorApiContractFarePassData)
    );
  }

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfarepassdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetFarePassDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFarePassDataPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFarePassDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractFarePassData>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetFarePassDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractFarePassData>;
      })
    );
  }

  /**
   * Get ticket details.
   *
   * ### Claim
   *  boservice.tariff.getfarepassdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetFarePassDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffGetFarePassDataPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractFarePassDataRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractFarePassData> {

    return this.apiTariffGetFarePassDataPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractFarePassData>) => r.body as TariffEditorApiContractFarePassData)
    );
  }

  /**
   * Path part for operation apiTariffRemoveFareDestroyDelete
   */
  static readonly ApiTariffRemoveFareDestroyDeletePath = '/api/Tariff/RemoveFareDestroy';

  /**
   * Deleting a ticket or PAYG.
   *
   * ### Claim
   *  boservice.tariff.removefaredestroy
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveFareDestroyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveFareDestroyDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * version datetime
     */
    date?: string;

    /**
     * holiday tariff
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffRemoveFareDestroyDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a ticket or PAYG.
   *
   * ### Claim
   *  boservice.tariff.removefaredestroy
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveFareDestroyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveFareDestroyDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * version datetime
     */
    date?: string;

    /**
     * holiday tariff
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveFareDestroyDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffListTariffGroupGet
   */
  static readonly ApiTariffListTariffGroupGetPath = '/api/Tariff/ListTariffGroup';

  /**
   * Getting groups for the tariff menu.
   *
   * ### Claim
   *  boservice.tariff.listtariffgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffGroupGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffGroup>>;
      })
    );
  }

  /**
   * Getting groups for the tariff menu.
   *
   * ### Claim
   *  boservice.tariff.listtariffgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffGroup>> {

    return this.apiTariffListTariffGroupGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffGroup>>) => r.body as Array<TariffEditorApiContractTariffGroup>)
    );
  }

  /**
   * Getting groups for the tariff menu.
   *
   * ### Claim
   *  boservice.tariff.listtariffgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffGroupGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffGroup>>;
      })
    );
  }

  /**
   * Getting groups for the tariff menu.
   *
   * ### Claim
   *  boservice.tariff.listtariffgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffGroup>> {

    return this.apiTariffListTariffGroupGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffGroup>>) => r.body as Array<TariffEditorApiContractTariffGroup>)
    );
  }

  /**
   * Path part for operation apiTariffListTariffGroupRepGet
   */
  static readonly ApiTariffListTariffGroupRepGetPath = '/api/Tariff/ListTariffGroupRep';

  /**
   * Getting groups for reports.
   *
   * ### Claim
   *  boservice.tariff.listtariffgrouprep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffGroupRepGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupRepGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffGroupRep>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffGroupRepGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffGroupRep>>;
      })
    );
  }

  /**
   * Getting groups for reports.
   *
   * ### Claim
   *  boservice.tariff.listtariffgrouprep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffGroupRepGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupRepGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffGroupRep>> {

    return this.apiTariffListTariffGroupRepGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffGroupRep>>) => r.body as Array<TariffEditorApiContractTariffGroupRep>)
    );
  }

  /**
   * Getting groups for reports.
   *
   * ### Claim
   *  boservice.tariff.listtariffgrouprep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffGroupRepGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupRepGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffGroupRep>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffGroupRepGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffGroupRep>>;
      })
    );
  }

  /**
   * Getting groups for reports.
   *
   * ### Claim
   *  boservice.tariff.listtariffgrouprep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffGroupRepGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffGroupRepGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffGroupRep>> {

    return this.apiTariffListTariffGroupRepGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffGroupRep>>) => r.body as Array<TariffEditorApiContractTariffGroupRep>)
    );
  }

  /**
   * Path part for operation apiTariffListPrivGroupGet
   */
  static readonly ApiTariffListPrivGroupGetPath = '/api/Tariff/ListPrivGroup';

  /**
   * Get Benefit Groups.
   *
   * ### Claim
   *  boservice.tariff.listprivgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPrivGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivGroupGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPrivGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPrivGroupGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPrivGroup>>;
      })
    );
  }

  /**
   * Get Benefit Groups.
   *
   * ### Claim
   *  boservice.tariff.listprivgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPrivGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivGroupGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPrivGroup>> {

    return this.apiTariffListPrivGroupGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPrivGroup>>) => r.body as Array<TariffEditorApiContractPrivGroup>)
    );
  }

  /**
   * Get Benefit Groups.
   *
   * ### Claim
   *  boservice.tariff.listprivgroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPrivGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivGroupGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPrivGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPrivGroupGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPrivGroup>>;
      })
    );
  }

  /**
   * Get Benefit Groups.
   *
   * ### Claim
   *  boservice.tariff.listprivgroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPrivGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivGroupGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPrivGroup>> {

    return this.apiTariffListPrivGroupGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPrivGroup>>) => r.body as Array<TariffEditorApiContractPrivGroup>)
    );
  }

  /**
   * Path part for operation apiTariffListRouteGroupGet
   */
  static readonly ApiTariffListRouteGroupGetPath = '/api/Tariff/ListRouteGroup';

  /**
   * Get route groups.
   *
   * ### Claim
   *  boservice.tariff.listroutegroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListRouteGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListRouteGroupGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractRouteGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListRouteGroupGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractRouteGroup>>;
      })
    );
  }

  /**
   * Get route groups.
   *
   * ### Claim
   *  boservice.tariff.listroutegroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListRouteGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListRouteGroupGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractRouteGroup>> {

    return this.apiTariffListRouteGroupGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractRouteGroup>>) => r.body as Array<TariffEditorApiContractRouteGroup>)
    );
  }

  /**
   * Get route groups.
   *
   * ### Claim
   *  boservice.tariff.listroutegroup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListRouteGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListRouteGroupGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractRouteGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListRouteGroupGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractRouteGroup>>;
      })
    );
  }

  /**
   * Get route groups.
   *
   * ### Claim
   *  boservice.tariff.listroutegroup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListRouteGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListRouteGroupGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractRouteGroup>> {

    return this.apiTariffListRouteGroupGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractRouteGroup>>) => r.body as Array<TariffEditorApiContractRouteGroup>)
    );
  }

  /**
   * Path part for operation apiTariffListTicketPeriodGet
   */
  static readonly ApiTariffListTicketPeriodGetPath = '/api/Tariff/ListTicketPeriod';

  /**
   * List of special periods.
   *
   * ### Claim
   *  boservice.tariff.listticketperiod
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTicketPeriodGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTicketPeriodGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTicketPeriod>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTicketPeriodGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTicketPeriod>>;
      })
    );
  }

  /**
   * List of special periods.
   *
   * ### Claim
   *  boservice.tariff.listticketperiod
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTicketPeriodGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTicketPeriodGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTicketPeriod>> {

    return this.apiTariffListTicketPeriodGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTicketPeriod>>) => r.body as Array<TariffEditorApiContractTicketPeriod>)
    );
  }

  /**
   * List of special periods.
   *
   * ### Claim
   *  boservice.tariff.listticketperiod
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTicketPeriodGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTicketPeriodGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTicketPeriod>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTicketPeriodGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTicketPeriod>>;
      })
    );
  }

  /**
   * List of special periods.
   *
   * ### Claim
   *  boservice.tariff.listticketperiod
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTicketPeriodGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTicketPeriodGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTicketPeriod>> {

    return this.apiTariffListTicketPeriodGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTicketPeriod>>) => r.body as Array<TariffEditorApiContractTicketPeriod>)
    );
  }

  /**
   * Path part for operation apiTariffListPeriodExcludeGet
   */
  static readonly ApiTariffListPeriodExcludeGetPath = '/api/Tariff/ListPeriodExclude';

  /**
   * Ban Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodexclude
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPeriodExcludeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodExcludeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPeriodExclude>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPeriodExcludeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPeriodExclude>>;
      })
    );
  }

  /**
   * Ban Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodexclude
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPeriodExcludeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodExcludeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPeriodExclude>> {

    return this.apiTariffListPeriodExcludeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPeriodExclude>>) => r.body as Array<TariffEditorApiContractPeriodExclude>)
    );
  }

  /**
   * Ban Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodexclude
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPeriodExcludeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodExcludeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPeriodExclude>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPeriodExcludeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPeriodExclude>>;
      })
    );
  }

  /**
   * Ban Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodexclude
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPeriodExcludeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodExcludeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPeriodExclude>> {

    return this.apiTariffListPeriodExcludeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPeriodExclude>>) => r.body as Array<TariffEditorApiContractPeriodExclude>)
    );
  }

  /**
   * Path part for operation apiTariffListPeriodIncludeGet
   */
  static readonly ApiTariffListPeriodIncludeGetPath = '/api/Tariff/ListPeriodInclude';

  /**
   * Permission Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodinclude
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPeriodIncludeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodIncludeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPeriodInclude>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPeriodIncludeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPeriodInclude>>;
      })
    );
  }

  /**
   * Permission Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodinclude
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPeriodIncludeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodIncludeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPeriodInclude>> {

    return this.apiTariffListPeriodIncludeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPeriodInclude>>) => r.body as Array<TariffEditorApiContractPeriodInclude>)
    );
  }

  /**
   * Permission Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodinclude
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPeriodIncludeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodIncludeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractPeriodInclude>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPeriodIncludeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractPeriodInclude>>;
      })
    );
  }

  /**
   * Permission Exception List.
   *
   * ### Claim
   *  boservice.tariff.listperiodinclude
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPeriodIncludeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPeriodIncludeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractPeriodInclude>> {

    return this.apiTariffListPeriodIncludeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractPeriodInclude>>) => r.body as Array<TariffEditorApiContractPeriodInclude>)
    );
  }

  /**
   * Path part for operation apiTariffListTariffConditionGet
   */
  static readonly ApiTariffListTariffConditionGetPath = '/api/Tariff/ListTariffCondition';

  /**
   * List of tariff conditions.
   *
   * ### Claim
   *  boservice.tariff.listtariffcondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffConditionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffConditionGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffCondition>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffConditionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffCondition>>;
      })
    );
  }

  /**
   * List of tariff conditions.
   *
   * ### Claim
   *  boservice.tariff.listtariffcondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffConditionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffConditionGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffCondition>> {

    return this.apiTariffListTariffConditionGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffCondition>>) => r.body as Array<TariffEditorApiContractTariffCondition>)
    );
  }

  /**
   * List of tariff conditions.
   *
   * ### Claim
   *  boservice.tariff.listtariffcondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffConditionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffConditionGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTariffCondition>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffConditionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTariffCondition>>;
      })
    );
  }

  /**
   * List of tariff conditions.
   *
   * ### Claim
   *  boservice.tariff.listtariffcondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffConditionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffConditionGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTariffCondition>> {

    return this.apiTariffListTariffConditionGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTariffCondition>>) => r.body as Array<TariffEditorApiContractTariffCondition>)
    );
  }

  /**
   * Path part for operation apiTariffSetPriceZonePost
   */
  static readonly ApiTariffSetPriceZonePostPath = '/api/Tariff/SetPriceZone';

  /**
   * Editing zone prices.
   *
   * ### Claim
   *  boservice.tariff.setpricezone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetPriceZonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetPriceZonePost$Response(params?: {

    /**
     * interface language
     */
    lang?: string;
    body?: TariffEditorApiContractPriceZoneARequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetPriceZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing zone prices.
   *
   * ### Claim
   *  boservice.tariff.setpricezone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetPriceZonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetPriceZonePost(params?: {

    /**
     * interface language
     */
    lang?: string;
    body?: TariffEditorApiContractPriceZoneARequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetPriceZonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffAddFareTicketPost
   */
  static readonly ApiTariffAddFareTicketPostPath = '/api/Tariff/AddFareTicket';

  /**
   * Adding a ticket.
   *
   * ### Claim
   *  boservice.tariff.addfareticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddFareTicketPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFareTicketPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFareTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddFareTicketResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffAddFareTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddFareTicketResponse>;
      })
    );
  }

  /**
   * Adding a ticket.
   *
   * ### Claim
   *  boservice.tariff.addfareticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddFareTicketPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFareTicketPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFareTicketRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddFareTicketResponse> {

    return this.apiTariffAddFareTicketPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddFareTicketResponse>) => r.body as TariffEditorApiContractAddFareTicketResponse)
    );
  }

  /**
   * Adding a ticket.
   *
   * ### Claim
   *  boservice.tariff.addfareticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddFareTicketPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFareTicketPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFareTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddFareTicketResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffAddFareTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddFareTicketResponse>;
      })
    );
  }

  /**
   * Adding a ticket.
   *
   * ### Claim
   *  boservice.tariff.addfareticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddFareTicketPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFareTicketPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFareTicketRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddFareTicketResponse> {

    return this.apiTariffAddFareTicketPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddFareTicketResponse>) => r.body as TariffEditorApiContractAddFareTicketResponse)
    );
  }

  /**
   * Path part for operation apiTariffSetFareTicketPost
   */
  static readonly ApiTariffSetFareTicketPostPath = '/api/Tariff/SetFareTicket';

  /**
   * Ticket editing.
   *
   * ### Claim
   *  boservice.tariff.setfareticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFareTicketPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareTicketPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractFareTicketData>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFareTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractFareTicketData>;
      })
    );
  }

  /**
   * Ticket editing.
   *
   * ### Claim
   *  boservice.tariff.setfareticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFareTicketPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareTicketPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareTicketRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractFareTicketData> {

    return this.apiTariffSetFareTicketPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractFareTicketData>) => r.body as TariffEditorApiContractFareTicketData)
    );
  }

  /**
   * Ticket editing.
   *
   * ### Claim
   *  boservice.tariff.setfareticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFareTicketPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareTicketPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractFareTicketData>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFareTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractFareTicketData>;
      })
    );
  }

  /**
   * Ticket editing.
   *
   * ### Claim
   *  boservice.tariff.setfareticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFareTicketPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareTicketPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareTicketRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractFareTicketData> {

    return this.apiTariffSetFareTicketPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractFareTicketData>) => r.body as TariffEditorApiContractFareTicketData)
    );
  }

  /**
   * Path part for operation apiTariffListTicketPriceZonePost
   */
  static readonly ApiTariffListTicketPriceZonePostPath = '/api/Tariff/ListTicketPriceZone';

  /**
   * view area ticket prices.
   *
   * ### Claim
   *  boservice.tariff.listticketpricezone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTicketPriceZonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListTicketPriceZonePost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListTicketPriceZoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTicketPriceZone>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTicketPriceZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTicketPriceZone>>;
      })
    );
  }

  /**
   * view area ticket prices.
   *
   * ### Claim
   *  boservice.tariff.listticketpricezone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTicketPriceZonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListTicketPriceZonePost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListTicketPriceZoneRequest
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTicketPriceZone>> {

    return this.apiTariffListTicketPriceZonePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTicketPriceZone>>) => r.body as Array<TariffEditorApiContractTicketPriceZone>)
    );
  }

  /**
   * view area ticket prices.
   *
   * ### Claim
   *  boservice.tariff.listticketpricezone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTicketPriceZonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListTicketPriceZonePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListTicketPriceZoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractTicketPriceZone>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTicketPriceZonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractTicketPriceZone>>;
      })
    );
  }

  /**
   * view area ticket prices.
   *
   * ### Claim
   *  boservice.tariff.listticketpricezone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTicketPriceZonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffListTicketPriceZonePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractListTicketPriceZoneRequest
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractTicketPriceZone>> {

    return this.apiTariffListTicketPriceZonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractTicketPriceZone>>) => r.body as Array<TariffEditorApiContractTicketPriceZone>)
    );
  }

  /**
   * Path part for operation apiTariffCopyFareTicketPost
   */
  static readonly ApiTariffCopyFareTicketPostPath = '/api/Tariff/CopyFareTicket';

  /**
   * fare copying.
   *
   * ### Claim
   *  boservice.tariff.copyfareticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffCopyFareTicketPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffCopyFareTicketPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractCopyFareTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractCopyFareTicket>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffCopyFareTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractCopyFareTicket>;
      })
    );
  }

  /**
   * fare copying.
   *
   * ### Claim
   *  boservice.tariff.copyfareticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffCopyFareTicketPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffCopyFareTicketPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractCopyFareTicketRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractCopyFareTicket> {

    return this.apiTariffCopyFareTicketPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractCopyFareTicket>) => r.body as TariffEditorApiContractCopyFareTicket)
    );
  }

  /**
   * fare copying.
   *
   * ### Claim
   *  boservice.tariff.copyfareticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffCopyFareTicketPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffCopyFareTicketPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractCopyFareTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractCopyFareTicket>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffCopyFareTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractCopyFareTicket>;
      })
    );
  }

  /**
   * fare copying.
   *
   * ### Claim
   *  boservice.tariff.copyfareticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffCopyFareTicketPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffCopyFareTicketPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractCopyFareTicketRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractCopyFareTicket> {

    return this.apiTariffCopyFareTicketPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractCopyFareTicket>) => r.body as TariffEditorApiContractCopyFareTicket)
    );
  }

  /**
   * Path part for operation apiTariffSetFareTicketParamsPost
   */
  static readonly ApiTariffSetFareTicketParamsPostPath = '/api/Tariff/SetFareTicketParams';

  /**
   * Changing parameters for a copied fare.
   *
   * ### Claim
   *  boservice.tariff.setfareticketparams
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFareTicketParamsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareTicketParamsPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareTicketParamsRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFareTicketParamsPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing parameters for a copied fare.
   *
   * ### Claim
   *  boservice.tariff.setfareticketparams
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFareTicketParamsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareTicketParamsPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareTicketParamsRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetFareTicketParamsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffDomainListGet
   */
  static readonly ApiTariffDomainListGetPath = '/api/Tariff/DomainList';

  /**
   * Getting a list of areas of control.
   *
   * Getting a list of control areas (domains) for the specified PTA or for all PTAs, to
   * to which the current user has access
   *  #LVO
   * ### Claim
   *  boservice.tariff.domainlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffDomainListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffDomainListGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id pt/null if not specified returns a list for all pt (including access
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractDomainListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffDomainListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractDomainListResponse>>;
      })
    );
  }

  /**
   * Getting a list of areas of control.
   *
   * Getting a list of control areas (domains) for the specified PTA or for all PTAs, to
   * to which the current user has access
   *  #LVO
   * ### Claim
   *  boservice.tariff.domainlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffDomainListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffDomainListGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id pt/null if not specified returns a list for all pt (including access
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractDomainListResponse>> {

    return this.apiTariffDomainListGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractDomainListResponse>>) => r.body as Array<TariffEditorApiContractDomainListResponse>)
    );
  }

  /**
   * Getting a list of areas of control.
   *
   * Getting a list of control areas (domains) for the specified PTA or for all PTAs, to
   * to which the current user has access
   *  #LVO
   * ### Claim
   *  boservice.tariff.domainlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffDomainListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffDomainListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id pt/null if not specified returns a list for all pt (including access
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractDomainListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffDomainListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractDomainListResponse>>;
      })
    );
  }

  /**
   * Getting a list of areas of control.
   *
   * Getting a list of control areas (domains) for the specified PTA or for all PTAs, to
   * to which the current user has access
   *  #LVO
   * ### Claim
   *  boservice.tariff.domainlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffDomainListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffDomainListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id pt/null if not specified returns a list for all pt (including access
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractDomainListResponse>> {

    return this.apiTariffDomainListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractDomainListResponse>>) => r.body as Array<TariffEditorApiContractDomainListResponse>)
    );
  }

  /**
   * Path part for operation apiTariffGetDbtimeGet
   */
  static readonly ApiTariffGetDbtimeGetPath = '/api/Tariff/GetDbtime';

  /**
   * receiving time.
   *
   * ### Claim
   *  boservice.tariff.getdbtime
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetDbtimeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDbtimeGet$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetDbtimeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetDbtimeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetDbtimeResponse>;
      })
    );
  }

  /**
   * receiving time.
   *
   * ### Claim
   *  boservice.tariff.getdbtime
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetDbtimeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDbtimeGet$Plain(params?: {
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetDbtimeResponse> {

    return this.apiTariffGetDbtimeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetDbtimeResponse>) => r.body as TariffEditorApiContractGetDbtimeResponse)
    );
  }

  /**
   * receiving time.
   *
   * ### Claim
   *  boservice.tariff.getdbtime
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetDbtimeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDbtimeGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetDbtimeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetDbtimeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetDbtimeResponse>;
      })
    );
  }

  /**
   * receiving time.
   *
   * ### Claim
   *  boservice.tariff.getdbtime
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetDbtimeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDbtimeGet(params?: {
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetDbtimeResponse> {

    return this.apiTariffGetDbtimeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetDbtimeResponse>) => r.body as TariffEditorApiContractGetDbtimeResponse)
    );
  }

  /**
   * Path part for operation apiTariffListFareLimitGet
   */
  static readonly ApiTariffListFareLimitGetPath = '/api/Tariff/ListFareLimit';

  /**
   * Getting usage limits for PP and PAYG tariffs.
   *
   * Getting usage limits for services (if relocation_avl = 1)
   * ### Claim
   *  boservice.tariff.listfarelimit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFareLimitGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareLimitGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFareLimitResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFareLimitGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFareLimitResponse>>;
      })
    );
  }

  /**
   * Getting usage limits for PP and PAYG tariffs.
   *
   * Getting usage limits for services (if relocation_avl = 1)
   * ### Claim
   *  boservice.tariff.listfarelimit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFareLimitGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareLimitGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFareLimitResponse>> {

    return this.apiTariffListFareLimitGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFareLimitResponse>>) => r.body as Array<TariffEditorApiContractListFareLimitResponse>)
    );
  }

  /**
   * Getting usage limits for PP and PAYG tariffs.
   *
   * Getting usage limits for services (if relocation_avl = 1)
   * ### Claim
   *  boservice.tariff.listfarelimit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFareLimitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareLimitGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFareLimitResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFareLimitGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFareLimitResponse>>;
      })
    );
  }

  /**
   * Getting usage limits for PP and PAYG tariffs.
   *
   * Getting usage limits for services (if relocation_avl = 1)
   * ### Claim
   *  boservice.tariff.listfarelimit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFareLimitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareLimitGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFareLimitResponse>> {

    return this.apiTariffListFareLimitGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFareLimitResponse>>) => r.body as Array<TariffEditorApiContractListFareLimitResponse>)
    );
  }

  /**
   * Path part for operation apiTariffSetFareLimitPost
   */
  static readonly ApiTariffSetFareLimitPostPath = '/api/Tariff/SetFareLimit';

  /**
   * Editing service limits (PP and PAYG).
   *
   * Editing service limits (if relocation_avl = 1)
   * ### Claim
   *  boservice.tariff.setfarelimit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFareLimitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareLimitPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareLimitRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFareLimitPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing service limits (PP and PAYG).
   *
   * Editing service limits (if relocation_avl = 1)
   * ### Claim
   *  boservice.tariff.setfarelimit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFareLimitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareLimitPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareLimitRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetFareLimitPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffListFarePrvPartGet
   */
  static readonly ApiTariffListFarePrvPartGetPath = '/api/Tariff/ListFarePrvPart';

  /**
   * Receiving shares by settlement providers (PP).
   *
   * ### Claim
   *  boservice.tariff.listfareprvpart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFarePrvPartGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePrvPartGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFarePrvPartResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFarePrvPartGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFarePrvPartResponse>>;
      })
    );
  }

  /**
   * Receiving shares by settlement providers (PP).
   *
   * ### Claim
   *  boservice.tariff.listfareprvpart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFarePrvPartGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePrvPartGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFarePrvPartResponse>> {

    return this.apiTariffListFarePrvPartGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFarePrvPartResponse>>) => r.body as Array<TariffEditorApiContractListFarePrvPartResponse>)
    );
  }

  /**
   * Receiving shares by settlement providers (PP).
   *
   * ### Claim
   *  boservice.tariff.listfareprvpart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFarePrvPartGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePrvPartGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFarePrvPartResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFarePrvPartGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFarePrvPartResponse>>;
      })
    );
  }

  /**
   * Receiving shares by settlement providers (PP).
   *
   * ### Claim
   *  boservice.tariff.listfareprvpart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFarePrvPartGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePrvPartGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * c service id null
     */
    srvId?: number;

    /**
     * with fare id/null for new. if null then i_srv_id must be specified
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFarePrvPartResponse>> {

    return this.apiTariffListFarePrvPartGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFarePrvPartResponse>>) => r.body as Array<TariffEditorApiContractListFarePrvPartResponse>)
    );
  }

  /**
   * Path part for operation apiTariffSetFarePrvPartPost
   */
  static readonly ApiTariffSetFarePrvPartPostPath = '/api/Tariff/SetFarePrvPart';

  /**
   * Editing shares by providers.
   *
   * ### Claim
   *  boservice.tariff.setfareprvpart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFarePrvPartPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFarePrvPartPost$Response(params?: {
    lang?: string;
    body?: TariffEditorApiContractSetFarePrvPartRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFarePrvPartPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing shares by providers.
   *
   * ### Claim
   *  boservice.tariff.setfareprvpart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFarePrvPartPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFarePrvPartPost(params?: {
    lang?: string;
    body?: TariffEditorApiContractSetFarePrvPartRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetFarePrvPartPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffListPriceFsGet
   */
  static readonly ApiTariffListPriceFsGetPath = '/api/Tariff/ListPriceFs';

  /**
   * Getting fare-stage prices (price_type=3).
   *
   * ### Claim
   *  boservice.tariff.listpricefs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPriceFsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPriceFsGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * version datetime
     */
    date?: string;

    /**
     * id services
     */
    srvId?: number;

    /**
     * fare id/null for new fare
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListPriceFsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPriceFsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListPriceFsResponse>>;
      })
    );
  }

  /**
   * Getting fare-stage prices (price_type=3).
   *
   * ### Claim
   *  boservice.tariff.listpricefs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPriceFsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPriceFsGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * version datetime
     */
    date?: string;

    /**
     * id services
     */
    srvId?: number;

    /**
     * fare id/null for new fare
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListPriceFsResponse>> {

    return this.apiTariffListPriceFsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListPriceFsResponse>>) => r.body as Array<TariffEditorApiContractListPriceFsResponse>)
    );
  }

  /**
   * Getting fare-stage prices (price_type=3).
   *
   * ### Claim
   *  boservice.tariff.listpricefs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPriceFsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPriceFsGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * version datetime
     */
    date?: string;

    /**
     * id services
     */
    srvId?: number;

    /**
     * fare id/null for new fare
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListPriceFsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPriceFsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfId', params.trfId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListPriceFsResponse>>;
      })
    );
  }

  /**
   * Getting fare-stage prices (price_type=3).
   *
   * ### Claim
   *  boservice.tariff.listpricefs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPriceFsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPriceFsGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * version datetime
     */
    date?: string;

    /**
     * id services
     */
    srvId?: number;

    /**
     * fare id/null for new fare
     */
    trfId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListPriceFsResponse>> {

    return this.apiTariffListPriceFsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListPriceFsResponse>>) => r.body as Array<TariffEditorApiContractListPriceFsResponse>)
    );
  }

  /**
   * Path part for operation apiTariffSetPriceFsPost
   */
  static readonly ApiTariffSetPriceFsPostPath = '/api/Tariff/SetPriceFs';

  /**
   * Edit fare-stage prices (price_type=3).
   *
   * ### Claim
   *  boservice.tariff.setpricefs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetPriceFsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetPriceFsPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetPriceFsRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetPriceFsPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Edit fare-stage prices (price_type=3).
   *
   * ### Claim
   *  boservice.tariff.setpricefs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetPriceFsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetPriceFsPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetPriceFsRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetPriceFsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffAddFarePassPost
   */
  static readonly ApiTariffAddFarePassPostPath = '/api/Tariff/AddFarePass';

  /**
   * Adding a PAYG tariff.
   *
   * ### Claim
   *  boservice.tariff.addfarepass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddFarePassPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFarePassPost$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFarePassRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddFarePassResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffAddFarePassPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddFarePassResponse>;
      })
    );
  }

  /**
   * Adding a PAYG tariff.
   *
   * ### Claim
   *  boservice.tariff.addfarepass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddFarePassPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFarePassPost$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFarePassRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddFarePassResponse> {

    return this.apiTariffAddFarePassPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddFarePassResponse>) => r.body as TariffEditorApiContractAddFarePassResponse)
    );
  }

  /**
   * Adding a PAYG tariff.
   *
   * ### Claim
   *  boservice.tariff.addfarepass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddFarePassPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFarePassPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFarePassRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddFarePassResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffAddFarePassPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddFarePassResponse>;
      })
    );
  }

  /**
   * Adding a PAYG tariff.
   *
   * ### Claim
   *  boservice.tariff.addfarepass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddFarePassPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddFarePassPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddFarePassRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddFarePassResponse> {

    return this.apiTariffAddFarePassPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddFarePassResponse>) => r.body as TariffEditorApiContractAddFarePassResponse)
    );
  }

  /**
   * Path part for operation apiTariffSetFarePassPost
   */
  static readonly ApiTariffSetFarePassPostPath = '/api/Tariff/SetFarePass';

  /**
   * Editing the PAYG tariff.
   *
   * ### Claim
   *  boservice.tariff.setfarepass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFarePassPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFarePassPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFarePassRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFarePassPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing the PAYG tariff.
   *
   * ### Claim
   *  boservice.tariff.setfarepass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFarePassPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFarePassPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFarePassRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetFarePassPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffListFarePriceTypeGet
   */
  static readonly ApiTariffListFarePriceTypeGetPath = '/api/Tariff/ListFarePriceType';

  /**
   * Getting Possible Price Types for a Tariff.
   *
   * ### Claim
   *  boservice.tariff.listfarepricetype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFarePriceTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePriceTypeGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;

    /**
     * * tariff type: 1 - one-time tariff (payg) 2 - subscription tariff (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFarePriceTypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFarePriceTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfType', params.trfType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFarePriceTypeResponse>>;
      })
    );
  }

  /**
   * Getting Possible Price Types for a Tariff.
   *
   * ### Claim
   *  boservice.tariff.listfarepricetype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFarePriceTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePriceTypeGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;

    /**
     * * tariff type: 1 - one-time tariff (payg) 2 - subscription tariff (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFarePriceTypeResponse>> {

    return this.apiTariffListFarePriceTypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFarePriceTypeResponse>>) => r.body as Array<TariffEditorApiContractListFarePriceTypeResponse>)
    );
  }

  /**
   * Getting Possible Price Types for a Tariff.
   *
   * ### Claim
   *  boservice.tariff.listfarepricetype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFarePriceTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePriceTypeGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;

    /**
     * * tariff type: 1 - one-time tariff (payg) 2 - subscription tariff (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFarePriceTypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFarePriceTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfType', params.trfType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFarePriceTypeResponse>>;
      })
    );
  }

  /**
   * Getting Possible Price Types for a Tariff.
   *
   * ### Claim
   *  boservice.tariff.listfarepricetype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFarePriceTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFarePriceTypeGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;

    /**
     * * tariff type: 1 - one-time tariff (payg) 2 - subscription tariff (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFarePriceTypeResponse>> {

    return this.apiTariffListFarePriceTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFarePriceTypeResponse>>) => r.body as Array<TariffEditorApiContractListFarePriceTypeResponse>)
    );
  }

  /**
   * Path part for operation apiTariffListFareMenuGet
   */
  static readonly ApiTariffListFareMenuGetPath = '/api/Tariff/ListFareMenu';

  /**
   * Getting a list of all tariffs (except for archived ones) for sorting editing.
   *
   * Entries are returned in service sort order, within services in fare sort order.
   * ### Claim
   *  boservice.tariff.listfaremenu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFareMenuGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareMenuGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * transport organizer ID
     */
    trmId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * fare type/null: 1 – single fare (payg) 2 – subscription fare (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFareMenuResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFareMenuGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfType', params.trfType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFareMenuResponse>>;
      })
    );
  }

  /**
   * Getting a list of all tariffs (except for archived ones) for sorting editing.
   *
   * Entries are returned in service sort order, within services in fare sort order.
   * ### Claim
   *  boservice.tariff.listfaremenu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFareMenuGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareMenuGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * transport organizer ID
     */
    trmId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * fare type/null: 1 – single fare (payg) 2 – subscription fare (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFareMenuResponse>> {

    return this.apiTariffListFareMenuGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFareMenuResponse>>) => r.body as Array<TariffEditorApiContractListFareMenuResponse>)
    );
  }

  /**
   * Getting a list of all tariffs (except for archived ones) for sorting editing.
   *
   * Entries are returned in service sort order, within services in fare sort order.
   * ### Claim
   *  boservice.tariff.listfaremenu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListFareMenuGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareMenuGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * transport organizer ID
     */
    trmId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * fare type/null: 1 – single fare (payg) 2 – subscription fare (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListFareMenuResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListFareMenuGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trfType', params.trfType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListFareMenuResponse>>;
      })
    );
  }

  /**
   * Getting a list of all tariffs (except for archived ones) for sorting editing.
   *
   * Entries are returned in service sort order, within services in fare sort order.
   * ### Claim
   *  boservice.tariff.listfaremenu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListFareMenuGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListFareMenuGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * transport organizer ID
     */
    trmId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * fare type/null: 1 – single fare (payg) 2 – subscription fare (pp)
     */
    trfType?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListFareMenuResponse>> {

    return this.apiTariffListFareMenuGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListFareMenuResponse>>) => r.body as Array<TariffEditorApiContractListFareMenuResponse>)
    );
  }

  /**
   * Path part for operation apiTariffSetFareSortPost
   */
  static readonly ApiTariffSetFareSortPostPath = '/api/Tariff/SetFareSort';

  /**
   * Editing the sorting order of tariffs for display on the interface.
   *
   * ### Claim
   *  boservice.tariff.setfaresort
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetFareSortPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareSortPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareSortRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffSetFareSortPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing the sorting order of tariffs for display on the interface.
   *
   * ### Claim
   *  boservice.tariff.setfaresort
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetFareSortPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetFareSortPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetFareSortRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetFareSortPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffListPrivCategGet
   */
  static readonly ApiTariffListPrivCategGetPath = '/api/Tariff/ListPrivCateg';

  /**
   * get a list of benefits.
   *
   * ### Claim
   *  boservice.tariff.listprivcateg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPrivCategGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivCategGet$Plain$Response(params?: {

    /**
     * ui language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListPrivCategResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPrivCategGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListPrivCategResponse>>;
      })
    );
  }

  /**
   * get a list of benefits.
   *
   * ### Claim
   *  boservice.tariff.listprivcateg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPrivCategGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivCategGet$Plain(params?: {

    /**
     * ui language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListPrivCategResponse>> {

    return this.apiTariffListPrivCategGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListPrivCategResponse>>) => r.body as Array<TariffEditorApiContractListPrivCategResponse>)
    );
  }

  /**
   * get a list of benefits.
   *
   * ### Claim
   *  boservice.tariff.listprivcateg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListPrivCategGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivCategGet$Response(params?: {

    /**
     * ui language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListPrivCategResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListPrivCategGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListPrivCategResponse>>;
      })
    );
  }

  /**
   * get a list of benefits.
   *
   * ### Claim
   *  boservice.tariff.listprivcateg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListPrivCategGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListPrivCategGet(params?: {

    /**
     * ui language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListPrivCategResponse>> {

    return this.apiTariffListPrivCategGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListPrivCategResponse>>) => r.body as Array<TariffEditorApiContractListPrivCategResponse>)
    );
  }

  /**
   * Path part for operation apiTariffGetTariffGroupRepDataGet
   */
  static readonly ApiTariffGetTariffGroupRepDataGetPath = '/api/Tariff/GetTariffGroupRepData';

  /**
   * Getting data about the rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.gettariffgrouprepdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetTariffGroupRepDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffGroupRepDataGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group ID
     */
    trgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetTariffGroupRepDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetTariffGroupRepDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trgId', params.trgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetTariffGroupRepDataResponse>;
      })
    );
  }

  /**
   * Getting data about the rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.gettariffgrouprepdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetTariffGroupRepDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffGroupRepDataGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group ID
     */
    trgId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetTariffGroupRepDataResponse> {

    return this.apiTariffGetTariffGroupRepDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetTariffGroupRepDataResponse>) => r.body as TariffEditorApiContractGetTariffGroupRepDataResponse)
    );
  }

  /**
   * Getting data about the rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.gettariffgrouprepdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetTariffGroupRepDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffGroupRepDataGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group ID
     */
    trgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetTariffGroupRepDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetTariffGroupRepDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trgId', params.trgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetTariffGroupRepDataResponse>;
      })
    );
  }

  /**
   * Getting data about the rate group for reports.
   *
   * ### Claim
   *  boservice.tariff.gettariffgrouprepdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetTariffGroupRepDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffGroupRepDataGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group ID
     */
    trgId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetTariffGroupRepDataResponse> {

    return this.apiTariffGetTariffGroupRepDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetTariffGroupRepDataResponse>) => r.body as TariffEditorApiContractGetTariffGroupRepDataResponse)
    );
  }

  /**
   * Path part for operation apiTariffGetDisplayTariffGroupDataGet
   */
  static readonly ApiTariffGetDisplayTariffGroupDataGetPath = '/api/Tariff/GetDisplayTariffGroupData';

  /**
   * Get rate group data to display.
   *
   * ### Claim
   *  boservice.tariff.getdisplaytariffgroupdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetDisplayTariffGroupDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDisplayTariffGroupDataGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group id to display
     */
    dtgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetDisplayTariffGroupDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetDisplayTariffGroupDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dtgId', params.dtgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetDisplayTariffGroupDataResponse>;
      })
    );
  }

  /**
   * Get rate group data to display.
   *
   * ### Claim
   *  boservice.tariff.getdisplaytariffgroupdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetDisplayTariffGroupDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDisplayTariffGroupDataGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group id to display
     */
    dtgId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetDisplayTariffGroupDataResponse> {

    return this.apiTariffGetDisplayTariffGroupDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetDisplayTariffGroupDataResponse>) => r.body as TariffEditorApiContractGetDisplayTariffGroupDataResponse)
    );
  }

  /**
   * Get rate group data to display.
   *
   * ### Claim
   *  boservice.tariff.getdisplaytariffgroupdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetDisplayTariffGroupDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDisplayTariffGroupDataGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group id to display
     */
    dtgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetDisplayTariffGroupDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffGetDisplayTariffGroupDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('dtgId', params.dtgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetDisplayTariffGroupDataResponse>;
      })
    );
  }

  /**
   * Get rate group data to display.
   *
   * ### Claim
   *  boservice.tariff.getdisplaytariffgroupdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetDisplayTariffGroupDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDisplayTariffGroupDataGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * rate group id to display
     */
    dtgId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetDisplayTariffGroupDataResponse> {

    return this.apiTariffGetDisplayTariffGroupDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetDisplayTariffGroupDataResponse>) => r.body as TariffEditorApiContractGetDisplayTariffGroupDataResponse)
    );
  }

  /**
   * Path part for operation apiTariffListTariffZoneGet
   */
  static readonly ApiTariffListTariffZoneGetPath = '/api/Tariff/ListTariffZone';

  /**
   * Getting a list of zones.
   *
   * Getting the list of zones for the tariff default zone.
   * For a complex service, returns the zones of its basic services.
   * ### Claim
   *  boservice.tariff.listtariffzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffZoneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffZoneGet$Plain$Response(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListTariffZoneResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListTariffZoneResponse>>;
      })
    );
  }

  /**
   * Getting a list of zones.
   *
   * Getting the list of zones for the tariff default zone.
   * For a complex service, returns the zones of its basic services.
   * ### Claim
   *  boservice.tariff.listtariffzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffZoneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffZoneGet$Plain(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListTariffZoneResponse>> {

    return this.apiTariffListTariffZoneGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListTariffZoneResponse>>) => r.body as Array<TariffEditorApiContractListTariffZoneResponse>)
    );
  }

  /**
   * Getting a list of zones.
   *
   * Getting the list of zones for the tariff default zone.
   * For a complex service, returns the zones of its basic services.
   * ### Claim
   *  boservice.tariff.listtariffzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListTariffZoneGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffZoneGet$Response(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListTariffZoneResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TariffService.ApiTariffListTariffZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListTariffZoneResponse>>;
      })
    );
  }

  /**
   * Getting a list of zones.
   *
   * Getting the list of zones for the tariff default zone.
   * For a complex service, returns the zones of its basic services.
   * ### Claim
   *  boservice.tariff.listtariffzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListTariffZoneGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListTariffZoneGet(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListTariffZoneResponse>> {

    return this.apiTariffListTariffZoneGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListTariffZoneResponse>>) => r.body as Array<TariffEditorApiContractListTariffZoneResponse>)
    );
  }

}
