import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SettingsDesignLogoModel } from '@libs/settings/settings.model';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { RouteEntity } from '@core/router/route.entity';

/**
 * Logo
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        RouterLink,
        NgOptimizedImage,
    ],
})
export class LogoComponent {
    /**
     * Settings instance
     */
    @Input() logo: SettingsDesignLogoModel;
    /**
     * Minimal view flag
     * For footer logo variant
     */
    @Input() minimal: boolean;

    public readonly routeEntity: typeof RouteEntity = RouteEntity;
}
