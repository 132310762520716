import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAppComponent } from './common-app.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { CookieMessageModule } from '@ui/cookie-message/cookie-message.module';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenModule } from '@core/auth/token/token.module';
import { DecoratorInjectModule } from '@libs/decorator-inject/decorator-inject.module';
import { ToastrModule } from 'ngx-toastr';
import { DefaultPushConfig } from '@core/push/push.config';
import { CookiePolicyTextModule } from '@ui/cookie-policy-text/cookie-policy-text.module';
import { BsDatePickerDeps, DomDeps, startupAppConfigs, startupDeps } from '@core/app/app.module.helpers';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { LocalizationModule } from '@core/localization/localization.module';
import { ApiModule } from '@api/api.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopupsModule } from '@popups/popups.module';
import { StartPreloaderModule } from '@ui/start-preloader/start-preloader.module';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';


@NgModule({
    declarations: [CommonAppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        HttpClientModule,
        CookieMessageModule,
        PreLoaderModule,
        BrowserAnimationsModule,
        TokenModule,
        DecoratorInjectModule,
        LocalizationModule,
        CookiePolicyTextModule,
        NgxWebstorageModule.forRoot(),
        ToastrModule.forRoot(DefaultPushConfig),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ApiModule,
        PopupsModule,
        StartPreloaderModule,
        NgProgressModule,
        NgProgressHttpModule,
    ],
    exports: [
        CommonAppComponent,
    ],
    providers: [
        ...DomDeps,
        ...BsDatePickerDeps,
        {
            provide: APP_INITIALIZER,
            useFactory: startupAppConfigs,
            deps: startupDeps,
            multi: true,
        },
    ],
})
export class CommonAppModule {
}
