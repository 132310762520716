import { Injector, NgModule } from '@angular/core';
import { UserState } from '@core/state/user.state';


@NgModule({
    providers: [UserState]
})
export class DecoratorInjectModule {
    public static injector: Injector;

    constructor(injector: Injector) {
        DecoratorInjectModule.injector = injector;
    }
}
