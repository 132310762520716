/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SetClientPhotoRequest as ClientSupportApiContractSetClientPhotoRequest } from '../models/ClientSupportApi/Contract/set-client-photo-request';

@Injectable({
  providedIn: 'root',
})
export class PhotoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportClientsIdPhotoPost
   */
  static readonly ApiSupportClientsIdPhotoPostPath = '/api/Support/clients/{id}/photo';

  /**
   * Add/update photo.
   *
   * ### Claim
   *  boservice.support.setclientphoto
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdPhotoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdPhotoPost$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;

    /**
     * Photo data
     */
    body?: ClientSupportApiContractSetClientPhotoRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PhotoService.ApiSupportClientsIdPhotoPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add/update photo.
   *
   * ### Claim
   *  boservice.support.setclientphoto
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdPhotoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdPhotoPost(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;

    /**
     * Photo data
     */
    body?: ClientSupportApiContractSetClientPhotoRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportClientsIdPhotoPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdPhotoDelete
   */
  static readonly ApiSupportClientsIdPhotoDeletePath = '/api/Support/clients/{id}/photo';

  /**
   * Delete customer photo.
   *
   * ### Claim
   *  boservice.support.removeclientphoto
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdPhotoDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdPhotoDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PhotoService.ApiSupportClientsIdPhotoDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete customer photo.
   *
   * ### Claim
   *  boservice.support.removeclientphoto
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdPhotoDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdPhotoDelete(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportClientsIdPhotoDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
