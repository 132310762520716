/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApElementActListResponse as BannerApiContractApElementActListResponse } from '../models/BannerApi/Contract/ap-element-act-list-response';
import { ApElementActSetRequest as BannerApiContractApElementActSetRequest } from '../models/BannerApi/Contract/ap-element-act-set-request';
import { ApElementAddResponse as BannerApiContractApElementAddResponse } from '../models/BannerApi/Contract/ap-element-add-response';
import { ApElementListResponse as BannerApiContractApElementListResponse } from '../models/BannerApi/Contract/ap-element-list-response';
import { ApElementSetResponse as BannerApiContractApElementSetResponse } from '../models/BannerApi/Contract/ap-element-set-response';
import { ApVersionAddRequest as BannerApiContractApVersionAddRequest } from '../models/BannerApi/Contract/ap-version-add-request';
import { ApVersionAddResponse as BannerApiContractApVersionAddResponse } from '../models/BannerApi/Contract/ap-version-add-response';
import { ApVersionListResponse as BannerApiContractApVersionListResponse } from '../models/BannerApi/Contract/ap-version-list-response';
import { ApVersionSetRequest as BannerApiContractApVersionSetRequest } from '../models/BannerApi/Contract/ap-version-set-request';

@Injectable({
  providedIn: 'root',
})
export class DesignElementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiBannerApVersionListGet
   */
  static readonly ApiBannerApVersionListGetPath = '/api/Banner/ApVersionList';

  /**
   * Getting a list of design element versions.
   *
   * ### Claim
   *  boservice.banner.apversionlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApVersionListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApVersionListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * attribute of archive records: 0/null – do not show 1 – show
     */
    arch?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BannerApiContractApVersionListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApVersionListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('arch', params.arch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BannerApiContractApVersionListResponse>>;
      })
    );
  }

  /**
   * Getting a list of design element versions.
   *
   * ### Claim
   *  boservice.banner.apversionlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApVersionListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApVersionListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * attribute of archive records: 0/null – do not show 1 – show
     */
    arch?: number;
  },
  context?: HttpContext

): Observable<Array<BannerApiContractApVersionListResponse>> {

    return this.apiBannerApVersionListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BannerApiContractApVersionListResponse>>) => r.body as Array<BannerApiContractApVersionListResponse>)
    );
  }

  /**
   * Path part for operation apiBannerApVersionAddPost
   */
  static readonly ApiBannerApVersionAddPostPath = '/api/Banner/ApVersionAdd';

  /**
   * Adding a new (planned) version of design elements.
   *
   * Adding a new (planned) version of design elements. All data is copied from
   * current version (if any)
   * ### Claim
   *  boservice.banner.apversionadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApVersionAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBannerApVersionAddPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BannerApiContractApVersionAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BannerApiContractApVersionAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApVersionAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BannerApiContractApVersionAddResponse>;
      })
    );
  }

  /**
   * Adding a new (planned) version of design elements.
   *
   * Adding a new (planned) version of design elements. All data is copied from
   * current version (if any)
   * ### Claim
   *  boservice.banner.apversionadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApVersionAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBannerApVersionAddPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BannerApiContractApVersionAddRequest
  },
  context?: HttpContext

): Observable<BannerApiContractApVersionAddResponse> {

    return this.apiBannerApVersionAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BannerApiContractApVersionAddResponse>) => r.body as BannerApiContractApVersionAddResponse)
    );
  }

  /**
   * Path part for operation apiBannerApVersionDeleteDelete
   */
  static readonly ApiBannerApVersionDeleteDeletePath = '/api/Banner/ApVersionDelete';

  /**
   * Deleting a Design Element Version.
   *
   * Delete version of design elements. Only the planned version can be deleted.
   * ### Claim
   *  boservice.banner.apversiondelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApVersionDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApVersionDeleteDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version id
     */
    apvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApVersionDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('apvId', params.apvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a Design Element Version.
   *
   * Delete version of design elements. Only the planned version can be deleted.
   * ### Claim
   *  boservice.banner.apversiondelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApVersionDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApVersionDeleteDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version id
     */
    apvId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiBannerApVersionDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiBannerApVersionSetPost
   */
  static readonly ApiBannerApVersionSetPostPath = '/api/Banner/ApVersionSet';

  /**
   * Modifying design element version data.
   *
   * ### Claim
   *  boservice.banner.apversionset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApVersionSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBannerApVersionSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BannerApiContractApVersionSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApVersionSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Modifying design element version data.
   *
   * ### Claim
   *  boservice.banner.apversionset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApVersionSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBannerApVersionSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BannerApiContractApVersionSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiBannerApVersionSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiBannerApElementListGet
   */
  static readonly ApiBannerApElementListGetPath = '/api/Banner/ApElementList';

  /**
   * Getting a list of design elements for a specified version.
   *
   * ### Claim
   *  boservice.banner.apelementlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApElementListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApElementListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version id
     */
    apvId?: number;

    /**
     * element type/null see document system_references.docx
     */
    elType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BannerApiContractApElementListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApElementListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('apvId', params.apvId, {});
      rb.query('elType', params.elType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BannerApiContractApElementListResponse>>;
      })
    );
  }

  /**
   * Getting a list of design elements for a specified version.
   *
   * ### Claim
   *  boservice.banner.apelementlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApElementListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApElementListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version id
     */
    apvId?: number;

    /**
     * element type/null see document system_references.docx
     */
    elType?: number;
  },
  context?: HttpContext

): Observable<Array<BannerApiContractApElementListResponse>> {

    return this.apiBannerApElementListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BannerApiContractApElementListResponse>>) => r.body as Array<BannerApiContractApElementListResponse>)
    );
  }

  /**
   * Path part for operation apiBannerApElementAddPost
   */
  static readonly ApiBannerApElementAddPostPath = '/api/Banner/ApElementAdd';

  /**
   * Adding a new design element.
   *
   * 1: Images for the ticket
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size: no more than 200x200 px, horizontal format, height to width ratio = from 2 to 3 to 1 to 2.
   * can be rectangular
   * 2: Background image for full ticket form (view from history)
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size no more than 300x300 px, horizontal format, with proportions height to width = 2 to 3.
   * 3: Dynamic Image ("Live Ticket")
   * File Type: animated GIF.
   * File size: no more than 100KB.
   * Image size: 320x200 px.
   * ### Claim
   *  boservice.banner.apelementadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApElementAddPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerApElementAddPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: {
'file'?: Blob;
'ApvId'?: number;
'ElType'?: number;
'Note'?: string;
'EdData'?: string;
'EdType'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BannerApiContractApElementAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApElementAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BannerApiContractApElementAddResponse>;
      })
    );
  }

  /**
   * Adding a new design element.
   *
   * 1: Images for the ticket
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size: no more than 200x200 px, horizontal format, height to width ratio = from 2 to 3 to 1 to 2.
   * can be rectangular
   * 2: Background image for full ticket form (view from history)
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size no more than 300x300 px, horizontal format, with proportions height to width = 2 to 3.
   * 3: Dynamic Image ("Live Ticket")
   * File Type: animated GIF.
   * File size: no more than 100KB.
   * Image size: 320x200 px.
   * ### Claim
   *  boservice.banner.apelementadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApElementAddPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerApElementAddPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: {
'file'?: Blob;
'ApvId'?: number;
'ElType'?: number;
'Note'?: string;
'EdData'?: string;
'EdType'?: string;
}
  },
  context?: HttpContext

): Observable<BannerApiContractApElementAddResponse> {

    return this.apiBannerApElementAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BannerApiContractApElementAddResponse>) => r.body as BannerApiContractApElementAddResponse)
    );
  }

  /**
   * Path part for operation apiBannerApElementSetPost
   */
  static readonly ApiBannerApElementSetPostPath = '/api/Banner/ApElementSet';

  /**
   * Changing the data of a design element.
   *
   * 1: Images for the ticket
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size: no more than 200x200 px, horizontal format, height to width ratio = from 2 to 3 to 1 to 2.
   * can be rectangular
   * 2: Background image for full ticket form (view from history)
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size no more than 300x300 px, horizontal format, with proportions height to width = 2 to 3.
   * 3: Dynamic Image ("Live Ticket")
   * File Type: animated GIF.
   * File size: no more than 100KB.
   * Image size: 320x200 px.
   * ### Claim
   *  boservice.banner.apelementset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApElementSetPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerApElementSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: {
'file'?: Blob;
'ApvId'?: number;
'EdId'?: number;
'EdData'?: string;
'EdType'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BannerApiContractApElementSetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApElementSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BannerApiContractApElementSetResponse>;
      })
    );
  }

  /**
   * Changing the data of a design element.
   *
   * 1: Images for the ticket
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size: no more than 200x200 px, horizontal format, height to width ratio = from 2 to 3 to 1 to 2.
   * can be rectangular
   * 2: Background image for full ticket form (view from history)
   * File Type: JPG, PNG, SVG.
   * File size: no more than 300KB.
   * Image size no more than 300x300 px, horizontal format, with proportions height to width = 2 to 3.
   * 3: Dynamic Image ("Live Ticket")
   * File Type: animated GIF.
   * File size: no more than 100KB.
   * Image size: 320x200 px.
   * ### Claim
   *  boservice.banner.apelementset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApElementSetPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiBannerApElementSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: {
'file'?: Blob;
'ApvId'?: number;
'EdId'?: number;
'EdData'?: string;
'EdType'?: string;
}
  },
  context?: HttpContext

): Observable<BannerApiContractApElementSetResponse> {

    return this.apiBannerApElementSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BannerApiContractApElementSetResponse>) => r.body as BannerApiContractApElementSetResponse)
    );
  }

  /**
   * Path part for operation apiBannerApElementDeleteDelete
   */
  static readonly ApiBannerApElementDeleteDeletePath = '/api/Banner/ApElementDelete';

  /**
   * Removing Design Element Data from a Version.
   *
   * Removing design element data from a version. If the element is not used in other
   * versions, it is marked as deleted
   * ### Claim
   *  boservice.banner.apelementdelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApElementDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApElementDeleteDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version id
     */
    apvId?: number;

    /**
     * * element data id
     */
    edId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApElementDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('apvId', params.apvId, {});
      rb.query('edId', params.edId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing Design Element Data from a Version.
   *
   * Removing design element data from a version. If the element is not used in other
   * versions, it is marked as deleted
   * ### Claim
   *  boservice.banner.apelementdelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApElementDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApElementDeleteDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * version id
     */
    apvId?: number;

    /**
     * * element data id
     */
    edId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiBannerApElementDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiBannerApElementActListGet
   */
  static readonly ApiBannerApElementActListGetPath = '/api/Banner/ApElementActList';

  /**
   * Getting a list of active design elements.
   *
   * Getting a list of active design elements.
   * The data is returned from the current version. These data are supplemented with records of new
   * elements from the planned version.
   * Used for selection in tariffs.
   * ### Claim
   *  boservice.banner.apelementactlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApElementActListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApElementActListGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * element type/null see document "system directories"
     */
    elType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BannerApiContractApElementActListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApElementActListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('elType', params.elType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BannerApiContractApElementActListResponse>>;
      })
    );
  }

  /**
   * Getting a list of active design elements.
   *
   * Getting a list of active design elements.
   * The data is returned from the current version. These data are supplemented with records of new
   * elements from the planned version.
   * Used for selection in tariffs.
   * ### Claim
   *  boservice.banner.apelementactlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApElementActListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBannerApElementActListGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * element type/null see document "system directories"
     */
    elType?: number;
  },
  context?: HttpContext

): Observable<Array<BannerApiContractApElementActListResponse>> {

    return this.apiBannerApElementActListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BannerApiContractApElementActListResponse>>) => r.body as Array<BannerApiContractApElementActListResponse>)
    );
  }

  /**
   * Path part for operation apiBannerApElementActSetPost
   */
  static readonly ApiBannerApElementActSetPostPath = '/api/Banner/ApElementActSet';

  /**
   * Changing the activity flag of a design element.
   *
   * ### Claim
   *  boservice.banner.apelementactset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBannerApElementActSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBannerApElementActSetPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BannerApiContractApElementActSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DesignElementsService.ApiBannerApElementActSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changing the activity flag of a design element.
   *
   * ### Claim
   *  boservice.banner.apelementactset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiBannerApElementActSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBannerApElementActSetPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: BannerApiContractApElementActSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiBannerApElementActSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
