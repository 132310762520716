import { HttpBackend } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DEFAULT_LANG } from '@libs/constants';
import { SettingsDesignModel, SettingsModel, SettingsService } from '@libs/settings';
import { LocalDateAdapter } from '@services/local-date-adapter/local-date-adapter';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, enGbLocale, esLocale, huLocale, ruLocale } from 'ngx-bootstrap/chronos';
import { SeoService } from '@services/seo/seo.service';
import { UserState } from '@core/state/user.state';
import { LanguageState } from '@core/localization/language.state';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpTranslateLoaderFactory = (_httpBackend: HttpBackend) =>
    new MultiTranslateHttpLoader(_httpBackend, [
        './assets/i18n-shared/',
        './assets/i18n/',
    ]);

export const startupAppConfigs =
    (
        languageState: LanguageState,
        settingsService: SettingsService,
        userState: UserState,
        seo: SeoService,
    ) =>
        () => {
            languageState.requestLanguages();
            userState.requestMe();
        };

export interface WindowExt extends Window {
    appSettings?: SettingsModel;
    appDesignSettings?: SettingsDesignModel;
}

export const windowFactory = (): WindowExt => window;

export const documentFactory = () => document;

export const startupDeps = [
    LanguageState,
    SettingsService,
    UserState,
    SeoService,
];

export const getDatepickerConfig = (
    dateFormatService: LocalDateAdapter,
    localeService: BsLocaleService,
    languageState: LanguageState,
): BsDatepickerConfig => {
    defineLocale('ru', ruLocale);
    defineLocale('es', esLocale);
    defineLocale('en', enGbLocale);
    defineLocale('hu', huLocale);

    const calendarLang: string[] = ['ru-ru', 'en-gb', 'es-es', 'hu-hu'];
    localeService.use(
        calendarLang.indexOf(languageState.currentLang) > -1
            ? languageState.currentLang.split('-')[0]
            : DEFAULT_LANG.split('-')[0],
    );

    return Object.assign(new BsDatepickerConfig(), {
        adaptivePosition: true,
        dateInputFormat: dateFormatService.format.bsDatePicker,
    });
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BsDatePickerDeps = [
    {
        provide: BsDatepickerConfig,
        useFactory: getDatepickerConfig,
        deps: [LocalDateAdapter, BsLocaleService, LanguageState],
    },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DomDeps = [
    {
        provide: 'document',
        useFactory: documentFactory,
    },
    {
        provide: 'window',
        useFactory: windowFactory,
    },
];
