import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsDesignLogoModel } from '@libs/settings/settings.model';
import { SettingsService } from '@libs/settings/settings.service';
import { AppState } from '@core/state/app.state';
import { Module } from '@api/models/UserService/Contract/module';
import { MobileMenuComponent } from '@ui/mobile-menu/mobile-menu.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { LogoComponent } from '@ui/logo/logo.component';
import { MenuApplicationComponent } from '@ui/menu-application/menu-application.component';
import { UserCardComponent } from '@ui/user-card/user-card.component';
import { LangCheckerComponent } from '@ui/lang-checker/lang-checker.component';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';
import { UserState } from '@core/state/user.state';
import { Language } from '@api/models/Postgres/Model/language';
import { LanguageState } from '@core/localization/language.state';
import { AvailableLanguage } from '@core/localization/available-language';
import { map } from 'rxjs/operators';


/**
 * Header
 */
@Component({
    selector: 'ui-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        MobileMenuComponent,
        NgIf,
        AsyncPipe,
        LogoComponent,
        MenuApplicationComponent,
        UserCardComponent,
        LangCheckerComponent,
    ],
})
export class HeaderComponent {
    readonly #appState: AppState = inject(AppState);
    readonly #userState: UserState = inject(UserState);
    readonly #languageState: LanguageState = inject(LanguageState);
    /**
     * Logo header
     */
    public readonly logo: SettingsDesignLogoModel =
        this.settingsService.settings?.logo?.srcHeader;
    /**
     * User data
     */
    public readonly user: Observable<LoginResponse> = this.#userState.user$;
    /**
     * List menu items
     */
    public readonly menuItems: Observable<Module[]> = this.#appState.modules$;
    /**
     * List languages
     */
    public readonly languages$: Observable<Language[]> = this.#languageState.languages$;
    /**
     * Selected lang code
     */
    public readonly currentLang$: Observable<string> = this.#languageState.language$.pipe(
        map((lang: AvailableLanguage) => lang.toUpperCase()),
    );

    constructor(
        private settingsService: SettingsService,
    ) {
    }

    public handlerChangeLang(language: Language): void {
        this.#languageState.setCurrentLanguage(language);
        window.location.reload();
    }
}
