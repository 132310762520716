/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DashboardAddRequest as AnalyticContractDashboardDashboardAddRequest } from '../models/Analytic/Contract/Dashboard/dashboard-add-request';
import { DashboardAddResponse as AnalyticContractDashboardDashboardAddResponse } from '../models/Analytic/Contract/Dashboard/dashboard-add-response';
import { DashboardCopyAddRequest as AnalyticContractDashboardDashboardCopyAddRequest } from '../models/Analytic/Contract/Dashboard/dashboard-copy-add-request';
import { DashboardCopyAddResponse as AnalyticContractDashboardDashboardCopyAddResponse } from '../models/Analytic/Contract/Dashboard/dashboard-copy-add-response';
import { DashboardFolderAddRequest as AnalyticContractDashboardDashboardFolderAddRequest } from '../models/Analytic/Contract/Dashboard/dashboard-folder-add-request';
import { DashboardFolderAddResponse as AnalyticContractDashboardDashboardFolderAddResponse } from '../models/Analytic/Contract/Dashboard/dashboard-folder-add-response';
import { DashboardFolderGetResponse as AnalyticContractDashboardDashboardFolderGetResponse } from '../models/Analytic/Contract/Dashboard/dashboard-folder-get-response';
import { DashboardFolderListResponse as AnalyticContractDashboardDashboardFolderListResponse } from '../models/Analytic/Contract/Dashboard/dashboard-folder-list-response';
import { DashboardFolderSetRequest as AnalyticContractDashboardDashboardFolderSetRequest } from '../models/Analytic/Contract/Dashboard/dashboard-folder-set-request';
import { DashboardGetResponse as AnalyticContractDashboardDashboardGetResponse } from '../models/Analytic/Contract/Dashboard/dashboard-get-response';
import { DashboardListResponse as AnalyticContractDashboardDashboardListResponse } from '../models/Analytic/Contract/Dashboard/dashboard-list-response';
import { DashboardMenuListResponse as AnalyticContractDashboardDashboardMenuListResponse } from '../models/Analytic/Contract/Dashboard/dashboard-menu-list-response';
import { DashboardRoleListResponse as AnalyticContractDashboardDashboardRoleListResponse } from '../models/Analytic/Contract/Dashboard/dashboard-role-list-response';
import { DashboardSetRequest as AnalyticContractDashboardDashboardSetRequest } from '../models/Analytic/Contract/Dashboard/dashboard-set-request';
import { DashboardXmlGetResponse as AnalyticContractDashboardDashboardXmlGetResponse } from '../models/Analytic/Contract/Dashboard/dashboard-xml-get-response';
import { DashboardXmlSetRequest as AnalyticContractDashboardDashboardXmlSetRequest } from '../models/Analytic/Contract/Dashboard/dashboard-xml-set-request';
import { DashboardInfoGetResponse as AnalyticContractDashboardsDashboardInfoGetResponse } from '../models/Analytic/Contract/Dashboards/dashboard-info-get-response';
import { DashboardResSetRequest as AnalyticContractDashboardsDashboardResSetRequest } from '../models/Analytic/Contract/Dashboards/dashboard-res-set-request';
import { LoadDataRequest as AnalyticContractLoadDataRequest } from '../models/Analytic/Contract/load-data-request';
import { LoadDataResponse as AnalyticContractLoadDataResponse } from '../models/Analytic/Contract/load-data-response';
import { ReportAddRequest as AnalyticContractReportsReportAddRequest } from '../models/Analytic/Contract/Reports/report-add-request';
import { ReportAddResponse as AnalyticContractReportsReportAddResponse } from '../models/Analytic/Contract/Reports/report-add-response';
import { ReportCopyAddRequest as AnalyticContractReportsReportCopyAddRequest } from '../models/Analytic/Contract/Reports/report-copy-add-request';
import { ReportCopyAddResponse as AnalyticContractReportsReportCopyAddResponse } from '../models/Analytic/Contract/Reports/report-copy-add-response';
import { ReportFolderAddRequest as AnalyticContractReportsReportFolderAddRequest } from '../models/Analytic/Contract/Reports/report-folder-add-request';
import { ReportFolderAddResponse as AnalyticContractReportsReportFolderAddResponse } from '../models/Analytic/Contract/Reports/report-folder-add-response';
import { ReportFolderGetResponse as AnalyticContractReportsReportFolderGetResponse } from '../models/Analytic/Contract/Reports/report-folder-get-response';
import { ReportFolderListResponse as AnalyticContractReportsReportFolderListResponse } from '../models/Analytic/Contract/Reports/report-folder-list-response';
import { ReportFolderSetRequest as AnalyticContractReportsReportFolderSetRequest } from '../models/Analytic/Contract/Reports/report-folder-set-request';
import { ReportGetResponse as AnalyticContractReportsReportGetResponse } from '../models/Analytic/Contract/Reports/report-get-response';
import { ReportInfoGetResponse as AnalyticContractReportsReportInfoGetResponse } from '../models/Analytic/Contract/Reports/report-info-get-response';
import { ReportListResponse as AnalyticContractReportsReportListResponse } from '../models/Analytic/Contract/Reports/report-list-response';
import { ReportMenuListResponse as AnalyticContractReportsReportMenuListResponse } from '../models/Analytic/Contract/Reports/report-menu-list-response';
import { ReportRoleListResponse as AnalyticContractReportsReportRoleListResponse } from '../models/Analytic/Contract/Reports/report-role-list-response';
import { ReportSetRequest as AnalyticContractReportsReportSetRequest } from '../models/Analytic/Contract/Reports/report-set-request';
import { ReportXmlGetResponse as AnalyticContractReportsReportXmlGetResponse } from '../models/Analytic/Contract/Reports/report-xml-get-response';
import { ReportXmlSetRequest as AnalyticContractReportsReportXmlSetRequest } from '../models/Analytic/Contract/Reports/report-xml-set-request';

@Injectable({
  providedIn: 'root',
})
export class AnalyticApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAnalyticApiLoadDataPost
   */
  static readonly ApiAnalyticApiLoadDataPostPath = '/api/AnalyticApi/LoadData';

  /**
   * ### Claim
   *  boservice.analyticapi.load
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiLoadDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiLoadDataPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractLoadDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractLoadDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiLoadDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractLoadDataResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.load
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiLoadDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiLoadDataPost(params?: {
    lang?: string;
    body?: AnalyticContractLoadDataRequest
  },
  context?: HttpContext

): Observable<AnalyticContractLoadDataResponse> {

    return this.apiAnalyticApiLoadDataPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractLoadDataResponse>) => r.body as AnalyticContractLoadDataResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportMenuListGet
   */
  static readonly ApiAnalyticApiReportMenuListGetPath = '/api/AnalyticApi/ReportMenuList';

  /**
   * ### Claim
   *  boservice.analyticapi.reportmenulist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportMenuListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportMenuListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractReportsReportMenuListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportMenuListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractReportsReportMenuListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportmenulist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportMenuListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportMenuListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractReportsReportMenuListResponse>> {

    return this.apiAnalyticApiReportMenuListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractReportsReportMenuListResponse>>) => r.body as Array<AnalyticContractReportsReportMenuListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportXmlGetGet
   */
  static readonly ApiAnalyticApiReportXmlGetGetPath = '/api/AnalyticApi/ReportXmlGet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportxmlget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportXmlGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportXmlGetGet$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportXmlGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportXmlGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportXmlGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportxmlget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportXmlGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportXmlGetGet(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportXmlGetResponse> {

    return this.apiAnalyticApiReportXmlGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportXmlGetResponse>) => r.body as AnalyticContractReportsReportXmlGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportFolderListGet
   */
  static readonly ApiAnalyticApiReportFolderListGetPath = '/api/AnalyticApi/ReportFolderList';

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportFolderListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportFolderListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractReportsReportFolderListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportFolderListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractReportsReportFolderListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportFolderListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportFolderListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractReportsReportFolderListResponse>> {

    return this.apiAnalyticApiReportFolderListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractReportsReportFolderListResponse>>) => r.body as Array<AnalyticContractReportsReportFolderListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportFolderGetGet
   */
  static readonly ApiAnalyticApiReportFolderGetGetPath = '/api/AnalyticApi/ReportFolderGet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportFolderGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportFolderGetGet$Response(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportFolderGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportFolderGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('folderId', params.folderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportFolderGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportFolderGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportFolderGetGet(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportFolderGetResponse> {

    return this.apiAnalyticApiReportFolderGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportFolderGetResponse>) => r.body as AnalyticContractReportsReportFolderGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportFolderAddPost
   */
  static readonly ApiAnalyticApiReportFolderAddPostPath = '/api/AnalyticApi/ReportFolderAdd';

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportFolderAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportFolderAddPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportFolderAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportFolderAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportFolderAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportFolderAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportFolderAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportFolderAddPost(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportFolderAddRequest
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportFolderAddResponse> {

    return this.apiAnalyticApiReportFolderAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportFolderAddResponse>) => r.body as AnalyticContractReportsReportFolderAddResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportFolderSetPost
   */
  static readonly ApiAnalyticApiReportFolderSetPostPath = '/api/AnalyticApi/ReportFolderSet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportFolderSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportFolderSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportFolderSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportFolderSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportFolderSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportFolderSetPost(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportFolderSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiReportFolderSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportFolderDeleteDelete
   */
  static readonly ApiAnalyticApiReportFolderDeleteDeletePath = '/api/AnalyticApi/ReportFolderDelete';

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderdelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportFolderDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportFolderDeleteDelete$Response(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportFolderDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('folderId', params.folderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportfolderdelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportFolderDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportFolderDeleteDelete(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiReportFolderDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportListGet
   */
  static readonly ApiAnalyticApiReportListGetPath = '/api/AnalyticApi/ReportList';

  /**
   * ### Claim
   *  boservice.analyticapi.reportlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractReportsReportListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractReportsReportListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractReportsReportListResponse>> {

    return this.apiAnalyticApiReportListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractReportsReportListResponse>>) => r.body as Array<AnalyticContractReportsReportListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportGetGet
   */
  static readonly ApiAnalyticApiReportGetGetPath = '/api/AnalyticApi/ReportGet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportGetGet$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportGetGet(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportGetResponse> {

    return this.apiAnalyticApiReportGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportGetResponse>) => r.body as AnalyticContractReportsReportGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportSetPost
   */
  static readonly ApiAnalyticApiReportSetPostPath = '/api/AnalyticApi/ReportSet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportSetPost(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiReportSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportAddPost
   */
  static readonly ApiAnalyticApiReportAddPostPath = '/api/AnalyticApi/ReportAdd';

  /**
   * ### Claim
   *  boservice.analyticapi.reportadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportAddPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportAddPost(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportAddRequest
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportAddResponse> {

    return this.apiAnalyticApiReportAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportAddResponse>) => r.body as AnalyticContractReportsReportAddResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportDeleteDelete
   */
  static readonly ApiAnalyticApiReportDeleteDeletePath = '/api/AnalyticApi/ReportDelete';

  /**
   * ### Claim
   *  boservice.analyticapi.reportdelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportDeleteDelete$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportdelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportDeleteDelete(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiReportDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportXmlSetPost
   */
  static readonly ApiAnalyticApiReportXmlSetPostPath = '/api/AnalyticApi/ReportXmlSet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportxmlset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportXmlSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportXmlSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportXmlSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportXmlSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportxmlset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportXmlSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportXmlSetPost(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportXmlSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiReportXmlSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportRoleListGet
   */
  static readonly ApiAnalyticApiReportRoleListGetPath = '/api/AnalyticApi/ReportRoleList';

  /**
   * ### Claim
   *  boservice.analyticapi.reportrolelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportRoleListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportRoleListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractReportsReportRoleListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportRoleListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractReportsReportRoleListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportrolelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportRoleListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportRoleListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractReportsReportRoleListResponse>> {

    return this.apiAnalyticApiReportRoleListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractReportsReportRoleListResponse>>) => r.body as Array<AnalyticContractReportsReportRoleListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportCopyAddPost
   */
  static readonly ApiAnalyticApiReportCopyAddPostPath = '/api/AnalyticApi/ReportCopyAdd';

  /**
   * ### Claim
   *  boservice.analyticapi.reportcopyadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportCopyAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportCopyAddPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportCopyAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportCopyAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportCopyAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportCopyAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportcopyadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportCopyAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiReportCopyAddPost(params?: {
    lang?: string;
    body?: AnalyticContractReportsReportCopyAddRequest
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportCopyAddResponse> {

    return this.apiAnalyticApiReportCopyAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportCopyAddResponse>) => r.body as AnalyticContractReportsReportCopyAddResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiReportInfoGetGet
   */
  static readonly ApiAnalyticApiReportInfoGetGetPath = '/api/AnalyticApi/ReportInfoGet';

  /**
   * ### Claim
   *  boservice.analyticapi.reportinfoget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiReportInfoGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportInfoGetGet$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractReportsReportInfoGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiReportInfoGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractReportsReportInfoGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.reportinfoget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiReportInfoGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiReportInfoGetGet(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<AnalyticContractReportsReportInfoGetResponse> {

    return this.apiAnalyticApiReportInfoGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractReportsReportInfoGetResponse>) => r.body as AnalyticContractReportsReportInfoGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardMenuListGet
   */
  static readonly ApiAnalyticApiDashboardMenuListGetPath = '/api/AnalyticApi/DashboardMenuList';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardmenulist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardMenuListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardMenuListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractDashboardDashboardMenuListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardMenuListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractDashboardDashboardMenuListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardmenulist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardMenuListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardMenuListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractDashboardDashboardMenuListResponse>> {

    return this.apiAnalyticApiDashboardMenuListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractDashboardDashboardMenuListResponse>>) => r.body as Array<AnalyticContractDashboardDashboardMenuListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardXmlGetGet
   */
  static readonly ApiAnalyticApiDashboardXmlGetGetPath = '/api/AnalyticApi/DashboardXmlGet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardxmlget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardXmlGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardXmlGetGet$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardDashboardXmlGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardXmlGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardDashboardXmlGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardxmlget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardXmlGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardXmlGetGet(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardDashboardXmlGetResponse> {

    return this.apiAnalyticApiDashboardXmlGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardDashboardXmlGetResponse>) => r.body as AnalyticContractDashboardDashboardXmlGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardFolderListGet
   */
  static readonly ApiAnalyticApiDashboardFolderListGetPath = '/api/AnalyticApi/DashboardFolderList';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardFolderListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardFolderListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractDashboardDashboardFolderListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardFolderListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractDashboardDashboardFolderListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardFolderListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardFolderListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractDashboardDashboardFolderListResponse>> {

    return this.apiAnalyticApiDashboardFolderListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractDashboardDashboardFolderListResponse>>) => r.body as Array<AnalyticContractDashboardDashboardFolderListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardFolderGetGet
   */
  static readonly ApiAnalyticApiDashboardFolderGetGetPath = '/api/AnalyticApi/DashboardFolderGet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardFolderGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardFolderGetGet$Response(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardDashboardFolderGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardFolderGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('folderId', params.folderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardDashboardFolderGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardFolderGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardFolderGetGet(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardDashboardFolderGetResponse> {

    return this.apiAnalyticApiDashboardFolderGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardDashboardFolderGetResponse>) => r.body as AnalyticContractDashboardDashboardFolderGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardFolderAddPost
   */
  static readonly ApiAnalyticApiDashboardFolderAddPostPath = '/api/AnalyticApi/DashboardFolderAdd';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardFolderAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardFolderAddPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardFolderAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardDashboardFolderAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardFolderAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardDashboardFolderAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardFolderAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardFolderAddPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardFolderAddRequest
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardDashboardFolderAddResponse> {

    return this.apiAnalyticApiDashboardFolderAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardDashboardFolderAddResponse>) => r.body as AnalyticContractDashboardDashboardFolderAddResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardFolderSetPost
   */
  static readonly ApiAnalyticApiDashboardFolderSetPostPath = '/api/AnalyticApi/DashboardFolderSet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardFolderSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardFolderSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardFolderSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardFolderSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardFolderSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardFolderSetPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardFolderSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiDashboardFolderSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardFolderDeleteDelete
   */
  static readonly ApiAnalyticApiDashboardFolderDeleteDeletePath = '/api/AnalyticApi/DashboardFolderDelete';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderdelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardFolderDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardFolderDeleteDelete$Response(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardFolderDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('folderId', params.folderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardfolderdelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardFolderDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardFolderDeleteDelete(params?: {
    lang?: string;
    folderId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiDashboardFolderDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardListGet
   */
  static readonly ApiAnalyticApiDashboardListGetPath = '/api/AnalyticApi/DashboardList';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractDashboardDashboardListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractDashboardDashboardListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractDashboardDashboardListResponse>> {

    return this.apiAnalyticApiDashboardListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractDashboardDashboardListResponse>>) => r.body as Array<AnalyticContractDashboardDashboardListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardGetGet
   */
  static readonly ApiAnalyticApiDashboardGetGetPath = '/api/AnalyticApi/DashboardGet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardGetGet$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardDashboardGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardDashboardGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardGetGet(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardDashboardGetResponse> {

    return this.apiAnalyticApiDashboardGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardDashboardGetResponse>) => r.body as AnalyticContractDashboardDashboardGetResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardSetPost
   */
  static readonly ApiAnalyticApiDashboardSetPostPath = '/api/AnalyticApi/DashboardSet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardSetPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiDashboardSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardAddPost
   */
  static readonly ApiAnalyticApiDashboardAddPostPath = '/api/AnalyticApi/DashboardAdd';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardAddPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardDashboardAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardDashboardAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardAddPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardAddRequest
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardDashboardAddResponse> {

    return this.apiAnalyticApiDashboardAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardDashboardAddResponse>) => r.body as AnalyticContractDashboardDashboardAddResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardDeleteDelete
   */
  static readonly ApiAnalyticApiDashboardDeleteDeletePath = '/api/AnalyticApi/DashboardDelete';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboarddelete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardDeleteDelete$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardDeleteDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboarddelete
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardDeleteDelete(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiDashboardDeleteDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardXmlSetPost
   */
  static readonly ApiAnalyticApiDashboardXmlSetPostPath = '/api/AnalyticApi/DashboardXmlSet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardxmlset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardXmlSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardXmlSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardXmlSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardXmlSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardxmlset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardXmlSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardXmlSetPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardXmlSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiDashboardXmlSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardRoleListGet
   */
  static readonly ApiAnalyticApiDashboardRoleListGetPath = '/api/AnalyticApi/DashboardRoleList';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardrolelist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardRoleListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardRoleListGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticContractDashboardDashboardRoleListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardRoleListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticContractDashboardDashboardRoleListResponse>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardrolelist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardRoleListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardRoleListGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticContractDashboardDashboardRoleListResponse>> {

    return this.apiAnalyticApiDashboardRoleListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticContractDashboardDashboardRoleListResponse>>) => r.body as Array<AnalyticContractDashboardDashboardRoleListResponse>)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardCopyAddPost
   */
  static readonly ApiAnalyticApiDashboardCopyAddPostPath = '/api/AnalyticApi/DashboardCopyAdd';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardcopyadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardCopyAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardCopyAddPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardCopyAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardDashboardCopyAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardCopyAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardDashboardCopyAddResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardcopyadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardCopyAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardCopyAddPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardDashboardCopyAddRequest
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardDashboardCopyAddResponse> {

    return this.apiAnalyticApiDashboardCopyAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardDashboardCopyAddResponse>) => r.body as AnalyticContractDashboardDashboardCopyAddResponse)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardResSetPost
   */
  static readonly ApiAnalyticApiDashboardResSetPostPath = '/api/AnalyticApi/DashboardResSet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardresset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardResSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardResSetPost$Response(params?: {
    lang?: string;
    body?: AnalyticContractDashboardsDashboardResSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardResSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardresset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardResSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnalyticApiDashboardResSetPost(params?: {
    lang?: string;
    body?: AnalyticContractDashboardsDashboardResSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAnalyticApiDashboardResSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAnalyticApiDashboardInfoGetGet
   */
  static readonly ApiAnalyticApiDashboardInfoGetGetPath = '/api/AnalyticApi/DashboardInfoGet';

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardinfoget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnalyticApiDashboardInfoGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardInfoGetGet$Response(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnalyticContractDashboardsDashboardInfoGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AnalyticApiService.ApiAnalyticApiDashboardInfoGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnalyticContractDashboardsDashboardInfoGetResponse>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.analyticapi.dashboardinfoget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnalyticApiDashboardInfoGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAnalyticApiDashboardInfoGetGet(params?: {
    lang?: string;
    key?: string;
  },
  context?: HttpContext

): Observable<AnalyticContractDashboardsDashboardInfoGetResponse> {

    return this.apiAnalyticApiDashboardInfoGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnalyticContractDashboardsDashboardInfoGetResponse>) => r.body as AnalyticContractDashboardsDashboardInfoGetResponse)
    );
  }

}
