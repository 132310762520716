import { inject, Injectable } from '@angular/core';
import { DateFormatModel } from '@services/local-date-adapter/date-format.model';
import * as moment from 'moment';
import { AvailableLanguage } from '@core/localization/available-language';
import { LOCALIZATION_CONFIG, LocalizationConfig } from '@core/localization/localization.config';
import { get } from 'lodash-es';
import { LanguageState } from '@core/localization/language.state';


@Injectable({
    providedIn: 'root',
})
export class LocalDateAdapter {
    readonly #languageState: LanguageState = inject(LanguageState);
    readonly #localizationConfig: LocalizationConfig = inject(LOCALIZATION_CONFIG);

    public get format(): DateFormatModel {
        const langCode: AvailableLanguage =
            this.#languageState.currentLang.toLowerCase() as AvailableLanguage;

        return get(this.#localizationConfig.dateFormats, langCode)
            || get(this.#localizationConfig.dateFormats, this.#localizationConfig.defaultLocale);
    }

    public prepareDateTimeForDB(dateFrom: Date | string, timeFrom: Date | string): string {
        const m: moment.Moment = moment(dateFrom);
        return m.format('YYYY-MM-DD') + 'T' + moment(timeFrom).format('HH:mm:ss');
    }

    public prepareDateForDB(date: Date | string): string {
        const m: moment.Moment = moment(date);
        return m.format('YYYY-MM-DD');
    }
}
