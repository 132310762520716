<div class="container-fluid d-print-none bg-brand">
    <div class="row">
        <div class="col px-md-8">
            <header class="align-items-center flex-md-nowrap flex-row">
                <ui-mobile-menu class="me-3"
                                *ngIf="user | async"
                                [user]="user | async"
                                [menuItems]="menuItems | async">

                </ui-mobile-menu>

                <ui-logo [logo]="logo"></ui-logo>

                <ui-menu-application class="py-1"
                                     *ngIf="user | async"
                                     [menuItems]="menuItems | async">

                </ui-menu-application>

                <ui-user-card class="ms-auto" [user]="user | async"></ui-user-card>

                <ui-lang-checker class="ms-auto ms-lg-5"
                                 [selected]="currentLang$ | async"
                                 [languages]="languages$ | async"
                                 (changeLang)="handlerChangeLang($event)">

                </ui-lang-checker>
            </header>
        </div>
    </div>
</div>
