/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListUpchargeConditionResponse as TariffEditorApiContractListUpchargeConditionResponse } from '../models/TariffEditorApi/Contract/list-upcharge-condition-response';
import { ListUpchargeResponse as TariffEditorApiContractListUpchargeResponse } from '../models/TariffEditorApi/Contract/list-upcharge-response';
import { SetUpchargeRequest as TariffEditorApiContractSetUpchargeRequest } from '../models/TariffEditorApi/Contract/set-upcharge-request';

@Injectable({
  providedIn: 'root',
})
export class UpchargeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffListUpchargeGet
   */
  static readonly ApiTariffListUpchargeGetPath = '/api/Tariff/ListUpcharge';

  /**
   * Getting a table of upcharges.
   *
   * ### Claim
   *  boservice.tariff.listupcharge
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListUpchargeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeGet$Plain$Response(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * * bundled service id
     */
    srvIdCplx?: number;

    /**
     * tariff id/null for new tariff
     */
    trfId?: number;

    /**
     * start zone id (optional) /null
     */
    zoneIdFrom?: number;

    /**
     * end zone id (optional) / null
     */
    zoneIdTo?: number;

    /**
     * upcharge condition id. null or -1 for default rates.
     */
    ucId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListUpchargeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UpchargeService.ApiTariffListUpchargeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvIdCplx', params.srvIdCplx, {});
      rb.query('trfId', params.trfId, {});
      rb.query('zoneIdFrom', params.zoneIdFrom, {});
      rb.query('zoneIdTo', params.zoneIdTo, {});
      rb.query('ucId', params.ucId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListUpchargeResponse>>;
      })
    );
  }

  /**
   * Getting a table of upcharges.
   *
   * ### Claim
   *  boservice.tariff.listupcharge
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListUpchargeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeGet$Plain(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * * bundled service id
     */
    srvIdCplx?: number;

    /**
     * tariff id/null for new tariff
     */
    trfId?: number;

    /**
     * start zone id (optional) /null
     */
    zoneIdFrom?: number;

    /**
     * end zone id (optional) / null
     */
    zoneIdTo?: number;

    /**
     * upcharge condition id. null or -1 for default rates.
     */
    ucId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListUpchargeResponse>> {

    return this.apiTariffListUpchargeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListUpchargeResponse>>) => r.body as Array<TariffEditorApiContractListUpchargeResponse>)
    );
  }

  /**
   * Getting a table of upcharges.
   *
   * ### Claim
   *  boservice.tariff.listupcharge
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListUpchargeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeGet$Response(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * * bundled service id
     */
    srvIdCplx?: number;

    /**
     * tariff id/null for new tariff
     */
    trfId?: number;

    /**
     * start zone id (optional) /null
     */
    zoneIdFrom?: number;

    /**
     * end zone id (optional) / null
     */
    zoneIdTo?: number;

    /**
     * upcharge condition id. null or -1 for default rates.
     */
    ucId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListUpchargeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UpchargeService.ApiTariffListUpchargeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('date', params.date, {});
      rb.query('srvIdCplx', params.srvIdCplx, {});
      rb.query('trfId', params.trfId, {});
      rb.query('zoneIdFrom', params.zoneIdFrom, {});
      rb.query('zoneIdTo', params.zoneIdTo, {});
      rb.query('ucId', params.ucId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListUpchargeResponse>>;
      })
    );
  }

  /**
   * Getting a table of upcharges.
   *
   * ### Claim
   *  boservice.tariff.listupcharge
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListUpchargeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeGet(params?: {

    /**
     * * interface language
     */
    lang?: string;

    /**
     * * version datetime
     */
    date?: string;

    /**
     * * bundled service id
     */
    srvIdCplx?: number;

    /**
     * tariff id/null for new tariff
     */
    trfId?: number;

    /**
     * start zone id (optional) /null
     */
    zoneIdFrom?: number;

    /**
     * end zone id (optional) / null
     */
    zoneIdTo?: number;

    /**
     * upcharge condition id. null or -1 for default rates.
     */
    ucId?: number;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListUpchargeResponse>> {

    return this.apiTariffListUpchargeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListUpchargeResponse>>) => r.body as Array<TariffEditorApiContractListUpchargeResponse>)
    );
  }

  /**
   * Path part for operation apiTariffSetUpchargePost
   */
  static readonly ApiTariffSetUpchargePostPath = '/api/Tariff/SetUpcharge';

  /**
   * Editing the upcharge table.
   *
   * ### Claim
   *  boservice.tariff.setupcharge
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetUpchargePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetUpchargePost$Response(params?: {

    /**
     * * interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetUpchargeRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UpchargeService.ApiTariffSetUpchargePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing the upcharge table.
   *
   * ### Claim
   *  boservice.tariff.setupcharge
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetUpchargePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetUpchargePost(params?: {

    /**
     * * interface language
     */
    lang?: string;
    body?: TariffEditorApiContractSetUpchargeRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetUpchargePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffListUpchargeConditionGet
   */
  static readonly ApiTariffListUpchargeConditionGetPath = '/api/Tariff/ListUpchargeCondition';

  /**
   * List of upcharge conditions.
   *
   * List of all available upcharge conditions.
   * Always contents dummy record with is_default == 1 and uc_id == -1 for default upcharge rates.
   * ### Claim
   *  boservice.tariff.listupchargecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListUpchargeConditionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeConditionGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListUpchargeConditionResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UpchargeService.ApiTariffListUpchargeConditionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListUpchargeConditionResponse>>;
      })
    );
  }

  /**
   * List of upcharge conditions.
   *
   * List of all available upcharge conditions.
   * Always contents dummy record with is_default == 1 and uc_id == -1 for default upcharge rates.
   * ### Claim
   *  boservice.tariff.listupchargecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListUpchargeConditionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeConditionGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListUpchargeConditionResponse>> {

    return this.apiTariffListUpchargeConditionGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListUpchargeConditionResponse>>) => r.body as Array<TariffEditorApiContractListUpchargeConditionResponse>)
    );
  }

  /**
   * List of upcharge conditions.
   *
   * List of all available upcharge conditions.
   * Always contents dummy record with is_default == 1 and uc_id == -1 for default upcharge rates.
   * ### Claim
   *  boservice.tariff.listupchargecondition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListUpchargeConditionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeConditionGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListUpchargeConditionResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UpchargeService.ApiTariffListUpchargeConditionGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListUpchargeConditionResponse>>;
      })
    );
  }

  /**
   * List of upcharge conditions.
   *
   * List of all available upcharge conditions.
   * Always contents dummy record with is_default == 1 and uc_id == -1 for default upcharge rates.
   * ### Claim
   *  boservice.tariff.listupchargecondition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListUpchargeConditionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListUpchargeConditionGet(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListUpchargeConditionResponse>> {

    return this.apiTariffListUpchargeConditionGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListUpchargeConditionResponse>>) => r.body as Array<TariffEditorApiContractListUpchargeConditionResponse>)
    );
  }

}
