/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddAgentDeptRequest as AgentApiContractAgentDeptAddAgentDeptRequest } from '../models/AgentApi/Contract/AgentDept/add-agent-dept-request';
import { ListAgentDeptResponse as AgentApiContractAgentDeptListAgentDeptResponse } from '../models/AgentApi/Contract/AgentDept/list-agent-dept-response';
import { SetAgentDeptRequest as AgentApiContractAgentDeptSetAgentDeptRequest } from '../models/AgentApi/Contract/AgentDept/set-agent-dept-request';

@Injectable({
  providedIn: 'root',
})
export class AgentSitesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAgentListAgentDeptGet
   */
  static readonly ApiAgentListAgentDeptGetPath = '/api/Agent/ListAgentDept';

  /**
   * Getting a list of Agent sites.
   *
   * ### Claim
   *  boservice.agent.listagentdept
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListAgentDeptGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentDeptGet$Plain$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAgentDeptListAgentDeptResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentSitesService.ApiAgentListAgentDeptGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slrId', params.slrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAgentDeptListAgentDeptResponse>>;
      })
    );
  }

  /**
   * Getting a list of Agent sites.
   *
   * ### Claim
   *  boservice.agent.listagentdept
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListAgentDeptGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentDeptGet$Plain(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAgentDeptListAgentDeptResponse>> {

    return this.apiAgentListAgentDeptGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAgentDeptListAgentDeptResponse>>) => r.body as Array<AgentApiContractAgentDeptListAgentDeptResponse>)
    );
  }

  /**
   * Getting a list of Agent sites.
   *
   * ### Claim
   *  boservice.agent.listagentdept
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListAgentDeptGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentDeptGet$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAgentDeptListAgentDeptResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentSitesService.ApiAgentListAgentDeptGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slrId', params.slrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAgentDeptListAgentDeptResponse>>;
      })
    );
  }

  /**
   * Getting a list of Agent sites.
   *
   * ### Claim
   *  boservice.agent.listagentdept
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListAgentDeptGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentDeptGet(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAgentDeptListAgentDeptResponse>> {

    return this.apiAgentListAgentDeptGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAgentDeptListAgentDeptResponse>>) => r.body as Array<AgentApiContractAgentDeptListAgentDeptResponse>)
    );
  }

  /**
   * Path part for operation apiAgentAddAgentDeptPost
   */
  static readonly ApiAgentAddAgentDeptPostPath = '/api/Agent/AddAgentDept';

  /**
   * Adding an Agent site.
   *
   * ### Claim
   *  boservice.agent.addagentdept
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentAddAgentDeptPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentDeptPost$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: AgentApiContractAgentDeptAddAgentDeptRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentSitesService.ApiAgentAddAgentDeptPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adding an Agent site.
   *
   * ### Claim
   *  boservice.agent.addagentdept
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentAddAgentDeptPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentDeptPost(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: AgentApiContractAgentDeptAddAgentDeptRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentAddAgentDeptPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAgentSetAgentDeptPost
   */
  static readonly ApiAgentSetAgentDeptPostPath = '/api/Agent/SetAgentDept';

  /**
   * Editing the data of the Agent's site.
   *
   * ### Claim
   *  boservice.agent.setagentdept
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentSetAgentDeptPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentSetAgentDeptPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAgentDeptSetAgentDeptRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentSitesService.ApiAgentSetAgentDeptPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing the data of the Agent's site.
   *
   * ### Claim
   *  boservice.agent.setagentdept
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentSetAgentDeptPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentSetAgentDeptPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAgentDeptSetAgentDeptRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentSetAgentDeptPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAgentRemoveAgentDeptDelete
   */
  static readonly ApiAgentRemoveAgentDeptDeletePath = '/api/Agent/RemoveAgentDept';

  /**
   * Deleting an Agent site.
   *
   * ### Claim
   *  boservice.agent.removeagentdept
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentRemoveAgentDeptDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentRemoveAgentDeptDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * * id sites agent
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentSitesService.ApiAgentRemoveAgentDeptDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('orgId', params.orgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting an Agent site.
   *
   * ### Claim
   *  boservice.agent.removeagentdept
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentRemoveAgentDeptDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentRemoveAgentDeptDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * * id sites agent
     */
    orgId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentRemoveAgentDeptDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
