/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Doctype as ClientSupportApiContractDoctype } from '../models/ClientSupportApi/Contract/doctype';
import { DoctypePrivs as ClientSupportApiContractDoctypePrivs } from '../models/ClientSupportApi/Contract/doctype-privs';
import { Priv as ClientSupportApiContractPriv } from '../models/ClientSupportApi/Contract/priv';
import { PrivDoctypes as ClientSupportApiContractPrivDoctypes } from '../models/ClientSupportApi/Contract/priv-doctypes';
import { SetIdPrivRequest as ClientSupportApiContractSetIdPrivRequest } from '../models/ClientSupportApi/Contract/set-id-priv-request';

@Injectable({
  providedIn: 'root',
})
export class PrivService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportListDoctypeGet
   */
  static readonly ApiSupportListDoctypeGetPath = '/api/Support/ListDoctype';

  /**
   * list of document types.
   *
   * ### Claim
   *  boservice.support.listdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListDoctypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractDoctype>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractDoctype>>;
      })
    );
  }

  /**
   * list of document types.
   *
   * ### Claim
   *  boservice.support.listdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListDoctypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractDoctype>> {

    return this.apiSupportListDoctypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractDoctype>>) => r.body as Array<ClientSupportApiContractDoctype>)
    );
  }

  /**
   * list of document types.
   *
   * ### Claim
   *  boservice.support.listdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListDoctypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractDoctype>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractDoctype>>;
      })
    );
  }

  /**
   * list of document types.
   *
   * ### Claim
   *  boservice.support.listdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListDoctypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractDoctype>> {

    return this.apiSupportListDoctypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractDoctype>>) => r.body as Array<ClientSupportApiContractDoctype>)
    );
  }

  /**
   * Path part for operation apiSupportListDoctypePrivsDocTypeGet
   */
  static readonly ApiSupportListDoctypePrivsDocTypeGetPath = '/api/Support/ListDoctypePrivs/{docType}';

  /**
   * List of available privileges for document type.
   *
   * ### Claim
   *  boservice.support.listdoctypeprivs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListDoctypePrivsDocTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypePrivsDocTypeGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * document type (id)
     */
    docType: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractDoctypePrivs>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListDoctypePrivsDocTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('docType', params.docType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractDoctypePrivs>>;
      })
    );
  }

  /**
   * List of available privileges for document type.
   *
   * ### Claim
   *  boservice.support.listdoctypeprivs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListDoctypePrivsDocTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypePrivsDocTypeGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * document type (id)
     */
    docType: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractDoctypePrivs>> {

    return this.apiSupportListDoctypePrivsDocTypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractDoctypePrivs>>) => r.body as Array<ClientSupportApiContractDoctypePrivs>)
    );
  }

  /**
   * List of available privileges for document type.
   *
   * ### Claim
   *  boservice.support.listdoctypeprivs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListDoctypePrivsDocTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypePrivsDocTypeGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * document type (id)
     */
    docType: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractDoctypePrivs>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListDoctypePrivsDocTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('docType', params.docType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractDoctypePrivs>>;
      })
    );
  }

  /**
   * List of available privileges for document type.
   *
   * ### Claim
   *  boservice.support.listdoctypeprivs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListDoctypePrivsDocTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListDoctypePrivsDocTypeGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * document type (id)
     */
    docType: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractDoctypePrivs>> {

    return this.apiSupportListDoctypePrivsDocTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractDoctypePrivs>>) => r.body as Array<ClientSupportApiContractDoctypePrivs>)
    );
  }

  /**
   * Path part for operation apiSupportListPrivDoctypesPrivIdGet
   */
  static readonly ApiSupportListPrivDoctypesPrivIdGetPath = '/api/Support/ListPrivDoctypes/{privId}';

  /**
   * List of types of documents for benefits.
   *
   * ### Claim
   *  boservice.support.listprivdoctypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrivDoctypesPrivIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivDoctypesPrivIdGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractPrivDoctypes>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListPrivDoctypesPrivIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('privId', params.privId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractPrivDoctypes>>;
      })
    );
  }

  /**
   * List of types of documents for benefits.
   *
   * ### Claim
   *  boservice.support.listprivdoctypes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrivDoctypesPrivIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivDoctypesPrivIdGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractPrivDoctypes>> {

    return this.apiSupportListPrivDoctypesPrivIdGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractPrivDoctypes>>) => r.body as Array<ClientSupportApiContractPrivDoctypes>)
    );
  }

  /**
   * List of types of documents for benefits.
   *
   * ### Claim
   *  boservice.support.listprivdoctypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrivDoctypesPrivIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivDoctypesPrivIdGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractPrivDoctypes>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListPrivDoctypesPrivIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('privId', params.privId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractPrivDoctypes>>;
      })
    );
  }

  /**
   * List of types of documents for benefits.
   *
   * ### Claim
   *  boservice.support.listprivdoctypes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrivDoctypesPrivIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivDoctypesPrivIdGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id perks
     */
    privId: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractPrivDoctypes>> {

    return this.apiSupportListPrivDoctypesPrivIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractPrivDoctypes>>) => r.body as Array<ClientSupportApiContractPrivDoctypes>)
    );
  }

  /**
   * Path part for operation apiSupportSetIdPrivPost
   */
  static readonly ApiSupportSetIdPrivPostPath = '/api/Support/SetIdPriv';

  /**
   * Installing benefits.
   *
   * ### Claim
   *  boservice.support.setidpriv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetIdPrivPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetIdPrivPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractSetIdPrivRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportSetIdPrivPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Installing benefits.
   *
   * ### Claim
   *  boservice.support.setidpriv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetIdPrivPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetIdPrivPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractSetIdPrivRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetIdPrivPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportListPrivCategGet
   */
  static readonly ApiSupportListPrivCategGetPath = '/api/Support/ListPrivCateg';

  /**
   * Get a list of benefit categories.
   *
   * ### Claim
   *  boservice.support.listprivcateg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrivCategGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivCategGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractPriv>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListPrivCategGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractPriv>>;
      })
    );
  }

  /**
   * Get a list of benefit categories.
   *
   * ### Claim
   *  boservice.support.listprivcateg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrivCategGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivCategGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractPriv>> {

    return this.apiSupportListPrivCategGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractPriv>>) => r.body as Array<ClientSupportApiContractPriv>)
    );
  }

  /**
   * Get a list of benefit categories.
   *
   * ### Claim
   *  boservice.support.listprivcateg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrivCategGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivCategGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractPriv>>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportListPrivCategGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractPriv>>;
      })
    );
  }

  /**
   * Get a list of benefit categories.
   *
   * ### Claim
   *  boservice.support.listprivcateg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrivCategGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrivCategGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractPriv>> {

    return this.apiSupportListPrivCategGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractPriv>>) => r.body as Array<ClientSupportApiContractPriv>)
    );
  }

  /**
   * Path part for operation apiSupportRemoveClientPrivDelete
   */
  static readonly ApiSupportRemoveClientPrivDeletePath = '/api/Support/RemoveClientPriv';

  /**
   * Delete privilege for client.
   *
   * ### Claim
   *  boservice.support.removeclientpriv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRemoveClientPrivDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveClientPrivDelete$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	client id
     */
    clientId?: number;

    /**
     * *	privilege id
     */
    privId?: number;

    /**
     * operator's note
     */
    note?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportRemoveClientPrivDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('clientId', params.clientId, {});
      rb.query('privId', params.privId, {});
      rb.query('note', params.note, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete privilege for client.
   *
   * ### Claim
   *  boservice.support.removeclientpriv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRemoveClientPrivDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveClientPrivDelete(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	client id
     */
    clientId?: number;

    /**
     * *	privilege id
     */
    privId?: number;

    /**
     * operator's note
     */
    note?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportRemoveClientPrivDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportRemoveIdPrivDelete
   */
  static readonly ApiSupportRemoveIdPrivDeletePath = '/api/Support/RemoveIdPriv';

  /**
   * Delete (ridership class) for dependent medium.
   *
   * Delete privilege (ridership class) for medium. Only for medium with "dependent" attribute.
   * ### Claim
   *  boservice.support.removeidpriv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRemoveIdPrivDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveIdPrivDelete$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	medium id
     */
    mediumId?: number;

    /**
     * *	privilege id
     */
    privId?: number;

    /**
     * operator's note
     */
    note?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PrivService.ApiSupportRemoveIdPrivDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('mediumId', params.mediumId, {});
      rb.query('privId', params.privId, {});
      rb.query('note', params.note, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete (ridership class) for dependent medium.
   *
   * Delete privilege (ridership class) for medium. Only for medium with "dependent" attribute.
   * ### Claim
   *  boservice.support.removeidpriv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRemoveIdPrivDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveIdPrivDelete(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	medium id
     */
    mediumId?: number;

    /**
     * *	privilege id
     */
    privId?: number;

    /**
     * operator's note
     */
    note?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportRemoveIdPrivDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
