/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoadDataResponse as DataTransferContractLoadDataResponse } from '../models/DataTransfer/Contract/load-data-response';
import { LoadaDataRequest as DataTransferContractLoadaDataRequest } from '../models/DataTransfer/Contract/loada-data-request';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDataTransferTransferDataToDwhPost
   */
  static readonly ApiDataTransferTransferDataToDwhPostPath = '/api/DataTransfer/TransferDataToDWH';

  /**
   * Loading data into the DWH database.
   *
   * ### Claim
   *  boservice.datatransfer.transferdatatodwh
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDataTransferTransferDataToDwhPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDataTransferTransferDataToDwhPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Data to download
     */
    body?: DataTransferContractLoadaDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DataTransferContractLoadDataResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DataTransferService.ApiDataTransferTransferDataToDwhPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataTransferContractLoadDataResponse>>;
      })
    );
  }

  /**
   * Loading data into the DWH database.
   *
   * ### Claim
   *  boservice.datatransfer.transferdatatodwh
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDataTransferTransferDataToDwhPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDataTransferTransferDataToDwhPost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Data to download
     */
    body?: DataTransferContractLoadaDataRequest
  },
  context?: HttpContext

): Observable<Array<DataTransferContractLoadDataResponse>> {

    return this.apiDataTransferTransferDataToDwhPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DataTransferContractLoadDataResponse>>) => r.body as Array<DataTransferContractLoadDataResponse>)
    );
  }

}
