/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddAgentPaymentRequest as AgentApiContractAddAgentPaymentRequest } from '../models/AgentApi/Contract/add-agent-payment-request';
import { AddAgentRequest as AgentApiContractAddAgentRequest } from '../models/AgentApi/Contract/add-agent-request';
import { AddFile as AgentApiContractAddFile } from '../models/AgentApi/Contract/add-file';
import { AddPtTicketRequest as AgentApiContractAddPtTicketRequest } from '../models/AgentApi/Contract/add-pt-ticket-request';
import { AddPtTicketResponse as AgentApiContractAddPtTicketResponse } from '../models/AgentApi/Contract/add-pt-ticket-response';
import { Agent as AgentApiContractAgent } from '../models/AgentApi/Contract/agent';
import { AgentData as AgentApiContractAgentData } from '../models/AgentApi/Contract/agent-data';
import { AgentInfo as AgentApiContractAgentInfo } from '../models/AgentApi/Contract/agent-info';
import { AgentPayments as AgentApiContractAgentPayments } from '../models/AgentApi/Contract/agent-payments';
import { ListPtTariffResponse as AgentApiContractListPtTariffResponse } from '../models/AgentApi/Contract/list-pt-tariff-response';
import { LoadResult as AgentApiContractLoadResult } from '../models/AgentApi/Contract/load-result';
import { SellerSettings as AgentApiContractSellerSettings } from '../models/AgentApi/Contract/seller-settings';
import { SetAgentPaymentStatusRequest as AgentApiContractSetAgentPaymentStatusRequest } from '../models/AgentApi/Contract/set-agent-payment-status-request';
import { SetAgentRequest as AgentApiContractSetAgentRequest } from '../models/AgentApi/Contract/set-agent-request';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAgentAddAgentPaymentPost
   */
  static readonly ApiAgentAddAgentPaymentPostPath = '/api/Agent/AddAgentPayment';

  /**
   * Creating a request for replenishment.
   *
   * ### Claim
   *  boservice.agent.addagentpayment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentAddAgentPaymentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentPaymentPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddAgentPaymentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentAddAgentPaymentPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creating a request for replenishment.
   *
   * ### Claim
   *  boservice.agent.addagentpayment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentAddAgentPaymentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentPaymentPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddAgentPaymentRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentAddAgentPaymentPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAgentSetAgentPaymentStatusPost
   */
  static readonly ApiAgentSetAgentPaymentStatusPostPath = '/api/Agent/SetAgentPaymentStatus';

  /**
   * Replenishment confirmation.
   *
   * ### Claim
   *  boservice.agent.setagentpaymentstatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentSetAgentPaymentStatusPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentSetAgentPaymentStatusPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: Array<AgentApiContractSetAgentPaymentStatusRequest>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentSetAgentPaymentStatusPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Replenishment confirmation.
   *
   * ### Claim
   *  boservice.agent.setagentpaymentstatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentSetAgentPaymentStatusPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentSetAgentPaymentStatusPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: Array<AgentApiContractSetAgentPaymentStatusRequest>
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentSetAgentPaymentStatusPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAgentListAgentPaymentsGet
   */
  static readonly ApiAgentListAgentPaymentsGetPath = '/api/Agent/ListAgentPayments';

  /**
   * Getting a list of replenishment operations.
   *
   * ### Claim
   *  boservice.agent.listagentpayments
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListAgentPaymentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentPaymentsGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * status filter: 0 – pending approval; 1 - approved; 2 - rejected; null - no filter (any)
     */
    status?: number;

    /**
     * date with
     */
    start?: string;

    /**
     * date to (inclusive)
     */
    end?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAgentPayments>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListAgentPaymentsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('status', params.status, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAgentPayments>>;
      })
    );
  }

  /**
   * Getting a list of replenishment operations.
   *
   * ### Claim
   *  boservice.agent.listagentpayments
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListAgentPaymentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentPaymentsGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * status filter: 0 – pending approval; 1 - approved; 2 - rejected; null - no filter (any)
     */
    status?: number;

    /**
     * date with
     */
    start?: string;

    /**
     * date to (inclusive)
     */
    end?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAgentPayments>> {

    return this.apiAgentListAgentPaymentsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAgentPayments>>) => r.body as Array<AgentApiContractAgentPayments>)
    );
  }

  /**
   * Getting a list of replenishment operations.
   *
   * ### Claim
   *  boservice.agent.listagentpayments
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListAgentPaymentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentPaymentsGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * status filter: 0 – pending approval; 1 - approved; 2 - rejected; null - no filter (any)
     */
    status?: number;

    /**
     * date with
     */
    start?: string;

    /**
     * date to (inclusive)
     */
    end?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAgentPayments>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListAgentPaymentsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('status', params.status, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAgentPayments>>;
      })
    );
  }

  /**
   * Getting a list of replenishment operations.
   *
   * ### Claim
   *  boservice.agent.listagentpayments
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListAgentPaymentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentPaymentsGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * status filter: 0 – pending approval; 1 - approved; 2 - rejected; null - no filter (any)
     */
    status?: number;

    /**
     * date with
     */
    start?: string;

    /**
     * date to (inclusive)
     */
    end?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAgentPayments>> {

    return this.apiAgentListAgentPaymentsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAgentPayments>>) => r.body as Array<AgentApiContractAgentPayments>)
    );
  }

  /**
   * Path part for operation apiAgentLoadCardStoreSlrIdPost
   */
  static readonly ApiAgentLoadCardStoreSlrIdPostPath = '/api/Agent/LoadCardStore/{slrId}';

  /**
   * Downloading maps to agents.
   *
   * ### Claim
   *  boservice.agent.loadcardstore
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentLoadCardStoreSlrIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAgentLoadCardStoreSlrIdPost$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID of the agent to whom the cards are loaded
     */
    slrId: number;
    body?: {

/**
 * file
 */
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractAddFile>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentLoadCardStoreSlrIdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('slrId', params.slrId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractAddFile>;
      })
    );
  }

  /**
   * Downloading maps to agents.
   *
   * ### Claim
   *  boservice.agent.loadcardstore
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentLoadCardStoreSlrIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAgentLoadCardStoreSlrIdPost$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID of the agent to whom the cards are loaded
     */
    slrId: number;
    body?: {

/**
 * file
 */
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<AgentApiContractAddFile> {

    return this.apiAgentLoadCardStoreSlrIdPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractAddFile>) => r.body as AgentApiContractAddFile)
    );
  }

  /**
   * Downloading maps to agents.
   *
   * ### Claim
   *  boservice.agent.loadcardstore
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentLoadCardStoreSlrIdPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAgentLoadCardStoreSlrIdPost$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID of the agent to whom the cards are loaded
     */
    slrId: number;
    body?: {

/**
 * file
 */
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractAddFile>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentLoadCardStoreSlrIdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('slrId', params.slrId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractAddFile>;
      })
    );
  }

  /**
   * Downloading maps to agents.
   *
   * ### Claim
   *  boservice.agent.loadcardstore
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentLoadCardStoreSlrIdPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAgentLoadCardStoreSlrIdPost(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * ID of the agent to whom the cards are loaded
     */
    slrId: number;
    body?: {

/**
 * file
 */
'file'?: Blob;
}
  },
  context?: HttpContext

): Observable<AgentApiContractAddFile> {

    return this.apiAgentLoadCardStoreSlrIdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractAddFile>) => r.body as AgentApiContractAddFile)
    );
  }

  /**
   * Path part for operation apiAgentGetSellerSettingsGet
   */
  static readonly ApiAgentGetSellerSettingsGetPath = '/api/Agent/GetSellerSettings';

  /**
   * Getting agent settings.
   *
   * ### Claim
   *  boservice.agent.getsellersettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentGetSellerSettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetSellerSettingsGet$Plain$Response(params?: {

    /**
     * system language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractSellerSettings>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentGetSellerSettingsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractSellerSettings>;
      })
    );
  }

  /**
   * Getting agent settings.
   *
   * ### Claim
   *  boservice.agent.getsellersettings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentGetSellerSettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetSellerSettingsGet$Plain(params?: {

    /**
     * system language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<AgentApiContractSellerSettings> {

    return this.apiAgentGetSellerSettingsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractSellerSettings>) => r.body as AgentApiContractSellerSettings)
    );
  }

  /**
   * Getting agent settings.
   *
   * ### Claim
   *  boservice.agent.getsellersettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentGetSellerSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetSellerSettingsGet$Response(params?: {

    /**
     * system language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractSellerSettings>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentGetSellerSettingsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractSellerSettings>;
      })
    );
  }

  /**
   * Getting agent settings.
   *
   * ### Claim
   *  boservice.agent.getsellersettings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentGetSellerSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetSellerSettingsGet(params?: {

    /**
     * system language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<AgentApiContractSellerSettings> {

    return this.apiAgentGetSellerSettingsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractSellerSettings>) => r.body as AgentApiContractSellerSettings)
    );
  }

  /**
   * Path part for operation apiAgentListLoadResultGet
   */
  static readonly ApiAgentListLoadResultGetPath = '/api/Agent/ListLoadResult';

  /**
   * Getting the download result.
   *
   * ### Claim
   *  boservice.agent.listloadresult
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListLoadResultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListLoadResultGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * download file sid
     */
    fileSid?: string;

    /**
     * download status (0 - all; 1 - successfully downloaded maps; 2 - screened maps during download)
     */
    status?: number;
    fileName?: string;
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractLoadResult>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListLoadResultGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('fileSid', params.fileSid, {});
      rb.query('status', params.status, {});
      rb.query('fileName', params.fileName, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractLoadResult>>;
      })
    );
  }

  /**
   * Getting the download result.
   *
   * ### Claim
   *  boservice.agent.listloadresult
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListLoadResultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListLoadResultGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * download file sid
     */
    fileSid?: string;

    /**
     * download status (0 - all; 1 - successfully downloaded maps; 2 - screened maps during download)
     */
    status?: number;
    fileName?: string;
    token?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractLoadResult>> {

    return this.apiAgentListLoadResultGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractLoadResult>>) => r.body as Array<AgentApiContractLoadResult>)
    );
  }

  /**
   * Getting the download result.
   *
   * ### Claim
   *  boservice.agent.listloadresult
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListLoadResultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListLoadResultGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * download file sid
     */
    fileSid?: string;

    /**
     * download status (0 - all; 1 - successfully downloaded maps; 2 - screened maps during download)
     */
    status?: number;
    fileName?: string;
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractLoadResult>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListLoadResultGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('fileSid', params.fileSid, {});
      rb.query('status', params.status, {});
      rb.query('fileName', params.fileName, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractLoadResult>>;
      })
    );
  }

  /**
   * Getting the download result.
   *
   * ### Claim
   *  boservice.agent.listloadresult
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListLoadResultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListLoadResultGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * download file sid
     */
    fileSid?: string;

    /**
     * download status (0 - all; 1 - successfully downloaded maps; 2 - screened maps during download)
     */
    status?: number;
    fileName?: string;
    token?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractLoadResult>> {

    return this.apiAgentListLoadResultGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractLoadResult>>) => r.body as Array<AgentApiContractLoadResult>)
    );
  }

  /**
   * Path part for operation apiAgentAddAgentPost
   */
  static readonly ApiAgentAddAgentPostPath = '/api/Agent/AddAgent';

  /**
   * Adding an agent.
   *
   * ### Claim
   *  boservice.agent.addagent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentAddAgentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddAgentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractAgent>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentAddAgentPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractAgent>;
      })
    );
  }

  /**
   * Adding an agent.
   *
   * ### Claim
   *  boservice.agent.addagent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentAddAgentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddAgentRequest
  },
  context?: HttpContext

): Observable<AgentApiContractAgent> {

    return this.apiAgentAddAgentPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractAgent>) => r.body as AgentApiContractAgent)
    );
  }

  /**
   * Adding an agent.
   *
   * ### Claim
   *  boservice.agent.addagent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentAddAgentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddAgentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractAgent>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentAddAgentPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractAgent>;
      })
    );
  }

  /**
   * Adding an agent.
   *
   * ### Claim
   *  boservice.agent.addagent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentAddAgentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddAgentPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddAgentRequest
  },
  context?: HttpContext

): Observable<AgentApiContractAgent> {

    return this.apiAgentAddAgentPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractAgent>) => r.body as AgentApiContractAgent)
    );
  }

  /**
   * Path part for operation apiAgentRemoveAgentDelete
   */
  static readonly ApiAgentRemoveAgentDeletePath = '/api/Agent/RemoveAgent';

  /**
   * Removing an agent.
   *
   * ### Claim
   *  boservice.agent.removeagent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentRemoveAgentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentRemoveAgentDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentRemoveAgentDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slrId', params.slrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing an agent.
   *
   * ### Claim
   *  boservice.agent.removeagent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentRemoveAgentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentRemoveAgentDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentRemoveAgentDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAgentListAgentGet
   */
  static readonly ApiAgentListAgentGetPath = '/api/Agent/ListAgent';

  /**
   * List of agents.
   *
   * ### Claim
   *  boservice.agent.listagent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListAgentGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAgentInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListAgentGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAgentInfo>>;
      })
    );
  }

  /**
   * List of agents.
   *
   * ### Claim
   *  boservice.agent.listagent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListAgentGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAgentInfo>> {

    return this.apiAgentListAgentGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAgentInfo>>) => r.body as Array<AgentApiContractAgentInfo>)
    );
  }

  /**
   * List of agents.
   *
   * ### Claim
   *  boservice.agent.listagent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListAgentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAgentInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListAgentGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAgentInfo>>;
      })
    );
  }

  /**
   * List of agents.
   *
   * ### Claim
   *  boservice.agent.listagent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListAgentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListAgentGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAgentInfo>> {

    return this.apiAgentListAgentGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAgentInfo>>) => r.body as Array<AgentApiContractAgentInfo>)
    );
  }

  /**
   * Path part for operation apiAgentGetAgentDataGet
   */
  static readonly ApiAgentGetAgentDataGetPath = '/api/Agent/GetAgentData';

  /**
   * Agent details.
   *
   * ### Claim
   *  boservice.agent.getagentdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentGetAgentDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetAgentDataGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractAgentData>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentGetAgentDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slrId', params.slrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractAgentData>;
      })
    );
  }

  /**
   * Agent details.
   *
   * ### Claim
   *  boservice.agent.getagentdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentGetAgentDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetAgentDataGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<AgentApiContractAgentData> {

    return this.apiAgentGetAgentDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractAgentData>) => r.body as AgentApiContractAgentData)
    );
  }

  /**
   * Agent details.
   *
   * ### Claim
   *  boservice.agent.getagentdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentGetAgentDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetAgentDataGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AgentApiContractAgentData>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentGetAgentDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slrId', params.slrId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgentApiContractAgentData>;
      })
    );
  }

  /**
   * Agent details.
   *
   * ### Claim
   *  boservice.agent.getagentdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentGetAgentDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentGetAgentDataGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * agent id
     */
    slrId?: number;
  },
  context?: HttpContext

): Observable<AgentApiContractAgentData> {

    return this.apiAgentGetAgentDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<AgentApiContractAgentData>) => r.body as AgentApiContractAgentData)
    );
  }

  /**
   * Path part for operation apiAgentListPtTariffGet
   */
  static readonly ApiAgentListPtTariffGetPath = '/api/Agent/ListPtTariff';

  /**
   * Getting a list of fares intended for paper tickets (QR).
   *
   * An empty list means that paper tickets cannot be sold at the terminal.
   * Can return an error if there is not enough funds on the agent's balance.
   * ### Claim
   *  boservice.agent.listpttariff
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListPtTariffGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListPtTariffGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * terminal id
     */
    termCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractListPtTariffResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListPtTariffGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('termCode', params.termCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractListPtTariffResponse>>;
      })
    );
  }

  /**
   * Getting a list of fares intended for paper tickets (QR).
   *
   * An empty list means that paper tickets cannot be sold at the terminal.
   * Can return an error if there is not enough funds on the agent's balance.
   * ### Claim
   *  boservice.agent.listpttariff
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListPtTariffGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListPtTariffGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * terminal id
     */
    termCode?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractListPtTariffResponse>> {

    return this.apiAgentListPtTariffGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractListPtTariffResponse>>) => r.body as Array<AgentApiContractListPtTariffResponse>)
    );
  }

  /**
   * Getting a list of fares intended for paper tickets (QR).
   *
   * An empty list means that paper tickets cannot be sold at the terminal.
   * Can return an error if there is not enough funds on the agent's balance.
   * ### Claim
   *  boservice.agent.listpttariff
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentListPtTariffGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListPtTariffGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * terminal id
     */
    termCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractListPtTariffResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentListPtTariffGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('termCode', params.termCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractListPtTariffResponse>>;
      })
    );
  }

  /**
   * Getting a list of fares intended for paper tickets (QR).
   *
   * An empty list means that paper tickets cannot be sold at the terminal.
   * Can return an error if there is not enough funds on the agent's balance.
   * ### Claim
   *  boservice.agent.listpttariff
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentListPtTariffGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAgentListPtTariffGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * terminal id
     */
    termCode?: string;
  },
  context?: HttpContext

): Observable<Array<AgentApiContractListPtTariffResponse>> {

    return this.apiAgentListPtTariffGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractListPtTariffResponse>>) => r.body as Array<AgentApiContractListPtTariffResponse>)
    );
  }

  /**
   * Path part for operation apiAgentAddPtTicketPost
   */
  static readonly ApiAgentAddPtTicketPostPath = '/api/Agent/AddPtTicket';

  /**
   * Paper ticket data generation (QR) and sale registration.
   *
   * ### Claim
   *  boservice.agent.addptticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentAddPtTicketPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddPtTicketPost$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddPtTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAddPtTicketResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentAddPtTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAddPtTicketResponse>>;
      })
    );
  }

  /**
   * Paper ticket data generation (QR) and sale registration.
   *
   * ### Claim
   *  boservice.agent.addptticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentAddPtTicketPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddPtTicketPost$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddPtTicketRequest
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAddPtTicketResponse>> {

    return this.apiAgentAddPtTicketPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAddPtTicketResponse>>) => r.body as Array<AgentApiContractAddPtTicketResponse>)
    );
  }

  /**
   * Paper ticket data generation (QR) and sale registration.
   *
   * ### Claim
   *  boservice.agent.addptticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentAddPtTicketPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddPtTicketPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddPtTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AgentApiContractAddPtTicketResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentAddPtTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentApiContractAddPtTicketResponse>>;
      })
    );
  }

  /**
   * Paper ticket data generation (QR) and sale registration.
   *
   * ### Claim
   *  boservice.agent.addptticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentAddPtTicketPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentAddPtTicketPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AgentApiContractAddPtTicketRequest
  },
  context?: HttpContext

): Observable<Array<AgentApiContractAddPtTicketResponse>> {

    return this.apiAgentAddPtTicketPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AgentApiContractAddPtTicketResponse>>) => r.body as Array<AgentApiContractAddPtTicketResponse>)
    );
  }

  /**
   * Path part for operation apiAgentSetAgentPost
   */
  static readonly ApiAgentSetAgentPostPath = '/api/Agent/SetAgent';

  /**
   * Editing agent data.
   *
   * ### Claim
   *  boservice.agent.setagent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAgentSetAgentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentSetAgentPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AgentApiContractSetAgentRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentService.ApiAgentSetAgentPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing agent data.
   *
   * ### Claim
   *  boservice.agent.setagent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAgentSetAgentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAgentSetAgentPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: AgentApiContractSetAgentRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiAgentSetAgentPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
