/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddDoctypeRequest as TariffEditorApiContractAddDoctypeRequest } from '../models/TariffEditorApi/Contract/add-doctype-request';
import { AddDoctypeResponse as TariffEditorApiContractAddDoctypeResponse } from '../models/TariffEditorApi/Contract/add-doctype-response';
import { GetDoctypeResponseBo as TariffEditorApiContractGetDoctypeResponseBo } from '../models/TariffEditorApi/Contract/get-doctype-response-bo';
import { ListDoctypeResponse as TariffEditorApiContractListDoctypeResponse } from '../models/TariffEditorApi/Contract/list-doctype-response';
import { SetDoctypeRequest as TariffEditorApiContractSetDoctypeRequest } from '../models/TariffEditorApi/Contract/set-doctype-request';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffListDoctypeGet
   */
  static readonly ApiTariffListDoctypeGetPath = '/api/Tariff/ListDoctype';

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListDoctypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffListDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>;
      })
    );
  }

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListDoctypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListDoctypeResponse>> {

    return this.apiTariffListDoctypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>) => r.body as Array<TariffEditorApiContractListDoctypeResponse>)
    );
  }

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffListDoctypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffListDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>;
      })
    );
  }

  /**
   * Getting a list of document types.
   *
   * ### Claim
   *  boservice.tariff.listdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffListDoctypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffListDoctypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<TariffEditorApiContractListDoctypeResponse>> {

    return this.apiTariffListDoctypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TariffEditorApiContractListDoctypeResponse>>) => r.body as Array<TariffEditorApiContractListDoctypeResponse>)
    );
  }

  /**
   * Path part for operation apiTariffGetDoctypeGet
   */
  static readonly ApiTariffGetDoctypeGetPath = '/api/Tariff/GetDoctype';

  /**
   * Get document type data.
   *
   * ### Claim
   *  boservice.tariff.getdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetDoctypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDoctypeGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	document type id
     */
    docType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetDoctypeResponseBo>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffGetDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('docType', params.docType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetDoctypeResponseBo>;
      })
    );
  }

  /**
   * Get document type data.
   *
   * ### Claim
   *  boservice.tariff.getdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetDoctypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDoctypeGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	document type id
     */
    docType?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetDoctypeResponseBo> {

    return this.apiTariffGetDoctypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetDoctypeResponseBo>) => r.body as TariffEditorApiContractGetDoctypeResponseBo)
    );
  }

  /**
   * Get document type data.
   *
   * ### Claim
   *  boservice.tariff.getdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetDoctypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDoctypeGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	document type id
     */
    docType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetDoctypeResponseBo>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffGetDoctypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('docType', params.docType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetDoctypeResponseBo>;
      })
    );
  }

  /**
   * Get document type data.
   *
   * ### Claim
   *  boservice.tariff.getdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetDoctypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetDoctypeGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	document type id
     */
    docType?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetDoctypeResponseBo> {

    return this.apiTariffGetDoctypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetDoctypeResponseBo>) => r.body as TariffEditorApiContractGetDoctypeResponseBo)
    );
  }

  /**
   * Path part for operation apiTariffSetDoctypePost
   */
  static readonly ApiTariffSetDoctypePostPath = '/api/Tariff/SetDoctype';

  /**
   * Edit document type data.
   *
   * ### Claim
   *  boservice.tariff.setdoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffSetDoctypePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetDoctypePost$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: TariffEditorApiContractSetDoctypeRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffSetDoctypePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Edit document type data.
   *
   * ### Claim
   *  boservice.tariff.setdoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffSetDoctypePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffSetDoctypePost(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: TariffEditorApiContractSetDoctypeRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffSetDoctypePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffAddDoctypePost
   */
  static readonly ApiTariffAddDoctypePostPath = '/api/Tariff/AddDoctype';

  /**
   * Add document type data.
   *
   * ### Claim
   *  boservice.tariff.adddoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddDoctypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDoctypePost$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: TariffEditorApiContractAddDoctypeRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddDoctypeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffAddDoctypePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddDoctypeResponse>;
      })
    );
  }

  /**
   * Add document type data.
   *
   * ### Claim
   *  boservice.tariff.adddoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddDoctypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDoctypePost$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: TariffEditorApiContractAddDoctypeRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddDoctypeResponse> {

    return this.apiTariffAddDoctypePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddDoctypeResponse>) => r.body as TariffEditorApiContractAddDoctypeResponse)
    );
  }

  /**
   * Add document type data.
   *
   * ### Claim
   *  boservice.tariff.adddoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddDoctypePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDoctypePost$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: TariffEditorApiContractAddDoctypeRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddDoctypeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffAddDoctypePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddDoctypeResponse>;
      })
    );
  }

  /**
   * Add document type data.
   *
   * ### Claim
   *  boservice.tariff.adddoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddDoctypePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddDoctypePost(params?: {

    /**
     * *	language code
     */
    lang?: string;
    body?: TariffEditorApiContractAddDoctypeRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddDoctypeResponse> {

    return this.apiTariffAddDoctypePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddDoctypeResponse>) => r.body as TariffEditorApiContractAddDoctypeResponse)
    );
  }

  /**
   * Path part for operation apiTariffDelDoctypeDelete
   */
  static readonly ApiTariffDelDoctypeDeletePath = '/api/Tariff/DelDoctype';

  /**
   * Delete document type data.
   *
   * ### Claim
   *  boservice.tariff.deldoctype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffDelDoctypeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffDelDoctypeDelete$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	document type id
     */
    docType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentTypesService.ApiTariffDelDoctypeDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('docType', params.docType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete document type data.
   *
   * ### Claim
   *  boservice.tariff.deldoctype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffDelDoctypeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffDelDoctypeDelete(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	document type id
     */
    docType?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffDelDoctypeDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
