import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenHttpInterceptor } from './token.http.interceptor';
import { ErrorService } from '@libs/error/error.service';
import { ErrorHttpInterceptor } from '@core/auth/token/error.http.interceptor';


@NgModule({
    declarations: [],
    imports: [HttpClientModule],
    providers: [
        ErrorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHttpInterceptor,
            multi: true,
        },
    ],
})
export class TokenModule {
}
