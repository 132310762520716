/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckEmailRequest as ClientSupportApiContractChangePhoneEmailCheckEmailRequest } from '../models/ClientSupportApi/Contract/ChangePhoneEmail/check-email-request';
import { CheckMobilePhoneRequest as ClientSupportApiContractChangePhoneEmailCheckMobilePhoneRequest } from '../models/ClientSupportApi/Contract/ChangePhoneEmail/check-mobile-phone-request';
import { SetEmailRequest as ClientSupportApiContractChangePhoneEmailSetEmailRequest } from '../models/ClientSupportApi/Contract/ChangePhoneEmail/set-email-request';
import { SetMobilePhoneRequest as ClientSupportApiContractChangePhoneEmailSetMobilePhoneRequest } from '../models/ClientSupportApi/Contract/ChangePhoneEmail/set-mobile-phone-request';
import { SmsCode as ClientSupportApiContractChangePhoneEmailSmsCode } from '../models/ClientSupportApi/Contract/ChangePhoneEmail/sms-code';

@Injectable({
  providedIn: 'root',
})
export class ChangePhoneEmailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportCheckMobilePhonePost
   */
  static readonly ApiSupportCheckMobilePhonePostPath = '/api/Support/CheckMobilePhone';

  /**
   * Check new mobile phone number before saving (roamdb).
   *
   * Checks if mobile number does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_PHONE error.
   * ### Claim
   *  boservice.support.checkmobilephone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCheckMobilePhonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckMobilePhonePost$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckMobilePhoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>> {

    const rb = new RequestBuilder(this.rootUrl, ChangePhoneEmailService.ApiSupportCheckMobilePhonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>;
      })
    );
  }

  /**
   * Check new mobile phone number before saving (roamdb).
   *
   * Checks if mobile number does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_PHONE error.
   * ### Claim
   *  boservice.support.checkmobilephone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportCheckMobilePhonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckMobilePhonePost$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckMobilePhoneRequest
  },
  context?: HttpContext

): Observable<ClientSupportApiContractChangePhoneEmailSmsCode> {

    return this.apiSupportCheckMobilePhonePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>) => r.body as ClientSupportApiContractChangePhoneEmailSmsCode)
    );
  }

  /**
   * Check new mobile phone number before saving (roamdb).
   *
   * Checks if mobile number does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_PHONE error.
   * ### Claim
   *  boservice.support.checkmobilephone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCheckMobilePhonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckMobilePhonePost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckMobilePhoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>> {

    const rb = new RequestBuilder(this.rootUrl, ChangePhoneEmailService.ApiSupportCheckMobilePhonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>;
      })
    );
  }

  /**
   * Check new mobile phone number before saving (roamdb).
   *
   * Checks if mobile number does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_PHONE error.
   * ### Claim
   *  boservice.support.checkmobilephone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportCheckMobilePhonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckMobilePhonePost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckMobilePhoneRequest
  },
  context?: HttpContext

): Observable<ClientSupportApiContractChangePhoneEmailSmsCode> {

    return this.apiSupportCheckMobilePhonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>) => r.body as ClientSupportApiContractChangePhoneEmailSmsCode)
    );
  }

  /**
   * Path part for operation apiSupportSetMobilePhonePost
   */
  static readonly ApiSupportSetMobilePhonePostPath = '/api/Support/SetMobilePhone';

  /**
   * Mobile phone number editing.
   *
   * Changes client's mobile number in roamdb database
   * ### Claim
   *  boservice.support.setmobilephone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetMobilePhonePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetMobilePhonePost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailSetMobilePhoneRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChangePhoneEmailService.ApiSupportSetMobilePhonePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mobile phone number editing.
   *
   * Changes client's mobile number in roamdb database
   * ### Claim
   *  boservice.support.setmobilephone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetMobilePhonePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetMobilePhonePost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailSetMobilePhoneRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetMobilePhonePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportCheckEmailPost
   */
  static readonly ApiSupportCheckEmailPostPath = '/api/Support/CheckEmail';

  /**
   * Check new e-mail before saving (roamdb).
   *
   * Checks if e-mail does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_EMAIL error
   * ### Claim
   *  boservice.support.checkemail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCheckEmailPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckEmailPost$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckEmailRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>> {

    const rb = new RequestBuilder(this.rootUrl, ChangePhoneEmailService.ApiSupportCheckEmailPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>;
      })
    );
  }

  /**
   * Check new e-mail before saving (roamdb).
   *
   * Checks if e-mail does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_EMAIL error
   * ### Claim
   *  boservice.support.checkemail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportCheckEmailPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckEmailPost$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckEmailRequest
  },
  context?: HttpContext

): Observable<ClientSupportApiContractChangePhoneEmailSmsCode> {

    return this.apiSupportCheckEmailPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>) => r.body as ClientSupportApiContractChangePhoneEmailSmsCode)
    );
  }

  /**
   * Check new e-mail before saving (roamdb).
   *
   * Checks if e-mail does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_EMAIL error
   * ### Claim
   *  boservice.support.checkemail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCheckEmailPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckEmailPost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckEmailRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>> {

    const rb = new RequestBuilder(this.rootUrl, ChangePhoneEmailService.ApiSupportCheckEmailPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>;
      })
    );
  }

  /**
   * Check new e-mail before saving (roamdb).
   *
   * Checks if e-mail does not exist in roamdb database.
   * If value already exists, then function raises ERR_DOUBLE_EMAIL error
   * ### Claim
   *  boservice.support.checkemail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportCheckEmailPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportCheckEmailPost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailCheckEmailRequest
  },
  context?: HttpContext

): Observable<ClientSupportApiContractChangePhoneEmailSmsCode> {

    return this.apiSupportCheckEmailPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractChangePhoneEmailSmsCode>) => r.body as ClientSupportApiContractChangePhoneEmailSmsCode)
    );
  }

  /**
   * Path part for operation apiSupportSetEmailPost
   */
  static readonly ApiSupportSetEmailPostPath = '/api/Support/SetEmail';

  /**
   * Mobile phone number editing.
   *
   * Changes client's mobile number in roamdb database
   * ### Claim
   *  boservice.support.setemail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetEmailPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetEmailPost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailSetEmailRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChangePhoneEmailService.ApiSupportSetEmailPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mobile phone number editing.
   *
   * Changes client's mobile number in roamdb database
   * ### Claim
   *  boservice.support.setemail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetEmailPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetEmailPost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractChangePhoneEmailSetEmailRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetEmailPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
