/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetSchemaServiceResponse as MonitoringServiceContractsNewGetSchemaServiceResponse } from '../models/MonitoringService/Contracts/New/get-schema-service-response';
import { GetSchemaStationResponse as MonitoringServiceContractsNewGetSchemaStationResponse } from '../models/MonitoringService/Contracts/New/get-schema-station-response';
import { GetVlDataResponse as MonitoringServiceContractsNewGetVlDataResponse } from '../models/MonitoringService/Contracts/New/get-vl-data-response';
import { ListSchemaServiceResponse as MonitoringServiceContractsNewListSchemaServiceResponse } from '../models/MonitoringService/Contracts/New/list-schema-service-response';
import { ListStPointsResponse as MonitoringServiceContractsNewListStPointsResponse } from '../models/MonitoringService/Contracts/New/list-st-points-response';
import { ListVlDataResponse as MonitoringServiceContractsNewListVlDataResponse } from '../models/MonitoringService/Contracts/New/list-vl-data-response';
import { Provider as MonitoringServiceContractsProvider } from '../models/MonitoringService/Contracts/provider';
import { RouteNew as MonitoringServiceContractsRouteNew } from '../models/MonitoringService/Contracts/route-new';
import { ServiceForRoute as MonitoringServiceContractsServiceForRoute } from '../models/MonitoringService/Contracts/service-for-route';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMonitoringListServicesGet
   */
  static readonly ApiMonitoringListServicesGetPath = '/api/Monitoring/ListServices';

  /**
   * Getting a list of services.
   *
   * ### Claim
   *  boservice.monitoring.listservicesforroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListServicesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListServicesGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsServiceForRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListServicesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsServiceForRoute>>;
      })
    );
  }

  /**
   * Getting a list of services.
   *
   * ### Claim
   *  boservice.monitoring.listservicesforroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListServicesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListServicesGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsServiceForRoute>> {

    return this.apiMonitoringListServicesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsServiceForRoute>>) => r.body as Array<MonitoringServiceContractsServiceForRoute>)
    );
  }

  /**
   * Getting a list of services.
   *
   * ### Claim
   *  boservice.monitoring.listservicesforroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListServicesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsServiceForRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListServicesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsServiceForRoute>>;
      })
    );
  }

  /**
   * Getting a list of services.
   *
   * ### Claim
   *  boservice.monitoring.listservicesforroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListServicesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsServiceForRoute>> {

    return this.apiMonitoringListServicesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsServiceForRoute>>) => r.body as Array<MonitoringServiceContractsServiceForRoute>)
    );
  }

  /**
   * Path part for operation apiMonitoringListRoutesGet
   */
  static readonly ApiMonitoringListRoutesGetPath = '/api/Monitoring/ListRoutes';

  /**
   * Route list.
   *
   * ### Claim
   *  boservice.monitoring.listservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListRoutesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListRoutesGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsRouteNew>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListRoutesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsRouteNew>>;
      })
    );
  }

  /**
   * Route list.
   *
   * ### Claim
   *  boservice.monitoring.listservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListRoutesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListRoutesGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsRouteNew>> {

    return this.apiMonitoringListRoutesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsRouteNew>>) => r.body as Array<MonitoringServiceContractsRouteNew>)
    );
  }

  /**
   * Route list.
   *
   * ### Claim
   *  boservice.monitoring.listservicedetail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListRoutesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListRoutesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsRouteNew>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListRoutesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsRouteNew>>;
      })
    );
  }

  /**
   * Route list.
   *
   * ### Claim
   *  boservice.monitoring.listservicedetail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListRoutesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListRoutesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsRouteNew>> {

    return this.apiMonitoringListRoutesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsRouteNew>>) => r.body as Array<MonitoringServiceContractsRouteNew>)
    );
  }

  /**
   * Path part for operation apiMonitoringListProvidersGet
   */
  static readonly ApiMonitoringListProvidersGetPath = '/api/Monitoring/ListProviders';

  /**
   * ### Claim
   *  boservice.monitoring.getprovider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListProvidersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListProvidersGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsProvider>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListProvidersGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsProvider>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoring.getprovider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListProvidersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListProvidersGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsProvider>> {

    return this.apiMonitoringListProvidersGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsProvider>>) => r.body as Array<MonitoringServiceContractsProvider>)
    );
  }

  /**
   * ### Claim
   *  boservice.monitoring.getprovider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListProvidersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListProvidersGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsProvider>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListProvidersGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsProvider>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoring.getprovider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListProvidersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListProvidersGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsProvider>> {

    return this.apiMonitoringListProvidersGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsProvider>>) => r.body as Array<MonitoringServiceContractsProvider>)
    );
  }

  /**
   * Path part for operation apiMonitoringGetVlDataGet
   */
  static readonly ApiMonitoringGetVlDataGetPath = '/api/Monitoring/GetVlData';

  /**
   * Obtaining detailed validator data from the database.
   *
   * ### Claim
   *  boservice.monitoring.getvldata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringGetVlDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetVlDataGet$Plain$Response(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * * validator number
     */
    vlN?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MonitoringServiceContractsNewGetVlDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringGetVlDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('vlN', params.vlN, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringServiceContractsNewGetVlDataResponse>;
      })
    );
  }

  /**
   * Obtaining detailed validator data from the database.
   *
   * ### Claim
   *  boservice.monitoring.getvldata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringGetVlDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetVlDataGet$Plain(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * * validator number
     */
    vlN?: number;
  },
  context?: HttpContext

): Observable<MonitoringServiceContractsNewGetVlDataResponse> {

    return this.apiMonitoringGetVlDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MonitoringServiceContractsNewGetVlDataResponse>) => r.body as MonitoringServiceContractsNewGetVlDataResponse)
    );
  }

  /**
   * Obtaining detailed validator data from the database.
   *
   * ### Claim
   *  boservice.monitoring.getvldata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringGetVlDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetVlDataGet$Response(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * * validator number
     */
    vlN?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MonitoringServiceContractsNewGetVlDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringGetVlDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('vlN', params.vlN, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringServiceContractsNewGetVlDataResponse>;
      })
    );
  }

  /**
   * Obtaining detailed validator data from the database.
   *
   * ### Claim
   *  boservice.monitoring.getvldata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringGetVlDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetVlDataGet(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * * validator number
     */
    vlN?: number;
  },
  context?: HttpContext

): Observable<MonitoringServiceContractsNewGetVlDataResponse> {

    return this.apiMonitoringGetVlDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<MonitoringServiceContractsNewGetVlDataResponse>) => r.body as MonitoringServiceContractsNewGetVlDataResponse)
    );
  }

  /**
   * Path part for operation apiMonitoringListStPointsGet
   */
  static readonly ApiMonitoringListStPointsGetPath = '/api/Monitoring/ListStPoints';

  /**
   * Getting a list of stops (named coordinates).
   *
   * Get a list of stops (as named geographic points)
   * ### Claim
   *  boservice.monitoring.liststpoints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListStPointsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListStPointsGet$Plain$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * stop type: null - all 0 - stop 1 - station (associated with fixed mu)
     */
    stType?: number;

    /**
     * service id/null. if it is given then the stations associated with the fixed mu with the given service are returned.
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsNewListStPointsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListStPointsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('stType', params.stType, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsNewListStPointsResponse>>;
      })
    );
  }

  /**
   * Getting a list of stops (named coordinates).
   *
   * Get a list of stops (as named geographic points)
   * ### Claim
   *  boservice.monitoring.liststpoints
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListStPointsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListStPointsGet$Plain(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * stop type: null - all 0 - stop 1 - station (associated with fixed mu)
     */
    stType?: number;

    /**
     * service id/null. if it is given then the stations associated with the fixed mu with the given service are returned.
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsNewListStPointsResponse>> {

    return this.apiMonitoringListStPointsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsNewListStPointsResponse>>) => r.body as Array<MonitoringServiceContractsNewListStPointsResponse>)
    );
  }

  /**
   * Getting a list of stops (named coordinates).
   *
   * Get a list of stops (as named geographic points)
   * ### Claim
   *  boservice.monitoring.liststpoints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListStPointsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListStPointsGet$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * stop type: null - all 0 - stop 1 - station (associated with fixed mu)
     */
    stType?: number;

    /**
     * service id/null. if it is given then the stations associated with the fixed mu with the given service are returned.
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsNewListStPointsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListStPointsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('stType', params.stType, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsNewListStPointsResponse>>;
      })
    );
  }

  /**
   * Getting a list of stops (named coordinates).
   *
   * Get a list of stops (as named geographic points)
   * ### Claim
   *  boservice.monitoring.liststpoints
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListStPointsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListStPointsGet(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;

    /**
     * stop type: null - all 0 - stop 1 - station (associated with fixed mu)
     */
    stType?: number;

    /**
     * service id/null. if it is given then the stations associated with the fixed mu with the given service are returned.
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsNewListStPointsResponse>> {

    return this.apiMonitoringListStPointsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsNewListStPointsResponse>>) => r.body as Array<MonitoringServiceContractsNewListStPointsResponse>)
    );
  }

  /**
   * Path part for operation apiMonitoringListVlDataGet
   */
  static readonly ApiMonitoringListVlDataGetPath = '/api/Monitoring/ListVlData';

  /**
   * Getting a list of validators to monitor.
   *
   * ### Claim
   *  boservice.monitoring.listvldata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListVlDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListVlDataGet$Plain$Response(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * provider id/null
     */
    prvId?: number;

    /**
     * park id/null
     */
    prdtId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * route id/null
     */
    sdId?: number;

    /**
     * * status of validators to display: -1 - all 2 - in stock/under repair
     */
    status?: number;

    /**
     * * shift status: -1 – all 0 – open1 – closed
     */
    shiftStatus?: number;

    /**
     * stop id. null - all stops
     */
    stId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsNewListVlDataResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListVlDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('prdtId', params.prdtId, {});
      rb.query('srvId', params.srvId, {});
      rb.query('sdId', params.sdId, {});
      rb.query('status', params.status, {});
      rb.query('shiftStatus', params.shiftStatus, {});
      rb.query('stId', params.stId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsNewListVlDataResponse>>;
      })
    );
  }

  /**
   * Getting a list of validators to monitor.
   *
   * ### Claim
   *  boservice.monitoring.listvldata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListVlDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListVlDataGet$Plain(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * provider id/null
     */
    prvId?: number;

    /**
     * park id/null
     */
    prdtId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * route id/null
     */
    sdId?: number;

    /**
     * * status of validators to display: -1 - all 2 - in stock/under repair
     */
    status?: number;

    /**
     * * shift status: -1 – all 0 – open1 – closed
     */
    shiftStatus?: number;

    /**
     * stop id. null - all stops
     */
    stId?: number;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsNewListVlDataResponse>> {

    return this.apiMonitoringListVlDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsNewListVlDataResponse>>) => r.body as Array<MonitoringServiceContractsNewListVlDataResponse>)
    );
  }

  /**
   * Getting a list of validators to monitor.
   *
   * ### Claim
   *  boservice.monitoring.listvldata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListVlDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListVlDataGet$Response(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * provider id/null
     */
    prvId?: number;

    /**
     * park id/null
     */
    prdtId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * route id/null
     */
    sdId?: number;

    /**
     * * status of validators to display: -1 - all 2 - in stock/under repair
     */
    status?: number;

    /**
     * * shift status: -1 – all 0 – open1 – closed
     */
    shiftStatus?: number;

    /**
     * stop id. null - all stops
     */
    stId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsNewListVlDataResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListVlDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prvId', params.prvId, {});
      rb.query('prdtId', params.prdtId, {});
      rb.query('srvId', params.srvId, {});
      rb.query('sdId', params.sdId, {});
      rb.query('status', params.status, {});
      rb.query('shiftStatus', params.shiftStatus, {});
      rb.query('stId', params.stId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsNewListVlDataResponse>>;
      })
    );
  }

  /**
   * Getting a list of validators to monitor.
   *
   * ### Claim
   *  boservice.monitoring.listvldata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListVlDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListVlDataGet(params?: {

    /**
     * Interface language. null - default system language
     */
    lang?: string;

    /**
     * provider id/null
     */
    prvId?: number;

    /**
     * park id/null
     */
    prdtId?: number;

    /**
     * service id/null
     */
    srvId?: number;

    /**
     * route id/null
     */
    sdId?: number;

    /**
     * * status of validators to display: -1 - all 2 - in stock/under repair
     */
    status?: number;

    /**
     * * shift status: -1 – all 0 – open1 – closed
     */
    shiftStatus?: number;

    /**
     * stop id. null - all stops
     */
    stId?: number;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsNewListVlDataResponse>> {

    return this.apiMonitoringListVlDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsNewListVlDataResponse>>) => r.body as Array<MonitoringServiceContractsNewListVlDataResponse>)
    );
  }

  /**
   * Path part for operation apiMonitoringListSchemaServiceGet
   */
  static readonly ApiMonitoringListSchemaServiceGetPath = '/api/Monitoring/ListSchemaService';

  /**
   * Getting a list of services to monitor on a diagram.
   *
   * Getting a list of services for which monitoring is possible on the svg scheme
   * ### Claim
   *  boservice.monitoring.listschemaservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListSchemaServiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListSchemaServiceGet$Plain$Response(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsNewListSchemaServiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListSchemaServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsNewListSchemaServiceResponse>>;
      })
    );
  }

  /**
   * Getting a list of services to monitor on a diagram.
   *
   * Getting a list of services for which monitoring is possible on the svg scheme
   * ### Claim
   *  boservice.monitoring.listschemaservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListSchemaServiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListSchemaServiceGet$Plain(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsNewListSchemaServiceResponse>> {

    return this.apiMonitoringListSchemaServiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsNewListSchemaServiceResponse>>) => r.body as Array<MonitoringServiceContractsNewListSchemaServiceResponse>)
    );
  }

  /**
   * Getting a list of services to monitor on a diagram.
   *
   * Getting a list of services for which monitoring is possible on the svg scheme
   * ### Claim
   *  boservice.monitoring.listschemaservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringListSchemaServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListSchemaServiceGet$Response(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MonitoringServiceContractsNewListSchemaServiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringListSchemaServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonitoringServiceContractsNewListSchemaServiceResponse>>;
      })
    );
  }

  /**
   * Getting a list of services to monitor on a diagram.
   *
   * Getting a list of services for which monitoring is possible on the svg scheme
   * ### Claim
   *  boservice.monitoring.listschemaservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringListSchemaServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringListSchemaServiceGet(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<MonitoringServiceContractsNewListSchemaServiceResponse>> {

    return this.apiMonitoringListSchemaServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MonitoringServiceContractsNewListSchemaServiceResponse>>) => r.body as Array<MonitoringServiceContractsNewListSchemaServiceResponse>)
    );
  }

  /**
   * Path part for operation apiMonitoringGetSchemaServiceGet
   */
  static readonly ApiMonitoringGetSchemaServiceGetPath = '/api/Monitoring/GetSchemaService';

  /**
   * Retrieving Service Layer Schema Data.
   *
   * ### Claim
   *  boservice.monitoring.getschemaservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringGetSchemaServiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaServiceGet$Plain$Response(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MonitoringServiceContractsNewGetSchemaServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringGetSchemaServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringServiceContractsNewGetSchemaServiceResponse>;
      })
    );
  }

  /**
   * Retrieving Service Layer Schema Data.
   *
   * ### Claim
   *  boservice.monitoring.getschemaservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringGetSchemaServiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaServiceGet$Plain(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<MonitoringServiceContractsNewGetSchemaServiceResponse> {

    return this.apiMonitoringGetSchemaServiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MonitoringServiceContractsNewGetSchemaServiceResponse>) => r.body as MonitoringServiceContractsNewGetSchemaServiceResponse)
    );
  }

  /**
   * Retrieving Service Layer Schema Data.
   *
   * ### Claim
   *  boservice.monitoring.getschemaservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringGetSchemaServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaServiceGet$Response(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MonitoringServiceContractsNewGetSchemaServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringGetSchemaServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringServiceContractsNewGetSchemaServiceResponse>;
      })
    );
  }

  /**
   * Retrieving Service Layer Schema Data.
   *
   * ### Claim
   *  boservice.monitoring.getschemaservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringGetSchemaServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaServiceGet(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<MonitoringServiceContractsNewGetSchemaServiceResponse> {

    return this.apiMonitoringGetSchemaServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<MonitoringServiceContractsNewGetSchemaServiceResponse>) => r.body as MonitoringServiceContractsNewGetSchemaServiceResponse)
    );
  }

  /**
   * Path part for operation apiMonitoringGetSchemaStationGet
   */
  static readonly ApiMonitoringGetSchemaStationGetPath = '/api/Monitoring/GetSchemaStation';

  /**
   * Retrieving station level diagram data.
   *
   * ### Claim
   *  boservice.monitoring.getschemastation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringGetSchemaStationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaStationGet$Plain$Response(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * station code
     */
    stCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MonitoringServiceContractsNewGetSchemaStationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringGetSchemaStationGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('stCode', params.stCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringServiceContractsNewGetSchemaStationResponse>;
      })
    );
  }

  /**
   * Retrieving station level diagram data.
   *
   * ### Claim
   *  boservice.monitoring.getschemastation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringGetSchemaStationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaStationGet$Plain(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * station code
     */
    stCode?: string;
  },
  context?: HttpContext

): Observable<MonitoringServiceContractsNewGetSchemaStationResponse> {

    return this.apiMonitoringGetSchemaStationGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MonitoringServiceContractsNewGetSchemaStationResponse>) => r.body as MonitoringServiceContractsNewGetSchemaStationResponse)
    );
  }

  /**
   * Retrieving station level diagram data.
   *
   * ### Claim
   *  boservice.monitoring.getschemastation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringGetSchemaStationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaStationGet$Response(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * station code
     */
    stCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MonitoringServiceContractsNewGetSchemaStationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ApiMonitoringGetSchemaStationGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('stCode', params.stCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringServiceContractsNewGetSchemaStationResponse>;
      })
    );
  }

  /**
   * Retrieving station level diagram data.
   *
   * ### Claim
   *  boservice.monitoring.getschemastation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringGetSchemaStationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringGetSchemaStationGet(params?: {

    /**
     * language interface. If not specified, the language system defaults to / null
     */
    lang?: string;

    /**
     * * station code
     */
    stCode?: string;
  },
  context?: HttpContext

): Observable<MonitoringServiceContractsNewGetSchemaStationResponse> {

    return this.apiMonitoringGetSchemaStationGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<MonitoringServiceContractsNewGetSchemaStationResponse>) => r.body as MonitoringServiceContractsNewGetSchemaStationResponse)
    );
  }

}
