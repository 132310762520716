import { SettingsApiModel, SettingsDBModel, SettingsDBResponse } from '@libs/settings';
import { API_CONFIG_URL, DB_CONFIG_URL } from '@libs/constants';
import { NgModule } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PromiseLoad } from '@core/utils/promise-load';
import { getTestBed } from '@angular/core/testing';
import { BrowserDynamicTestingModule, platformBrowserDynamicTesting } from '@angular/platform-browser-dynamic/testing';
import { StorageKeys } from '@core/storage/storage.keys';


export interface CustomAngularApp {
    bootstrapApp(config: SettingsApiModel, module?: NgModule): void;
}

export class CreateBoApp<T> {
    readonly #apiConfigUrl: string = API_CONFIG_URL;
    readonly #databaseSettingsConfigUrl: string = DB_CONFIG_URL;
    readonly #appModule: T;
    readonly #isTesting: boolean;
    #app: CustomAngularApp;

    readonly #apiConfigRequest: Promise<SettingsApiModel> = new PromiseLoad(this.#apiConfigUrl).get<SettingsApiModel>();
    readonly #databaseSettingsConfigRequest: Promise<SettingsDBResponse> = new PromiseLoad(
        this.#databaseSettingsConfigUrl,
        StorageKeys.Access,
    ).get<SettingsDBResponse>();

    constructor(appModule: T, isTesting?: boolean) {
        this.#appModule = appModule;
        this.#isTesting = isTesting;
    }

    private bootstrap(): void {
        this.#apiConfigRequest.then((response: SettingsApiModel) => {
            if (this.#isTesting) {
                this.#app = new AngularBoTestAppWithSettings();
                this.#app.bootstrapApp(response);
                new AngularBoTestAppWithSettings();
            } else {
                this.#app = new AngularBoAppWithSettings();
                this.#app.bootstrapApp(response, this.#appModule);
            }
        });
    };

    private provideSettingsInWindow(settings: SettingsDBResponse): void {
        if (!window) {
            return;
        }
        // @ts-ignore
        window?.appDesignSettings?.phoneCode = settings.find(
            (item: SettingsDBModel) => item.name === 'DEFAULT_PHONE_COUNTRY',
        )?.value;
        // @ts-ignore
        window?.appDesignSettings?.currencyFormat = settings.find(
            (item: SettingsDBModel) => item.name === 'CURRENCY_FORMAT',
        )?.value;
        // @ts-ignore
        window?.appDesignSettings?.enableParking = settings.find(
            (item: SettingsDBModel) => item.name === 'PARKING_ENABLED',
        )?.value;
    }

    public initApp(): void {
        this.#databaseSettingsConfigRequest.then(
            (response: SettingsDBResponse): void => {
                this.provideSettingsInWindow(response);
                this.bootstrap();
            },
            (): void => {
                this.bootstrap();
            },
        );
    }
}

export class AngularBoAppWithSettings implements CustomAngularApp {
    bootstrapApp(config: SettingsApiModel, appModule: any) {
        platformBrowserDynamic([{ provide: 'API_CONFIG', useValue: config }])
            .bootstrapModule(appModule)
            .catch((err) => console.error(err));
    }
}

export class AngularBoTestAppWithSettings implements CustomAngularApp {
    bootstrapApp(config: SettingsApiModel) {
        getTestBed().initTestEnvironment(
            BrowserDynamicTestingModule,
            platformBrowserDynamicTesting([
                { provide: 'API_CONFIG', useValue: config },
            ]),
            {
                teardown: { destroyAfterEach: false },
            },
        );
    }
}
