/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CouponList as BoLankaContractCouponCouponList } from '../models/BoLanka/Contract/Coupon/coupon-list';
import { CouponStatusList as BoLankaContractCouponCouponStatusList } from '../models/BoLanka/Contract/Coupon/coupon-status-list';
import { DiscProgramActivateRequest as BoLankaContractCouponDiscProgramActivateRequest } from '../models/BoLanka/Contract/Coupon/disc-program-activate-request';
import { DiscProgramAddRequest as BoLankaContractCouponDiscProgramAddRequest } from '../models/BoLanka/Contract/Coupon/disc-program-add-request';
import { DiscProgramFinishRequest as BoLankaContractCouponDiscProgramFinishRequest } from '../models/BoLanka/Contract/Coupon/disc-program-finish-request';
import { DiscProgramSetRequest as BoLankaContractCouponDiscProgramSetRequest } from '../models/BoLanka/Contract/Coupon/disc-program-set-request';
import { DiscTariffListResponse as BoLankaContractCouponDiscTariffListResponse } from '../models/BoLanka/Contract/Coupon/disc-tariff-list-response';
import { IssuerList as BoLankaContractCouponIssuerList } from '../models/BoLanka/Contract/Coupon/issuer-list';
import { ProgramAdd as BoLankaContractCouponProgramAdd } from '../models/BoLanka/Contract/Coupon/program-add';
import { ProgramGet as BoLankaContractCouponProgramGet } from '../models/BoLanka/Contract/Coupon/program-get';
import { ProgramList as BoLankaContractCouponProgramList } from '../models/BoLanka/Contract/Coupon/program-list';
import { VoucherList as BoLankaContractCouponVoucherList } from '../models/BoLanka/Contract/Coupon/voucher-list';
import { CouponGet as ClientSupportApiContractCouponCouponGet } from '../models/ClientSupportApi/Contract/Coupon/coupon-get';

@Injectable({
  providedIn: 'root',
})
export class CouponService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCouponDiscIssuerListGet
   */
  static readonly ApiCouponDiscIssuerListGetPath = '/api/Coupon/DiscIssuerList';

  /**
   * Get a list of coupon issuers.
   *
   * ### Claim
   *  boservice.coupon.discissuerlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscIssuerListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscIssuerListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractCouponIssuerList>>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscIssuerListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractCouponIssuerList>>;
      })
    );
  }

  /**
   * Get a list of coupon issuers.
   *
   * ### Claim
   *  boservice.coupon.discissuerlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscIssuerListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscIssuerListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractCouponIssuerList>> {

    return this.apiCouponDiscIssuerListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractCouponIssuerList>>) => r.body as Array<BoLankaContractCouponIssuerList>)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramAddPost
   */
  static readonly ApiCouponDiscProgramAddPostPath = '/api/Coupon/DiscProgramAdd';

  /**
   * Adding a discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramadd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramAddPost$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractCouponProgramAdd>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractCouponProgramAdd>;
      })
    );
  }

  /**
   * Adding a discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramadd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramAddPost(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramAddRequest
  },
  context?: HttpContext

): Observable<BoLankaContractCouponProgramAdd> {

    return this.apiCouponDiscProgramAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractCouponProgramAdd>) => r.body as BoLankaContractCouponProgramAdd)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramActivatePost
   */
  static readonly ApiCouponDiscProgramActivatePostPath = '/api/Coupon/DiscProgramActivate';

  /**
   * Discount program activation.
   *
   * ### Claim
   *  boservice.coupon.discprogramactivate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramActivatePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramActivatePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramActivateRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramActivatePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Discount program activation.
   *
   * ### Claim
   *  boservice.coupon.discprogramactivate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramActivatePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramActivatePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramActivateRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiCouponDiscProgramActivatePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramDelPrgIdDelete
   */
  static readonly ApiCouponDiscProgramDelPrgIdDeletePath = '/api/Coupon/DiscProgramDel/{prgId}';

  /**
   * Deleting a discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramdel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramDelPrgIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscProgramDelPrgIdDelete$Response(params: {

    /**
     * Interface language
     */
    lang?: string;
    prgId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramDelPrgIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('prgId', params.prgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramdel
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramDelPrgIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscProgramDelPrgIdDelete(params: {

    /**
     * Interface language
     */
    lang?: string;
    prgId: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiCouponDiscProgramDelPrgIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramListGet
   */
  static readonly ApiCouponDiscProgramListGetPath = '/api/Coupon/DiscProgramList';

  /**
   * Getting a list of discount programs.
   *
   * ### Claim
   *  boservice.coupon.discprogramlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscProgramListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractCouponProgramList>>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractCouponProgramList>>;
      })
    );
  }

  /**
   * Getting a list of discount programs.
   *
   * ### Claim
   *  boservice.coupon.discprogramlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscProgramListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractCouponProgramList>> {

    return this.apiCouponDiscProgramListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractCouponProgramList>>) => r.body as Array<BoLankaContractCouponProgramList>)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramGetGet
   */
  static readonly ApiCouponDiscProgramGetGetPath = '/api/Coupon/DiscProgramGet';

  /**
   * Obtaining detailed data of the discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscProgramGetGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BoLankaContractCouponProgramGet>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoLankaContractCouponProgramGet>;
      })
    );
  }

  /**
   * Obtaining detailed data of the discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscProgramGetGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<BoLankaContractCouponProgramGet> {

    return this.apiCouponDiscProgramGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<BoLankaContractCouponProgramGet>) => r.body as BoLankaContractCouponProgramGet)
    );
  }

  /**
   * Path part for operation apiCouponDiscVoucherListGet
   */
  static readonly ApiCouponDiscVoucherListGetPath = '/api/Coupon/DiscVoucherList';

  /**
   * Getting a list of discount vouchers.
   *
   * ### Claim
   *  boservice.coupon.discvoucherlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscVoucherListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscVoucherListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractCouponVoucherList>>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscVoucherListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractCouponVoucherList>>;
      })
    );
  }

  /**
   * Getting a list of discount vouchers.
   *
   * ### Claim
   *  boservice.coupon.discvoucherlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscVoucherListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscVoucherListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractCouponVoucherList>> {

    return this.apiCouponDiscVoucherListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractCouponVoucherList>>) => r.body as Array<BoLankaContractCouponVoucherList>)
    );
  }

  /**
   * Path part for operation apiCouponDiscCouponListGet
   */
  static readonly ApiCouponDiscCouponListGetPath = '/api/Coupon/DiscCouponList';

  /**
   * Getting a list of discount coupons.
   *
   * ### Claim
   *  boservice.coupon.disccouponlist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscCouponListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscCouponListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * coupon number / null
     */
    cpnNo?: string;

    /**
     * coupon status code / null
     */
    statusCode?: number;

    /**
     * limit of records to return. if not set then it is not limited. / null
     */
    limit?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractCouponCouponList>>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscCouponListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('cpnNo', params.cpnNo, {});
      rb.query('statusCode', params.statusCode, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractCouponCouponList>>;
      })
    );
  }

  /**
   * Getting a list of discount coupons.
   *
   * ### Claim
   *  boservice.coupon.disccouponlist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscCouponListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscCouponListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * coupon number / null
     */
    cpnNo?: string;

    /**
     * coupon status code / null
     */
    statusCode?: number;

    /**
     * limit of records to return. if not set then it is not limited. / null
     */
    limit?: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractCouponCouponList>> {

    return this.apiCouponDiscCouponListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractCouponCouponList>>) => r.body as Array<BoLankaContractCouponCouponList>)
    );
  }

  /**
   * Path part for operation apiCouponDiscCouponListToCsvGet
   */
  static readonly ApiCouponDiscCouponListToCsvGetPath = '/api/Coupon/DiscCouponListToCsv';

  /**
   * Getting a list of discount coupons to csv.
   *
   * ### Claim
   *  boservice.coupon.disccouponlisttocsv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscCouponListToCsvGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscCouponListToCsvGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * coupon status code / null
     */
    statusCode?: number;

    /**
     * session token
     */
    token?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscCouponListToCsvGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgId', params.prgId, {});
      rb.query('statusCode', params.statusCode, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Getting a list of discount coupons to csv.
   *
   * ### Claim
   *  boservice.coupon.disccouponlisttocsv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscCouponListToCsvGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscCouponListToCsvGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * program ID
     */
    prgId?: number;

    /**
     * coupon status code / null
     */
    statusCode?: number;

    /**
     * session token
     */
    token?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.apiCouponDiscCouponListToCsvGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiCouponDiscCouponStatusListGet
   */
  static readonly ApiCouponDiscCouponStatusListGetPath = '/api/Coupon/DiscCouponStatusList';

  /**
   * Get a list of coupon states.
   *
   * ### Claim
   *  boservice.coupon.disccouponstatuslist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscCouponStatusListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscCouponStatusListGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractCouponCouponStatusList>>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscCouponStatusListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractCouponCouponStatusList>>;
      })
    );
  }

  /**
   * Get a list of coupon states.
   *
   * ### Claim
   *  boservice.coupon.disccouponstatuslist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscCouponStatusListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscCouponStatusListGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractCouponCouponStatusList>> {

    return this.apiCouponDiscCouponStatusListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractCouponCouponStatusList>>) => r.body as Array<BoLankaContractCouponCouponStatusList>)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramSetPost
   */
  static readonly ApiCouponDiscProgramSetPostPath = '/api/Coupon/DiscProgramSet';

  /**
   * Editing a discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramset
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramSetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramSetPost$Response(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramSetRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramSetPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Editing a discount program.
   *
   * ### Claim
   *  boservice.coupon.discprogramset
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramSetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramSetPost(params?: {

    /**
     * Interface language. if not defined then system default language
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramSetRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiCouponDiscProgramSetPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCouponDiscProgramFinishPost
   */
  static readonly ApiCouponDiscProgramFinishPostPath = '/api/Coupon/DiscProgramFinish';

  /**
   * Discount program finish.
   *
   * Sets discount program to status 3 (finished). Applicable to active programs.
   * ### Claim
   *  boservice.coupon.discprogramfinish
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscProgramFinishPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramFinishPost$Response(params?: {

    /**
     * language code
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramFinishRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscProgramFinishPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Discount program finish.
   *
   * Sets discount program to status 3 (finished). Applicable to active programs.
   * ### Claim
   *  boservice.coupon.discprogramfinish
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscProgramFinishPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCouponDiscProgramFinishPost(params?: {

    /**
     * language code
     */
    lang?: string;
    body?: BoLankaContractCouponDiscProgramFinishRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiCouponDiscProgramFinishPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCouponDiscTariffListGet
   */
  static readonly ApiCouponDiscTariffListGetPath = '/api/Coupon/DiscTariffList';

  /**
   * Tariff list.
   *
   * List of PP tariff for programs of type "Promo" and "Voucher".
   * ### Claim
   *  boservice.coupon.disctarifflist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCouponDiscTariffListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscTariffListGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	program type
     */
    prgType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BoLankaContractCouponDiscTariffListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiCouponDiscTariffListGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('prgType', params.prgType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoLankaContractCouponDiscTariffListResponse>>;
      })
    );
  }

  /**
   * Tariff list.
   *
   * List of PP tariff for programs of type "Promo" and "Voucher".
   * ### Claim
   *  boservice.coupon.disctarifflist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCouponDiscTariffListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCouponDiscTariffListGet(params?: {

    /**
     * *	language code
     */
    lang?: string;

    /**
     * *	program type
     */
    prgType?: number;
  },
  context?: HttpContext

): Observable<Array<BoLankaContractCouponDiscTariffListResponse>> {

    return this.apiCouponDiscTariffListGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BoLankaContractCouponDiscTariffListResponse>>) => r.body as Array<BoLankaContractCouponDiscTariffListResponse>)
    );
  }

  /**
   * Path part for operation apiSupportDiscCouponGetGet
   */
  static readonly ApiSupportDiscCouponGetGetPath = '/api/Support/DiscCouponGet';

  /**
   * Finding and Retrieving Discount Coupon Data.
   *
   * ### Claim
   *  boservice.support.disccouponget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportDiscCouponGetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportDiscCouponGetGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * coupon number
     */
    cpnNo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractCouponCouponGet>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiSupportDiscCouponGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('cpnNo', params.cpnNo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractCouponCouponGet>;
      })
    );
  }

  /**
   * Finding and Retrieving Discount Coupon Data.
   *
   * ### Claim
   *  boservice.support.disccouponget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportDiscCouponGetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportDiscCouponGetGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * coupon number
     */
    cpnNo?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractCouponCouponGet> {

    return this.apiSupportDiscCouponGetGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractCouponCouponGet>) => r.body as ClientSupportApiContractCouponCouponGet)
    );
  }

  /**
   * Finding and Retrieving Discount Coupon Data.
   *
   * ### Claim
   *  boservice.support.disccouponget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportDiscCouponGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportDiscCouponGetGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * coupon number
     */
    cpnNo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractCouponCouponGet>> {

    const rb = new RequestBuilder(this.rootUrl, CouponService.ApiSupportDiscCouponGetGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('cpnNo', params.cpnNo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractCouponCouponGet>;
      })
    );
  }

  /**
   * Finding and Retrieving Discount Coupon Data.
   *
   * ### Claim
   *  boservice.support.disccouponget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportDiscCouponGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportDiscCouponGetGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * coupon number
     */
    cpnNo?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractCouponCouponGet> {

    return this.apiSupportDiscCouponGetGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractCouponCouponGet>) => r.body as ClientSupportApiContractCouponCouponGet)
    );
  }

}
