import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Language } from '@api/models/Postgres/Model/language';

/**
 * Language switcher
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-lang-checker',
    templateUrl: './lang-checker.component.html',
    styleUrls: ['./lang-checker.component.scss'],
    standalone: true,
    imports: [
        NgSelectModule,
        FormsModule,
        NgClass,
    ],
})
export class LangCheckerComponent {
    /**
     * List languages
     */
    @Input() languages: Language[];
    /**
     * Selected lang code
     */
    @Input() selected: string;
    /**
     * Change language event
     *
     * @returns Lang data
     */
    @Output() changeLang: EventEmitter<Language> = new EventEmitter<Language>();

    public handlerChangeLang(lang: unknown): void {
        this.changeLang.emit(lang as Language);
    }
}
