import { InjectionToken } from '@angular/core';
import { enGB, enUS, es, Locale, ru } from 'date-fns/locale';
import { AvailableLanguage } from '@core/localization/available-language';
import { DateFormatModel } from '@services/local-date-adapter/date-format.model';


export type LocalizationConfig = {
    locales: {
        locale: Locale;
        iso: AvailableLanguage;
    }[];
    defaultLocale: AvailableLanguage;
    defaultDateLocal: Locale;
    defaultOutputDateFormat: string;
    defaultOutputDateTimeFormat: string;
    dateFormats: Record<AvailableLanguage, DateFormatModel>;
};

export const defaultLocalizationConfig: LocalizationConfig = {
    locales: [
        {
            iso: AvailableLanguage.EnGb,
            locale: enGB,
        },
        {
            iso: AvailableLanguage.EnUs,
            locale: enUS,
        },
        {
            iso: AvailableLanguage.RuRu,
            locale: ru,
        },
        {
            iso: AvailableLanguage.EsEs,
            locale: es,
        },
    ],
    defaultLocale: AvailableLanguage.EnGb,
    defaultDateLocal: enGB,
    defaultOutputDateFormat: 'dd-MM-yyyy',
    defaultOutputDateTimeFormat: 'dd-MM-yyyy hh:mm:ss',
    dateFormats: {
        [AvailableLanguage.EnGb]: {
            bsDatePicker: 'DD-MM-YYYY',
            moment: 'DD-MM-YYYY HH:mm',
            angular: 'dd-MM-yyyy HH:mm',
            angularDate: 'dd-MM-yyyy',
            time: 'HH:mm',
        },
        [AvailableLanguage.RuRu]: {
            bsDatePicker: 'DD-MM-YYYY',
            moment: 'DD-MM-YYYY HH:mm',
            angular: 'dd-MM-yyyy HH:mm',
            angularDate: 'dd-MM-yyyy',
            time: 'HH:mm',
        },
        [AvailableLanguage.EnUs]: {
            bsDatePicker: 'MM-DD-YYYY',
            moment: 'MM-DD-YYYY hh:mm a',
            angular: 'MM-dd-yyyy hh:mm a',
            angularDate: 'MM-dd-yyyy',
            time: 'HH:mm',
        },
        [AvailableLanguage.EsEs]: {
            bsDatePicker: 'DD-MM-YYYY',
            moment: 'DD-MM-YYYY HH:mm',
            angular: 'dd-MM-yyyy HH:mm',
            angularDate: 'dd-MM-yyyy',
            time: 'HH:mm',
        },
    },
};

export const LOCALIZATION_CONFIG: InjectionToken<LocalizationConfig> = new InjectionToken<LocalizationConfig>('Localization config');
