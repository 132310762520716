import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LoginService } from '@api/services/login.service';
import { LocalStorage } from '@core/storage/local-storage';
import { StorageKeys } from '@core/storage/storage.keys';
import { LanguageState } from '@core/localization/language.state';
import { RoamingService } from '@api/services/roaming.service';


@Injectable({
    providedIn: 'root',
})
export class TokenHttpInterceptor implements HttpInterceptor {
    @LocalStorage(StorageKeys.Access)
    protected token: string;

    readonly #languageState: LanguageState = inject(LanguageState);

    readonly #excludeListUrlForLangParam: string[] = [
        RoamingService.ApiRoamingListLanguagesGetPath,
        LoginService.ApiLoginGetUserDataGetPath,
    ];


    public intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(req.clone({
            ...(!this.#excludeListUrlForLangParam.includes(req.url)
                ? {
                    setParams: { lang: this.#languageState.currentLang },
                }
                : {}),
            ...(this.token ? {
                    setHeaders: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: this.token,
                    },
                }
                : null),
        }));
    }
}
