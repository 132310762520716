<menu *ngIf="menuItems" class="m-0 menu d-flex flex-row flex-wrap">
    <div class="menu__item me-3" *ngFor="let item of menuItems">
        <a class="a--border-off"
           [class.a--white]="selected !== item.url"
           [class.a--selected]="selected === item.url"
           [href]="item.url | currentProtocol"
           [attr.title]="item.mdName"
           target="_blank"
           rel="noopener">{{ item.mdName }}</a>
    </div>
</menu>
