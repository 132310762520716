/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddParkingPermitRequest as ClientSupportApiContractParkingAddParkingPermitRequest } from '../models/ClientSupportApi/Contract/Parking/add-parking-permit-request';
import { ParkingArea as ClientSupportApiContractParkingParkingArea } from '../models/ClientSupportApi/Contract/Parking/parking-area';
import { ParkingPermit as ClientSupportApiContractParkingParkingPermit } from '../models/ClientSupportApi/Contract/Parking/parking-permit';
import { PrkPermit as ClientSupportApiContractParkingPrkPermit } from '../models/ClientSupportApi/Contract/Parking/prk-permit';
import { PrkPermitArea as ClientSupportApiContractParkingPrkPermitArea } from '../models/ClientSupportApi/Contract/Parking/prk-permit-area';
import { PrkPermitZone as ClientSupportApiContractParkingPrkPermitZone } from '../models/ClientSupportApi/Contract/Parking/prk-permit-zone';
import { PrkService as ClientSupportApiContractParkingPrkService } from '../models/ClientSupportApi/Contract/Parking/prk-service';
import { PrkZone as ClientSupportApiContractParkingPrkZone } from '../models/ClientSupportApi/Contract/Parking/prk-zone';
import { Vehicle as ClientSupportApiContractParkingVehicle } from '../models/ClientSupportApi/Contract/Parking/vehicle';

@Injectable({
  providedIn: 'root',
})
export class ParkingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportListPrkPermitTypeGet
   */
  static readonly ApiSupportListPrkPermitTypeGetPath = '/api/Support/ListPrkPermitType';

  /**
   * Obtaining a list of types of subscriptions (permits) for parking.
   *
   * ### Claim
   *  boservice.support.listprkpermittype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkPermitTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitTypeGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermit>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkPermitTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermit>>;
      })
    );
  }

  /**
   * Obtaining a list of types of subscriptions (permits) for parking.
   *
   * ### Claim
   *  boservice.support.listprkpermittype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkPermitTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitTypeGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkPermit>> {

    return this.apiSupportListPrkPermitTypeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermit>>) => r.body as Array<ClientSupportApiContractParkingPrkPermit>)
    );
  }

  /**
   * Obtaining a list of types of subscriptions (permits) for parking.
   *
   * ### Claim
   *  boservice.support.listprkpermittype
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkPermitTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitTypeGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermit>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkPermitTypeGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermit>>;
      })
    );
  }

  /**
   * Obtaining a list of types of subscriptions (permits) for parking.
   *
   * ### Claim
   *  boservice.support.listprkpermittype
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkPermitTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitTypeGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkPermit>> {

    return this.apiSupportListPrkPermitTypeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermit>>) => r.body as Array<ClientSupportApiContractParkingPrkPermit>)
    );
  }

  /**
   * Path part for operation apiSupportListVehicleGet
   */
  static readonly ApiSupportListVehicleGetPath = '/api/Support/ListVehicle';

  /**
   * Getting a list of client vehicles.
   *
   * ### Claim
   *  boservice.support.listvehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListVehicleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListVehicleGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    client?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingVehicle>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListVehicleGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('client', params.client, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingVehicle>>;
      })
    );
  }

  /**
   * Getting a list of client vehicles.
   *
   * ### Claim
   *  boservice.support.listvehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListVehicleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListVehicleGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    client?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingVehicle>> {

    return this.apiSupportListVehicleGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingVehicle>>) => r.body as Array<ClientSupportApiContractParkingVehicle>)
    );
  }

  /**
   * Getting a list of client vehicles.
   *
   * ### Claim
   *  boservice.support.listvehicle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListVehicleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListVehicleGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    client?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingVehicle>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListVehicleGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('client', params.client, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingVehicle>>;
      })
    );
  }

  /**
   * Getting a list of client vehicles.
   *
   * ### Claim
   *  boservice.support.listvehicle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListVehicleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListVehicleGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    client?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingVehicle>> {

    return this.apiSupportListVehicleGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingVehicle>>) => r.body as Array<ClientSupportApiContractParkingVehicle>)
    );
  }

  /**
   * Path part for operation apiSupportAddParkingPermitPost
   */
  static readonly ApiSupportAddParkingPermitPostPath = '/api/Support/AddParkingPermit';

  /**
   * Adding a subscription to the parking of the vehicle.
   *
   * ### Claim
   *  boservice.support.addparkingpermit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportAddParkingPermitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddParkingPermitPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractParkingAddParkingPermitRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportAddParkingPermitPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adding a subscription to the parking of the vehicle.
   *
   * ### Claim
   *  boservice.support.addparkingpermit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportAddParkingPermitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddParkingPermitPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractParkingAddParkingPermitRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportAddParkingPermitPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportRemoveParkingPermitDelete
   */
  static readonly ApiSupportRemoveParkingPermitDeletePath = '/api/Support/RemoveParkingPermit';

  /**
   * Removing a parking pass.
   *
   * ### Claim
   *  boservice.support.removeparkingpermit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRemoveParkingPermitDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveParkingPermitDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    client?: number;

    /**
     * parking pass ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportRemoveParkingPermitDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('client', params.client, {});
      rb.query('permId', params.permId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing a parking pass.
   *
   * ### Claim
   *  boservice.support.removeparkingpermit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRemoveParkingPermitDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveParkingPermitDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    client?: number;

    /**
     * parking pass ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportRemoveParkingPermitDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportListPrkServiceGet
   */
  static readonly ApiSupportListPrkServiceGetPath = '/api/Support/ListPrkService';

  /**
   * Getting a list of parking services.
   *
   * ### Claim
   *  boservice.support.listprkservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkServiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkServiceGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkService>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkService>>;
      })
    );
  }

  /**
   * Getting a list of parking services.
   *
   * ### Claim
   *  boservice.support.listprkservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkServiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkServiceGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkService>> {

    return this.apiSupportListPrkServiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkService>>) => r.body as Array<ClientSupportApiContractParkingPrkService>)
    );
  }

  /**
   * Getting a list of parking services.
   *
   * ### Claim
   *  boservice.support.listprkservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkServiceGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkService>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkService>>;
      })
    );
  }

  /**
   * Getting a list of parking services.
   *
   * ### Claim
   *  boservice.support.listprkservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkServiceGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkService>> {

    return this.apiSupportListPrkServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkService>>) => r.body as Array<ClientSupportApiContractParkingPrkService>)
    );
  }

  /**
   * Path part for operation apiSupportListPrkZoneGet
   */
  static readonly ApiSupportListPrkZoneGetPath = '/api/Support/ListPrkZone';

  /**
   * Getting parking zones for a service.
   *
   * ### Claim
   *  boservice.support.listprkzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkZoneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkZoneGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkZone>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkZone>>;
      })
    );
  }

  /**
   * Getting parking zones for a service.
   *
   * ### Claim
   *  boservice.support.listprkzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkZoneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkZoneGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkZone>> {

    return this.apiSupportListPrkZoneGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkZone>>) => r.body as Array<ClientSupportApiContractParkingPrkZone>)
    );
  }

  /**
   * Getting parking zones for a service.
   *
   * ### Claim
   *  boservice.support.listprkzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkZoneGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkZoneGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkZone>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkZone>>;
      })
    );
  }

  /**
   * Getting parking zones for a service.
   *
   * ### Claim
   *  boservice.support.listprkzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkZoneGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkZoneGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkZone>> {

    return this.apiSupportListPrkZoneGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkZone>>) => r.body as Array<ClientSupportApiContractParkingPrkZone>)
    );
  }

  /**
   * Path part for operation apiSupportListParkingAreaGet
   */
  static readonly ApiSupportListParkingAreaGetPath = '/api/Support/ListParkingArea';

  /**
   * Getting parking places in the zone.
   *
   * ### Claim
   *  boservice.support.listparkingarea
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListParkingAreaGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingAreaGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    przId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingParkingArea>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListParkingAreaGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('przId', params.przId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingParkingArea>>;
      })
    );
  }

  /**
   * Getting parking places in the zone.
   *
   * ### Claim
   *  boservice.support.listparkingarea
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListParkingAreaGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingAreaGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    przId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingParkingArea>> {

    return this.apiSupportListParkingAreaGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingParkingArea>>) => r.body as Array<ClientSupportApiContractParkingParkingArea>)
    );
  }

  /**
   * Getting parking places in the zone.
   *
   * ### Claim
   *  boservice.support.listparkingarea
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListParkingAreaGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingAreaGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    przId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingParkingArea>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListParkingAreaGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('przId', params.przId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingParkingArea>>;
      })
    );
  }

  /**
   * Getting parking places in the zone.
   *
   * ### Claim
   *  boservice.support.listparkingarea
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListParkingAreaGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingAreaGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * id services
     */
    przId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingParkingArea>> {

    return this.apiSupportListParkingAreaGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingParkingArea>>) => r.body as Array<ClientSupportApiContractParkingParkingArea>)
    );
  }

  /**
   * Path part for operation apiSupportListParkingPermitGet
   */
  static readonly ApiSupportListParkingPermitGetPath = '/api/Support/ListParkingPermit';

  /**
   * Obtaining a list of subscriptions / parking permits for a client.
   *
   * ### Claim
   *  boservice.support.listparkingpermit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListParkingPermitGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingPermitGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * client ID
     */
    clientId?: number;

    /**
     * history search attribute: 0 – only current and future versions 1 – entire history
     */
    searchAll?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingParkingPermit>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListParkingPermitGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('clientId', params.clientId, {});
      rb.query('searchAll', params.searchAll, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingParkingPermit>>;
      })
    );
  }

  /**
   * Obtaining a list of subscriptions / parking permits for a client.
   *
   * ### Claim
   *  boservice.support.listparkingpermit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListParkingPermitGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingPermitGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * client ID
     */
    clientId?: number;

    /**
     * history search attribute: 0 – only current and future versions 1 – entire history
     */
    searchAll?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingParkingPermit>> {

    return this.apiSupportListParkingPermitGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingParkingPermit>>) => r.body as Array<ClientSupportApiContractParkingParkingPermit>)
    );
  }

  /**
   * Obtaining a list of subscriptions / parking permits for a client.
   *
   * ### Claim
   *  boservice.support.listparkingpermit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListParkingPermitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingPermitGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * client ID
     */
    clientId?: number;

    /**
     * history search attribute: 0 – only current and future versions 1 – entire history
     */
    searchAll?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingParkingPermit>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListParkingPermitGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('clientId', params.clientId, {});
      rb.query('searchAll', params.searchAll, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingParkingPermit>>;
      })
    );
  }

  /**
   * Obtaining a list of subscriptions / parking permits for a client.
   *
   * ### Claim
   *  boservice.support.listparkingpermit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListParkingPermitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListParkingPermitGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * client ID
     */
    clientId?: number;

    /**
     * history search attribute: 0 – only current and future versions 1 – entire history
     */
    searchAll?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingParkingPermit>> {

    return this.apiSupportListParkingPermitGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingParkingPermit>>) => r.body as Array<ClientSupportApiContractParkingParkingPermit>)
    );
  }

  /**
   * Path part for operation apiSupportListPrkPermitZoneGet
   */
  static readonly ApiSupportListPrkPermitZoneGetPath = '/api/Support/ListPrkPermitZone';

  /**
   * Getting a list of zone restrictions for subscriptions/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkPermitZoneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitZoneGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitZone>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkPermitZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('permId', params.permId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitZone>>;
      })
    );
  }

  /**
   * Getting a list of zone restrictions for subscriptions/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkPermitZoneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitZoneGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkPermitZone>> {

    return this.apiSupportListPrkPermitZoneGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitZone>>) => r.body as Array<ClientSupportApiContractParkingPrkPermitZone>)
    );
  }

  /**
   * Getting a list of zone restrictions for subscriptions/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitzone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkPermitZoneGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitZoneGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitZone>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkPermitZoneGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('permId', params.permId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitZone>>;
      })
    );
  }

  /**
   * Getting a list of zone restrictions for subscriptions/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitzone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkPermitZoneGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitZoneGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkPermitZone>> {

    return this.apiSupportListPrkPermitZoneGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitZone>>) => r.body as Array<ClientSupportApiContractParkingPrkPermitZone>)
    );
  }

  /**
   * Path part for operation apiSupportListPrkPermitAreaGet
   */
  static readonly ApiSupportListPrkPermitAreaGetPath = '/api/Support/ListPrkPermitArea';

  /**
   * Getting a list of seat restrictions for passes/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitarea
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkPermitAreaGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitAreaGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitArea>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkPermitAreaGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('permId', params.permId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitArea>>;
      })
    );
  }

  /**
   * Getting a list of seat restrictions for passes/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitarea
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkPermitAreaGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitAreaGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkPermitArea>> {

    return this.apiSupportListPrkPermitAreaGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitArea>>) => r.body as Array<ClientSupportApiContractParkingPrkPermitArea>)
    );
  }

  /**
   * Getting a list of seat restrictions for passes/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitarea
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListPrkPermitAreaGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitAreaGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitArea>>> {

    const rb = new RequestBuilder(this.rootUrl, ParkingService.ApiSupportListPrkPermitAreaGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('permId', params.permId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitArea>>;
      })
    );
  }

  /**
   * Getting a list of seat restrictions for passes/permits.
   *
   * ### Claim
   *  boservice.support.listprkpermitarea
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListPrkPermitAreaGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListPrkPermitAreaGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * subscription/permit ID
     */
    permId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractParkingPrkPermitArea>> {

    return this.apiSupportListPrkPermitAreaGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractParkingPrkPermitArea>>) => r.body as Array<ClientSupportApiContractParkingPrkPermitArea>)
    );
  }

}
