import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SettingsDesignLogoModel } from '@libs/settings/settings.model';
import { SettingsService } from '@libs/settings/settings.service';
import { LogoComponent } from '@ui/logo/logo.component';

/**
 * Footer
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [
        LogoComponent,
    ],
})
export class FooterComponent {
    readonly #settingsService: SettingsService = inject(SettingsService);
    /**
     * Settings application
     */
    public readonly logo: SettingsDesignLogoModel =
        this.#settingsService.settings?.logo?.srcFooter;
    /**
     * Year in copyright
     */
    public readonly year: number = new Date().getFullYear();
}
