/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListAppSettingsResponse as SettingsApiContractListAppSettingsResponse } from '../models/SettingsApi/Contract/list-app-settings-response';
import { SetSettingRequest as SettingsApiContractSetSettingRequest } from '../models/SettingsApi/Contract/set-setting-request';
import { Settings as SettingsApiContractSettings } from '../models/SettingsApi/Contract/settings';
import { SlistData as SettingsApiContractSlistData } from '../models/SettingsApi/Contract/slist-data';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSettingsListSettingsGet
   */
  static readonly ApiSettingsListSettingsGetPath = '/api/Settings/ListSettings';

  /**
   * ### Claim
   *  boservice.settings.listsettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsListSettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSettingsGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingsApiContractSettings>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsListSettingsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingsApiContractSettings>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.settings.listsettings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingsListSettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSettingsGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<SettingsApiContractSettings>> {

    return this.apiSettingsListSettingsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingsApiContractSettings>>) => r.body as Array<SettingsApiContractSettings>)
    );
  }

  /**
   * ### Claim
   *  boservice.settings.listsettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsListSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSettingsGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingsApiContractSettings>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsListSettingsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingsApiContractSettings>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.settings.listsettings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingsListSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSettingsGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<SettingsApiContractSettings>> {

    return this.apiSettingsListSettingsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingsApiContractSettings>>) => r.body as Array<SettingsApiContractSettings>)
    );
  }

  /**
   * Path part for operation apiSettingsSetSettingPost
   */
  static readonly ApiSettingsSetSettingPostPath = '/api/Settings/SetSetting';

  /**
   * ### Claim
   *  boservice.settings.setsetting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsSetSettingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsSetSettingPost$Response(params?: {
    lang?: string;
    body?: Array<SettingsApiContractSetSettingRequest>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsSetSettingPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.settings.setsetting
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingsSetSettingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsSetSettingPost(params?: {
    lang?: string;
    body?: Array<SettingsApiContractSetSettingRequest>
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSettingsSetSettingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSettingsListSlistDataGet
   */
  static readonly ApiSettingsListSlistDataGetPath = '/api/Settings/ListSlistData';

  /**
   * ### Claim
   *  boservice.settings.listslistdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsListSlistDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSlistDataGet$Plain$Response(params?: {
    lang?: string;
    slistId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingsApiContractSlistData>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsListSlistDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slistId', params.slistId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingsApiContractSlistData>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.settings.listslistdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingsListSlistDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSlistDataGet$Plain(params?: {
    lang?: string;
    slistId?: number;
  },
  context?: HttpContext

): Observable<Array<SettingsApiContractSlistData>> {

    return this.apiSettingsListSlistDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingsApiContractSlistData>>) => r.body as Array<SettingsApiContractSlistData>)
    );
  }

  /**
   * ### Claim
   *  boservice.settings.listslistdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsListSlistDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSlistDataGet$Response(params?: {
    lang?: string;
    slistId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingsApiContractSlistData>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsListSlistDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('slistId', params.slistId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingsApiContractSlistData>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.settings.listslistdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingsListSlistDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsListSlistDataGet(params?: {
    lang?: string;
    slistId?: number;
  },
  context?: HttpContext

): Observable<Array<SettingsApiContractSlistData>> {

    return this.apiSettingsListSlistDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingsApiContractSlistData>>) => r.body as Array<SettingsApiContractSlistData>)
    );
  }

  /**
   * Path part for operation apiAppListSettingsGet
   */
  static readonly ApiAppListSettingsGetPath = '/api/App/ListSettings';

  /**
   * Getting a list of settings for BO (apps=BO).
   *
   * Settings for BO (roamdb and masserv)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppListSettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppListSettingsGet$Plain$Response(params?: {

    /**
     * *	interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingsApiContractListAppSettingsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiAppListSettingsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingsApiContractListAppSettingsResponse>>;
      })
    );
  }

  /**
   * Getting a list of settings for BO (apps=BO).
   *
   * Settings for BO (roamdb and masserv)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppListSettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppListSettingsGet$Plain(params?: {

    /**
     * *	interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<SettingsApiContractListAppSettingsResponse>> {

    return this.apiAppListSettingsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingsApiContractListAppSettingsResponse>>) => r.body as Array<SettingsApiContractListAppSettingsResponse>)
    );
  }

  /**
   * Getting a list of settings for BO (apps=BO).
   *
   * Settings for BO (roamdb and masserv)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppListSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppListSettingsGet$Response(params?: {

    /**
     * *	interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingsApiContractListAppSettingsResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiAppListSettingsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingsApiContractListAppSettingsResponse>>;
      })
    );
  }

  /**
   * Getting a list of settings for BO (apps=BO).
   *
   * Settings for BO (roamdb and masserv)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppListSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppListSettingsGet(params?: {

    /**
     * *	interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<SettingsApiContractListAppSettingsResponse>> {

    return this.apiAppListSettingsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingsApiContractListAppSettingsResponse>>) => r.body as Array<SettingsApiContractListAppSettingsResponse>)
    );
  }

}
