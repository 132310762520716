/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { B2BRiderAddRequest as B2BServiceContractB2BRiderAddRequest } from '../models/B2bService/Contract/b-2-b-rider-add-request';
import { B2BRiderAddResponse as B2BServiceContractB2BRiderAddResponse } from '../models/B2bService/Contract/b-2-b-rider-add-response';

@Injectable({
  providedIn: 'root',
})
export class ManualRiderOperationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiB2BB2BRiderAddPost
   */
  static readonly ApiB2BB2BRiderAddPostPath = '/api/B2b/B2bRiderAdd';

  /**
   * Add b2b program and fob to customer.
   *
   * Add b2b program and card/fob to customer
   * ### Claim
   *  boservice.b2b.b2brideradd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BRiderAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BB2BRiderAddPost$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: B2BServiceContractB2BRiderAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractB2BRiderAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ManualRiderOperationsService.ApiB2BB2BRiderAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractB2BRiderAddResponse>;
      })
    );
  }

  /**
   * Add b2b program and fob to customer.
   *
   * Add b2b program and card/fob to customer
   * ### Claim
   *  boservice.b2b.b2brideradd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BRiderAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BB2BRiderAddPost$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: B2BServiceContractB2BRiderAddRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractB2BRiderAddResponse> {

    return this.apiB2BB2BRiderAddPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractB2BRiderAddResponse>) => r.body as B2BServiceContractB2BRiderAddResponse)
    );
  }

  /**
   * Add b2b program and fob to customer.
   *
   * Add b2b program and card/fob to customer
   * ### Claim
   *  boservice.b2b.b2brideradd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiB2BB2BRiderAddPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BB2BRiderAddPost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: B2BServiceContractB2BRiderAddRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<B2BServiceContractB2BRiderAddResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ManualRiderOperationsService.ApiB2BB2BRiderAddPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<B2BServiceContractB2BRiderAddResponse>;
      })
    );
  }

  /**
   * Add b2b program and fob to customer.
   *
   * Add b2b program and card/fob to customer
   * ### Claim
   *  boservice.b2b.b2brideradd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiB2BB2BRiderAddPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiB2BB2BRiderAddPost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: B2BServiceContractB2BRiderAddRequest
  },
  context?: HttpContext

): Observable<B2BServiceContractB2BRiderAddResponse> {

    return this.apiB2BB2BRiderAddPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<B2BServiceContractB2BRiderAddResponse>) => r.body as B2BServiceContractB2BRiderAddResponse)
    );
  }

}
