import { Component, inject } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { LanguageState } from '@core/localization/language.state';
import { RxEffects } from '@rx-angular/state/effects';
import { filter } from 'rxjs/operators';
import { UserState } from '@core/state/user.state';
import { AppState } from '@core/state/app.state';
import { Language } from '@api/models/Postgres/Model/language';


/**
 *  Common bootstrap application component
 */
@Component({
    selector: 'ui-common-app',
    templateUrl: './common-app.component.html',
    styleUrls: ['./common-app.component.scss'],
    providers: [RxEffects],
})
export class CommonAppComponent {
    readonly #userState: UserState = inject(UserState);
    readonly #appState: AppState = inject(AppState);
    readonly #languageState: LanguageState = inject(LanguageState);
    readonly #effects: RxEffects = inject(RxEffects);

    /**
     * List languages
     */
    public languages$: Observable<Language[]> = this.#languageState.languages$;

    constructor() {
        this.#effects.register(
            combineLatest([
                this.#languageState.language$,
                this.#userState.isAuthorized$.pipe(filter(Boolean)),
            ]),
            () => {
                this.#appState.requestModules();
                this.#languageState.requestUserLanguages();
            },
        );

    }
}
