import { inject, Inject, Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { LOCAL_LOGIN_URL } from '@libs/constants';
import { UserState } from '@core/state/user.state';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';


@Injectable({
    providedIn: 'root',
})
export class AuthWithTempPasswordGuard implements CanLoad {
    readonly #userState: UserState = inject(UserState);

    public constructor(
        @Inject('window') private window: Window,
    ) {
    }

    public canLoad(): Promise<boolean> | boolean {
        return new Promise((resolve) => {
            this.#userState.user$.subscribe((user: LoginResponse) => {
                if (user) {
                    resolve(true);
                } else {
                    this.loginRedirect();
                }
            });
        });
    }

    private loginRedirect(): void {
        setTimeout(() => this.window.location.assign(LOCAL_LOGIN_URL));
    }
}
